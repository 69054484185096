import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  handleRequestErrorState,
  handleRequestState,
  handleRequestSuccessState,
  handleStale,
} from "../lib/GAPIData";
import { IAPIError } from "../lib/GRequest";
import { ISellStock, IStock, IStockData } from "./StocksApi";
import { IStocksStore, STOCK_DATA_MAX_AGE_SECONDS } from "./store";

export const stockAddRequest = createAction<IStock>("stocks/stockAddRequest");
export const stockSaleRequest = createAction<ISellStock>(
  "stocks/stockSaleRequest"
);

const stockSlice = createSlice({
  name: "stocks",
  initialState: {} as IStocksStore,
  reducers: {
    stockDataRequest(state) {
      return {
        ...state,
        stockData: handleRequestState(
          state.stockData,
          STOCK_DATA_MAX_AGE_SECONDS
        ),
      };
    },
    stockDataRequestError(state, action: PayloadAction<IAPIError>) {
      return {
        ...state,
        stockData: handleRequestErrorState(
          action.payload.message,
          state.stockData,
          STOCK_DATA_MAX_AGE_SECONDS
        ),
      };
    },
    stockDataRequestSuccess(state, action: PayloadAction<IStockData>) {
      return {
        ...state,
        stockData: handleRequestSuccessState(
          [action.payload],
          state.stockData,
          STOCK_DATA_MAX_AGE_SECONDS
        ),
      };
    },

    /**
     * Adding new stocks
     */
    stockAddRequestError(state) {
      return state;
    },
    stockAddRequestSuccess(state) {
      return {
        ...state,
        stockHistoryData: handleStale(
          state.stockData,
          STOCK_DATA_MAX_AGE_SECONDS
        ),
      };
    },

    /**
     * Selling stocks
     */
    stockSaleRequestError(state) {
      return state;
    },
    stockSaleRequestSuccess(state) {
      return {
        ...state,
        stocksData: handleStale(state.stockData, STOCK_DATA_MAX_AGE_SECONDS),
      };
    },
  },
});

export const {
  stockAddRequestError,
  stockAddRequestSuccess,
  stockSaleRequestError,
  stockSaleRequestSuccess,
  stockDataRequest,
  stockDataRequestError,
  stockDataRequestSuccess,
} = stockSlice.actions;
export default stockSlice.reducer;
