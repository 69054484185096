import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import GCard from "../../lib/GCard";
import { DietaryFlag, IRecipe } from "../RecipeApi1";
import DietaryFlagLabel from "./DietaryFlagLabel";
import Ingredient from "./Ingredient";
import MealTypeLabel from "./MealTypeLabel";
import RecipeCost from "./RecipeCost";
import RecipeIngredientCount from "./RecipeIngredientCount";
import RecipeLastAddedToCart, {
  shouldShowLastAdded,
} from "./RecipeLastAddedToCart";
import RecipePans from "./RecipePans";
import RecipeReheatableDays from "./RecipeReheatableDays";
import RecipeStepCount from "./RecipeStepCount";
import RecipeTime from "./RecipeTime";
import RecipeTotalMeals from "./RecipeTotalMeals";

interface IProps {
  recipe: IRecipe;
  noLink?: boolean;
  noIngredients?: boolean;
  narrow?: boolean;
}

const XS = 4;
const SM = 4;
const MD = 2;
const MD_NARROW = 4;

function recipeGridItem(key: string, component: any, narrow: boolean) {
  const md = narrow ? MD_NARROW : MD;

  return (
    <Grid item key={key} xs={XS} sm={SM} md={md} lg={md} xl={md}>
      {component}
    </Grid>
  );
}

function recipeAttribute(
  recipe: IRecipe,
  attr: string,
  value: any,
  narrow: boolean
) {
  if (!value || value === "0") {
    return null;
  }

  let component;
  switch (attr) {
    case "cost":
      component = <RecipeCost recipe={recipe} />;
      break;
    case "leftover_days":
      component = <RecipeReheatableDays recipe={recipe} />;
      break;
    case "meals":
      component = <RecipeTotalMeals recipe={recipe} />;
      break;
    case "minutes":
      component = <RecipeTime minutes={recipe.minutes} />;
      break;
    case "pots_and_pans":
      component = <RecipePans recipe={recipe} />;
      break;
    case "steps":
      component = <RecipeStepCount recipe={recipe} />;
      break;
    case "ingredients":
      component = <RecipeIngredientCount recipe={recipe} />;
      break;
  }

  return recipeGridItem(attr, component, narrow);
}

function recipeDietaryFlags(recipe: IRecipe, narrow: boolean) {
  const labels = [];
  const flags = recipe.dietary_flags || [];
  const mkLabel = (f: DietaryFlag) => {
    return recipeGridItem(f, <DietaryFlagLabel name={f} />, narrow);
  };

  if (flags.indexOf(DietaryFlag.Vegan) >= 0) {
    labels.push(mkLabel(DietaryFlag.Vegan));
  } else {
    for (const flag of flags) {
      labels.push(mkLabel(flag));
    }
  }

  return labels;
}

function recipeMealTypes(recipe: IRecipe, narrow: boolean) {
  const labels = [];
  const mealTypes = recipe.meal_types || [];

  for (const t of mealTypes) {
    labels.push(recipeGridItem(t, <MealTypeLabel name={t} />, narrow));
  }

  return labels;
}

const IngredientGrid = styled(Grid)({
  padding: "1em",
});

function recipeIngredients(recipe: IRecipe) {
  const ingredients = [];

  for (const ingredient of recipe.ingredient_list) {
    ingredients.push(
      <Grid key={ingredient.name} item xs={12} sm={6} md={4}>
        <Ingredient ingredient={ingredient} />
      </Grid>
    );
  }
  return <IngredientGrid container>{ingredients}</IngredientGrid>;
}

export default function RecipeBox(props: IProps) {
  const { recipe, noLink, noIngredients, narrow } = props;
  const url = noLink ? undefined : `/recipe/${recipe.id}`;

  let img;
  if (recipe.photo) {
    img = recipe.photo;
  }

  const n = narrow || false;

  return (
    <GCard title={recipe.name} route={url} img={img}>
      <Grid container alignItems={"center"} justify={"center"} spacing={1}>
        {shouldShowLastAdded(recipe) &&
          recipeGridItem(
            "last_added",
            <RecipeLastAddedToCart recipe={recipe} />,
            n
          )}
        {recipeAttribute(recipe, "steps", true, n)}
        {/*{recipeAttribute(recipe, "ingredients", true, n)}*/}
        {recipeAttribute(recipe, "minutes", recipe.minutes, n)}
        {recipeAttribute(recipe, "meals", recipe.meals, n)}
        {/*{recipeAttribute(recipe, "leftover_days", recipe.leftover_days, n)}*/}
        {/*{recipeAttribute(recipe, "cost", recipe.cost, n)}*/}
        {recipeAttribute(recipe, "pots_and_pans", recipe.pots_and_pans, n)}
        {recipeDietaryFlags(recipe, n)}
        {recipeMealTypes(recipe, n)}
        {!noIngredients && recipeIngredients(recipe)}
      </Grid>
    </GCard>
  );
}
