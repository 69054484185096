import styled from "@material-ui/core/styles/styled";
import RefreshIcon from "@material-ui/icons/Refresh";
import * as React from "react";
import { Color } from "../../lib/colors";
import { IStockData } from "../StocksApi";
import { prettyDateTime } from "./common";

const Container = styled("div")({
  width: "100%",
  textAlign: "center",
  fontSize: "10pt",
  color: Color.Gray,
  margin: "0.7em",
  "& svg": {
    marginBottom: "-2px",
  },
});

const Time = styled("span")({
  paddingRight: "0.3em",
});

const Label = styled("span")({
  fontWeight: "bold",
  paddingLeft: "0.3em",
});

interface IProps {
  stockData: IStockData;
}

export default (props: IProps) => {
  const { stockData } = props;
  const r = stockData.refreshed;

  return (
    <Container>
      <RefreshIcon fontSize={"inherit"} />
      <Label>Quote:</Label> <Time>{prettyDateTime(r.quotes)}</Time>
      <Label>History:</Label> <Time>{prettyDateTime(r.history)}</Time>
      <Label>Info:</Label> <Time>{prettyDateTime(r.info)}</Time>
      <Label>Request:</Label> <Time>{prettyDateTime(r.response)}</Time>
    </Container>
  );
};
