import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { Color, textColorForBackgroundColor } from "../../lib/colors";
import GLabel from "../../lib/GLabel";
import { IStockDataBuy } from "../StocksApi";
import {
  ColoredValue,
  colorForStock,
  createColumn,
  currFormat,
  placeRound,
  shortDate,
} from "./common";

const ThinLineContainer = styled("div")({
  marginBottom: "1em",
  paddingBottom: "1em",
  borderWidth: "0 0 1px 0",
  borderColor: Color.Gray,
  borderStyle: "dashed",
});

const BarContainer = styled("div")({
  width: "100%",
  overflow: "clip",
  display: "flex",
});

interface IDistributionItem {
  label: string;
  value: number;
  color: Color;
  total: number;
}

interface IBarItemProps {
  item: IDistributionItem;
}

const BarItem = styled("div")({
  backgroundColor: (props: IBarItemProps) => props.item.color,
  color: (props) => textColorForBackgroundColor(props.item.color),
  textAlign: "center",
  width: (props: IBarItemProps) => `${props.item.value}%`,
  minWidth: "1%",
  height: "25px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderWidth: "1px",
  borderColor: Color.Background,
  borderStyle: "solid",
  fontFamily: "monospace",
  fontWeight: "bold",
});

const BarItemLegend = styled(BarItem)({
  backgroundColor: Color.Background,
  color: (props: IBarItemProps) => props.item.color,
  borderWidth: "0px",
  minWidth: "fit-content",
});

interface IDistributionBarProps {
  values: IDistributionItem[];
}

const DistributionBar = (props: IDistributionBarProps) => {
  const items: JSX.Element[] = [];
  const legendItems: JSX.Element[] = [];
  props.values.forEach((item) => {
    const title = `${item.label} ${currFormat.format(
      item.total
    )} (${item.value.toFixed(1)}%)`;
    items.push(
      <BarItem key={item.label} item={item} title={title}>
        {item.value > 8 && item.label}
      </BarItem>
    );
    legendItems.push(
      <BarItemLegend key={item.label} item={item} title={title}>
        {currFormat.format(item.total)}
        {item.value > 15 && ` (${item.value.toFixed(1)}%)`}
      </BarItemLegend>
    );
  });
  return (
    <>
      <BarContainer>{items}</BarContainer>
      <BarContainer>{legendItems}</BarContainer>
    </>
  );
};

const getDistributions = (sb: IStockDataBuy): IDistributionItem[] => {
  const dividend = sb.total_dividend_profit;
  const investment = sb.total_cost;
  const shareProfit = sb.total_share_profit;

  const data = [];
  let total = dividend + investment + shareProfit;

  if (shareProfit >= 0) {
    data.push({
      label: "Invested",
      value: placeRound(investment, total),
      color: Color.Purple,
      total: investment,
    });

    const sharePct = placeRound(shareProfit, total);
    if (sharePct > 0.1)
      data.push({
        label: "Profit",
        value: sharePct,
        color: Color.Green,
        total: shareProfit,
      });
  } else {
    const totalValue = shareProfit + investment;
    const lossValue = Math.min(shareProfit + dividend, 0);

    if (lossValue < 0) total = investment;

    data.push({
      label: "Value",
      value: placeRound(totalValue, total),
      color: Color.Gray,
      total: totalValue,
    });

    const lossPct = placeRound(Math.abs(lossValue), total);
    if (lossPct > 0.1)
      data.push({
        label: "Loss",
        value: lossPct,
        color: Color.Red,
        total: lossValue,
      });
  }

  const divPct = placeRound(dividend, total);
  if (divPct > 0.1)
    data.push({
      label: "Dividends",
      value: divPct,
      color: Color.Blue,
      total: dividend,
    });

  return data;
};

const StockBuySummary = styled("div")({
  fontSize: "14pt",
});

const StockBuySummaryQuantity = styled("span")({
  fontWeight: "bold",
});

const StockBuySummaryDate = styled("span")({
  fontWeight: "bold",
  color: Color.Purple,
});

const StockBuySummaryPrice = styled("span")({
  fontWeight: "bold",
  color: (props: any) => props.color,
});

interface IStockBuyProps {
  sb: IStockDataBuy;
}

export const StockBuy = (props: IStockBuyProps) => {
  const { sb } = props;

  const isGreaterValue = sb.total_profit > 0;
  let lengthColor = Color.Yellow;
  let lengthLabel = "short";
  if (sb.days_owned > 365) {
    lengthColor = Color.Green;
    lengthLabel = "long";
  }

  return (
    <ThinLineContainer>
      <Grid container spacing={1} alignItems={"center"}>
        <Grid item xs={2}>
          <GLabel
            label={lengthLabel}
            variant={lengthColor}
            extraLabel={`${sb.days_owned}d`}
            extraVariant={Color.Purple}
          />
        </Grid>
        <Grid item xs={4}>
          <StockBuySummary>
            <StockBuySummaryDate>
              {shortDate(sb.purchase_date)}:&nbsp;
            </StockBuySummaryDate>
            <StockBuySummaryQuantity>{sb.quantity}</StockBuySummaryQuantity>
            &nbsp;for&nbsp;
            <StockBuySummaryPrice color={colorForStock(sb)}>
              {currFormat.format(sb.purchase_price)}
            </StockBuySummaryPrice>
          </StockBuySummary>
        </Grid>
        <Grid item xs={2}>
          {createColumn(
            "ROI",
            <ColoredValue raw={sb.total_roi}>
              {sb.total_roi.toFixed(2)}%
            </ColoredValue>
          )}
        </Grid>
        <Grid item xs={2}>
          {createColumn(
            "ROI/y",
            <ColoredValue raw={sb.total_apy}>
              {sb.total_apy.toFixed(2)}%
            </ColoredValue>
          )}
        </Grid>
        <Grid item xs={2}>
          {createColumn(
            "value",
            <ColoredValue raw={isGreaterValue ? 1 : -1}>
              {currFormat.format(sb.total_current)}
            </ColoredValue>
          )}
        </Grid>

        <Grid item xs={12}>
          <DistributionBar values={getDistributions(sb)} />
        </Grid>
      </Grid>
    </ThinLineContainer>
  );
};
