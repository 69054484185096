import { Button, TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import styled from "@material-ui/core/styles/styled";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IStoreState } from "store";
import { IGAPIDataState, isDataLoading, useGData } from "../../lib/GAPIData";
import GFab from "../../lib/GFab";
import GLoading from "../../lib/GLoading";
import Header from "../../main/Header";
import { allCartItems, ICart } from "../CartApi1";
import CartItem from "../components/CartItem";
import { IRecipe, sortRecipes } from "../RecipeApi1";
import {
  cartRequest,
  cartClearRequest,
  cartAddItemRequest,
  cartAddRecipeRequest,
  recipesRequest,
} from "../reducer";

interface IStoreProps {
  cartData?: IGAPIDataState<ICart>;
  recipeData?: IGAPIDataState<IRecipe>;
}

interface IDispatchProps {
  requestCart: () => void;
  requestCartClear: () => void;
  requestCartAddItem: (n: string, q: string) => void;
  requestCartAddRecipe: (rId: number) => void;
  requestRecipes: () => void;
}

interface IState {
  currentTab: number;
  clearConfirmOpen?: boolean;
  addToCartOpen?: boolean;
  addItemQuantity: string;
  addItemName: string;
  addRecipeName: string;
}

const CartItemContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
});

const ClearButton = styled(Button)({
  marginTop: "1em",
});

export default connect(
  (store: IStoreState): IStoreProps => {
    return {
      cartData: store.recipes.cartData,
      recipeData: store.recipes.recipeData,
    };
  },
  (dispatch: Dispatch): IDispatchProps => {
    return {
      requestCart: () => dispatch(cartRequest()),
      requestCartClear: () => dispatch(cartClearRequest()),
      requestCartAddItem: (n: string, q: string) =>
        dispatch(cartAddItemRequest({ name: n, quantity: q, checked: false })),
      requestCartAddRecipe: (rId: number) =>
        dispatch(cartAddRecipeRequest(rId)),
      requestRecipes: () => dispatch(recipesRequest()),
    };
  }
)((props: IStoreProps & IDispatchProps) => {
  const [state, setState] = useState<IState>({
    currentTab: 0,
    addRecipeName: "",
    addItemName: "",
    addItemQuantity: "",
  });
  const {
    currentTab,
    clearConfirmOpen,
    addItemName,
    addItemQuantity,
    addToCartOpen,
    addRecipeName,
  } = state;
  const {
    requestCart,
    cartData,
    recipeData,
    requestCartClear,
    requestCartAddItem,
    requestCartAddRecipe,
    requestRecipes,
  } = props;
  const cart = useGData(cartData, requestCart)[0];
  let recipes = useGData(recipeData, requestRecipes);
  recipes = sortRecipes(recipes);
  const allItems = allCartItems(cart, recipes);

  useEffect(() => {
    const timer = setTimeout(() => requestCart(), 60 * 1000);
    return () => clearTimeout(timer);
  });

  function closeClearDialog() {
    setState({ ...state, clearConfirmOpen: false });
  }

  function closeAddToCartDialog() {
    setState({ ...state, addToCartOpen: false });
  }

  function clearCart() {
    requestCartClear();
    closeClearDialog();
  }

  function addNewItem() {
    if (
      addRecipeName !== null &&
      addRecipeName !== undefined &&
      addRecipeName !== ""
    ) {
      for (const recipe of recipes) {
        if (recipe.name === addRecipeName) {
          requestCartAddRecipe(recipe.id);
        }
      }
    } else if (addItemName) {
      requestCartAddItem(addItemName, addItemQuantity || "");
    }
    setState({
      ...state,
      addItemQuantity: "",
      addItemName: "",
      addRecipeName: "",
      addToCartOpen: false,
    });
  }

  let body;

  if (!cart && isDataLoading(cartData)) {
    body = <GLoading />;
  } else {
    body = (
      <CartItemContainer>
        {allItems.map((i: any) => (
          <CartItem key={`${i.quantity}${i.name}`} item={i} />
        ))}
        {allItems.length > 0 && (
          <ClearButton
            variant={"outlined"}
            startIcon={<DeleteSweepIcon />}
            onClick={() => setState({ ...state, clearConfirmOpen: true })}
          >
            Clear Cart
          </ClearButton>
        )}
      </CartItemContainer>
    );
  }

  return (
    <>
      <Header pageTitle={"Cart"} />
      {body}

      <GFab
        icon={<AddIcon />}
        offset={6}
        onClick={() => setState({ ...state, addToCartOpen: true })}
      />

      <Dialog open={clearConfirmOpen || false}>
        <DialogTitle>Confirm Clear Cart</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm that you want to clear your cart, this cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeClearDialog}>Cancel</Button>
          <Button onClick={clearCart} color={"primary"}>
            Clear
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={addToCartOpen || false}>
        <DialogTitle>Add to Cart</DialogTitle>
        <DialogContent>
          <Tabs
            centered
            value={currentTab}
            onChange={(e, t: number) => setState({ ...state, currentTab: t })}
          >
            <Tab label={"Item"} value={0} />
            <Tab label={"Recipe"} value={1} />
          </Tabs>
          <br />
          {currentTab === 0 && (
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TextField
                  variant={"outlined"}
                  label={"Quantity"}
                  value={addItemQuantity}
                  onChange={(e) =>
                    setState({ ...state, addItemQuantity: e.target.value })
                  }
                  fullWidth={true}
                />
              </Grid>
              <Grid item xs={12}>
                &nbsp;
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant={"outlined"}
                  label={"Name"}
                  value={addItemName}
                  onChange={(e) =>
                    setState({ ...state, addItemName: e.target.value })
                  }
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          )}
          {currentTab === 1 && (
            <div>
              <Select
                value={addRecipeName}
                onChange={(e: any) =>
                  setState({ ...state, addRecipeName: e.target.value })
                }
                variant={"outlined"}
                fullWidth={true}
              >
                {recipes.map((r) => (
                  <MenuItem key={r.id} value={r.name}>
                    {r.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddToCartDialog}>Cancel</Button>
          <Button
            onClick={addNewItem}
            color={"primary"}
            startIcon={<AddShoppingCartIcon />}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
