import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAPIError } from "../lib/GRequest";
import { IAuthLoginResponse } from "./AuthApi1";
import { DEFAULT_AUTH_STORE } from "./store";

export interface ILoginRequest {
  username: string;
  password: string;
}
export const loginRequest = createAction<ILoginRequest>("auth/loginRequest");
export const logoutRequest = createAction("auth/logoutRequest");

const authSlice = createSlice({
  name: "auth",
  initialState: DEFAULT_AUTH_STORE,
  reducers: {
    loginSuccess(state, action: PayloadAction<IAuthLoginResponse>) {
      return {
        ...state,
        error: "",
        userId: action.payload.user.id,
        userName: action.payload.user.username,
        userToken: action.payload.token,
        permissions: action.payload.user.permissions,
      };
    },
    loginError(state, action: PayloadAction<IAPIError>) {
      return { ...DEFAULT_AUTH_STORE, error: action.payload.message };
    },
    logoutSuccess() {
      return DEFAULT_AUTH_STORE;
    },
  },
});

export const { loginSuccess, loginError, logoutSuccess } = authSlice.actions;
export default authSlice.reducer;
