import * as React from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Color } from "../../lib/colors";
import GTitle from "../../lib/GTitle";
import { IStockData } from "../StocksApi";
import { CustomTooltip } from "./common";

interface IProps {
  sd: IStockData;
}

export default (props: IProps) => {
  const { sd } = props;

  return (
    <>
      <GTitle title={"Account Balance"} sub />
      <LineChart width={1000} height={400} data={sd.series}>
        <Line
          dot={false}
          strokeWidth={3}
          type={"linear"}
          dataKey={"value"}
          stroke={Color.Green}
        />
        <Line
          dot={false}
          strokeWidth={3}
          type={"linear"}
          dataKey={"invested"}
          stroke={Color.Yellow}
        />
        <Line
          dot={false}
          strokeWidth={3}
          type={"linear"}
          dataKey={"share_profit"}
          stroke={Color.Orange}
        />
        <Tooltip content={CustomTooltip} />
        <XAxis
          dataKey={"timestamp"}
          tickFormatter={(value) => new Date(value * 1000).toLocaleDateString()}
          angle={45}
          tickMargin={25}
          height={60}
        />
        <YAxis
          tickFormatter={(value) => `$${(value / 1000).toFixed(0)}K`}
          width={40}
          domain={[0, "dataMax + 5000"]}
        />
        <CartesianGrid strokeDasharray="5 5" />
      </LineChart>
    </>
  );
};
