import EventIcon from "@material-ui/icons/Event";
import * as React from "react";
import { IRecipe } from "../RecipeApi1";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  recipe: IRecipe;
}

const getDaysSinceLastAdded = (recipe: IRecipe) => {
  const diffSeconds =
    (new Date().getTime() - new Date(recipe.last_added_to_cart).getTime()) /
    1000;
  return diffSeconds / 60 / 60 / 24;
};

export const shouldShowLastAdded = (recipe: IRecipe) => {
  if (!recipe.last_added_to_cart) {
    return false;
  }
  const diffDays = getDaysSinceLastAdded(recipe);
  return diffDays > 14;
};

export default (props: IProps) => {
  const { recipe } = props;
  let lastAddedDate = "Never";
  let value = "?";
  let suffix = "";
  if (recipe.last_added_to_cart) {
    lastAddedDate = new Date(recipe.last_added_to_cart).toLocaleDateString();

    const diffDays = getDaysSinceLastAdded(recipe);
    value = diffDays.toFixed(0);
    suffix = "days";
  }
  return (
    <RecipeAttr
      title={`Last added to cart ${lastAddedDate}`}
      icon={<EventIcon />}
      value={value}
      suffix={suffix}
    />
  );
};
