import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import history from "main/history";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import styled from "@material-ui/core/styles/styled";
import { Color } from "./lib/colors";
import Home from "./main/Home";
import RecipeRouter from "./recipes/router";
import registerServiceWorker from "./registerServiceWorker";
import Resume from "./resume/Resume";
import { PERSIST_STORE, STORE } from "./store";
import ToolsRouter from "./tools/router";
import StocksRouter from "./stocks/router";
import { StylesProvider } from "@material-ui/core/styles";
import AuthRouter from "./auth/router";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: { main: Color.Purple },
    secondary: { main: Color.Blue },
    background: {
      default: Color.Background,
      paper: Color.Dark,
    },
  },
});

const MainBody = styled("div")({
  maxWidth: "1000px",
  marginLeft: "auto",
  marginRight: "auto",
});

Sentry.init({
  dsn:
    "https://c2cfcd295b4c404cb67a4686d557b968@o560749.ingest.sentry.io/5696661",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={STORE}>
    <PersistGate persistor={PERSIST_STORE}>
      <Router history={history}>
        <MuiThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <CssBaseline />
            <MainBody>
              <Switch>
                <Route path={"/account"} component={AuthRouter} />
                <Route path={"/recipe"} component={RecipeRouter} />
                <Route path={"/resume"} component={Resume} />
                <Route path={"/tools"} component={ToolsRouter} />
                <Route path={"/stocks"} component={StocksRouter} />
                <Route path={"/"} component={Home} />
              </Switch>
            </MainBody>
          </StylesProvider>
        </MuiThemeProvider>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById("root") as HTMLElement
);
registerServiceWorker();
