import { PayloadAction } from "@reduxjs/toolkit";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import history from "../main/history";
import { IStoreState } from "../store";
import AuthApi1 from "./AuthApi1";
import {
  ILoginRequest,
  loginError,
  loginSuccess,
  loginRequest,
  logoutRequest,
  logoutSuccess,
} from "./reducer";

function* loginRequestSaga(
  action: PayloadAction<ILoginRequest>
): Generator<any, any, any> {
  const response = yield call(
    AuthApi1.login,
    action.payload.username,
    action.payload.password
  );

  if (response.error) {
    yield put(loginError(response));
  } else {
    // dump the login success action first
    yield put(loginSuccess(response));

    // determine if we were referred to this page from another by auth error
    const state: any = history.location.state || {
      from: null,
      search: null,
      sourceAction: null,
    };
    if (state.from) {
      // build the path and query args to where we were referred from
      const dest = `${state.from}${state.search || ""}`;

      // go back to where we came
      yield history.push(dest, {});

      // kick off the action that failed
      if (state.sourceAction) {
        yield put(state.sourceAction);
      }
    }
  }
}

function* logoutRequestSaga(): Generator<any, any, any> {
  const apiToken = yield select((store: IStoreState) => store.auth.userToken);
  if (apiToken) {
    yield call(AuthApi1.logout, apiToken);
  }
  yield put(logoutSuccess());
}

export default function* rootSaga() {
  yield all([
    takeEvery(loginRequest.toString(), loginRequestSaga),
    takeEvery(logoutRequest.toString(), logoutRequestSaga),
  ]);
}
