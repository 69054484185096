import GRequest, { IAPIError } from "../lib/GRequest";

/**
 * Package Versions
 */
export interface IHostVersion {
  hostname: string;
  version: string;
  reported_at: Date;
}

export interface IPackageVersion {
  name: string;
  latest: string;
  hosts: string[];
  versions: IHostVersion[];
}

export interface IPackageVersionsResponse {
  packages: IPackageVersion[];
}

export type PackageVersionsResponse = IPackageVersionsResponse | IAPIError;

/**
 * Speed Tests
 */
export interface ISpeedTest {
  id: number;
  logged_at: Date;
  ping: number;
  upload: number;
  download: number;
}

export interface ISpeedTestsResponse {
  speedtests: ISpeedTest[];
}

export type SpeedTestsResponse = ISpeedTestsResponse | IAPIError;

/**
 * IP Address History
 */
export interface IUsedIp {
  id: string;
  ip: string;
  start: string;
  end: string;
}

export interface IIpAddressHistoryResponse {
  ips: IUsedIp[];
}

export type IpHistoryAPIResponse = IIpAddressHistoryResponse | IAPIError;

/**
 * ZFS Drive Monitor
 */
export interface IZfsDriveChange {
  change: string;
  occurred_at: Date;
}

export enum ZfsDriveState {
  Online = "ONLINE",
  Offline = "OFFLINE",
  Faulted = "FAULTED",
}

export interface IZfsDrive {
  id: number;
  serial: string;
  bay_number: number;
  state: ZfsDriveState;
  changelog: IZfsDriveChange[];
  first_seen: Date;
  last_seen: Date;
  checksum_errors: number;
  read_errors: number;
  write_errors: number;
}

export interface IZfsDrivesResponse {
  drives: IZfsDrive[];
}

export type ZfsDrivesResponse = IZfsDrivesResponse | IAPIError;

export type ZfsSetBayNumberResponse = IZfsDrive | IAPIError;

class ToolsApi {
  /**
   * Get all the package versions
   */
  public static packageVersions(
    token: string
  ): Promise<PackageVersionsResponse> {
    return new GRequest("versions", "GET", token).fetch();
  }

  /**
   * Get all speedtest results
   */
  public static speedtests(token: string): Promise<SpeedTestsResponse> {
    return new GRequest("speedtests", "GET", token).fetch();
  }

  /**
   *  IP Address History
   */
  public static ipAddresses(token: string): Promise<IpHistoryAPIResponse> {
    return new GRequest("ips", "GET", token).fetch();
  }

  /**
   * ZFS Monitor
   */
  public static zfsDrives(token: string): Promise<ZfsDrivesResponse> {
    return new GRequest("zfs", "GET", token).fetch();
  }

  public static setBayNumber(
    token: string,
    serial: string,
    bay: number
  ): Promise<ZfsSetBayNumberResponse> {
    return new GRequest(`zfs/bay/${bay}`, "PATCH", token)
      .body({ serial })
      .fetch();
  }
}

export default ToolsApi;
