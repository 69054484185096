import TimelapseIcon from "@material-ui/icons/Timelapse";
import * as React from "react";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  minutes: number;
}

export default function RecipeTime(props: IProps) {
  const { minutes } = props;
  return <RecipeAttr title={"Recipe Cook Time"} icon={<TimelapseIcon/>} value={minutes.toString()} suffix={"min."}/>;
}
