import { PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";
import authenticatedSaga from "../auth/authenticatedSaga";
import StocksApi, { IStock, IStockData } from "./StocksApi";
import {
  stockAddRequest,
  stockSaleRequest,
  stockSaleRequestSuccess,
  stockSaleRequestError,
  stockAddRequestSuccess,
  stockAddRequestError,
  stockDataRequest,
  stockDataRequestSuccess,
  stockDataRequestError,
} from "./reducer";

function* stockAddRequestSaga(action: PayloadAction<IStock>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: StocksApi.purchaseStock,
    success: stockAddRequestSuccess,
    error: stockAddRequestError,
    args: [action.payload],
  });
  yield put(stockDataRequest());
}

function* stockSaleRequestSaga(action: PayloadAction<IStock>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: StocksApi.sellStock,
    success: stockSaleRequestSuccess,
    error: stockSaleRequestError,
    args: [action.payload],
  });
}

function* stockDataRequestSaga(action: PayloadAction) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: StocksApi.stockData,
    success: (r: IStockData) => stockDataRequestSuccess(r),
    error: stockDataRequestError,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(stockAddRequest.toString(), stockAddRequestSaga),
    takeEvery(stockSaleRequest.toString(), stockSaleRequestSaga),
    takeLatest(stockDataRequest.toString(), stockDataRequestSaga),
  ]);
}
