import Fraction from "fraction.js/fraction";
import GRequest, { IAPIError } from "../lib/GRequest";
import pluralize from "pluralize";

export enum MealType {
  Breakfast = "breakfast",
  Lunch = "lunch",
  Dinner = "dinner",
  Dessert = "dessert",
  Snack = "snack",
  Drink = "drink",
}

export enum DietaryFlag {
  Vegan = "vegan",
  Vegetarian = "vegetarian",
  DairyFree = "dairyFree",
}

export interface IIngredient {
  name: string;
  quantity: string;
}

interface ISplitQuantity {
  amount?: string;
  label?: string;
}

export interface IInstruction {
  step: number;
  ingredients: IIngredient[];
  minutes?: number;
  text: string;
}

export interface IRecipe {
  // identifiers
  id: number;
  name: string;

  // extra props
  complexity?: string;
  cost?: number;
  meals?: number;
  leftover_days?: number;
  pots_and_pans?: number;
  dietary_flags?: DietaryFlag[];
  meal_types: MealType[];
  source_url?: string;
  photo?: string;
  instruction_list: IInstruction[];

  // computed
  minutes: number;
  ingredient_list: IIngredient[];
  last_added_to_cart: Date;
}

export interface IRecipesResponse {
  recipes: IRecipe[];
}

export type RecipesResponse = IRecipesResponse | IAPIError;

const HAS_AMOUNT_REGEX = /^([0-9]|[0-9][\S]*[0-9])$/;
const IS_SIMPLE_FRACTION_REGEX = /^[0-9]+[/][0-9]+$/;
const ABBREVIATION_MAP = new Map();
ABBREVIATION_MAP.set("tablespoon", "Tbsp");
ABBREVIATION_MAP.set("tablespoons", "Tbsps");
ABBREVIATION_MAP.set("teaspoon", "tsp");
ABBREVIATION_MAP.set("teaspoons", "tsps");
ABBREVIATION_MAP.set("package", "pkg");
ABBREVIATION_MAP.set("packages", "pkgs");

export default class RecipeApi1 {
  /**
   * Get all the recipes
   */
  public static recipes(token: string): Promise<RecipesResponse> {
    return new GRequest("recipes", "GET", token).fetch();
  }

  /**
   * Create a new recipe
   */
  public static createRecipe(token: string, name: string): Promise<IRecipe> {
    return new GRequest("recipes", "POST", token).body({ name }).fetch();
  }

  /**
   * Delete a recipe
   */
  public static deleteRecipe(token: string, id: number): Promise<any> {
    return new GRequest(`recipes/${id}`, "DELETE", token).fetch();
  }

  /**
   * Get a single recipe
   */
  public static recipe(token: string, id: number): Promise<IRecipe> {
    return new GRequest(`recipes/${id}`, "GET", token).fetch();
  }

  /**
   * Update a recipe
   */
  public static updateRecipe(token: string, recipe: IRecipe): Promise<IRecipe> {
    return new GRequest(`recipes/${recipe.id}`, "PATCH", token)
      .body(recipe)
      .fetch();
  }
}

/**
 * Select a particular recipe from the list of recipes by id
 * @param recipes
 * @param id
 */
export function selectRecipe(
  recipes?: IRecipe[],
  id?: string
): IRecipe | undefined {
  const recipeId = parseInt(id || "", 10);

  if (recipes) {
    for (const r of recipes) {
      if (r.id === recipeId) {
        return r;
      }
    }
  }
}

/**
 * Condense a list of ingredients down, adding same ingredients together
 * @param ingredients
 */
export function condenseIngredients(ingredients: IIngredient[]) {
  const normalized = new Map();

  for (const ingredient of ingredients) {
    const name = ingredient.name.toLowerCase();
    // eslint-disable-next-line prefer-const
    let { amount, label } = splitQuantity(ingredient.quantity);
    const amountNumber = amount ? normalizeQuantityAmount(amount) : amount;

    if (label) {
      label = pluralize.singular(label);
    }

    // condense ingredient amounts by name and unit
    if (normalized.has(name)) {
      const labelMap = normalized.get(name);
      if (labelMap.has(label)) {
        // try to add our new value to the existing one
        labelMap.set(label, labelMap.get(label) + amountNumber);
      } else {
        // new unit for this ingredient
        labelMap.set(label, amountNumber);
      }
    } else {
      // create a fresh unit map
      const labelMap = new Map();
      labelMap.set(label, amountNumber);
      normalized.set(name, labelMap);
    }
  }

  // format the condensed results
  const results: IIngredient[] = [];
  for (const name of normalized.keys()) {
    const labelMap = normalized.get(name);

    for (const label of labelMap.keys()) {
      const amount = labelMap.get(label);
      const displayLabel = label ? pluralize(label, amount) : label;
      const quantity = amount || label ? `${amount} ${displayLabel}` : "";
      results.push({ name, quantity });
    }
  }

  // sort the ingredients
  return results.sort(ingredientComparator);
}

/**
 * Split recipe quantities into a numeric amount and/or an alphanumeric label
 * @param quantity
 */
export function splitQuantity(quantity?: string): ISplitQuantity {
  quantity = quantity && quantity.trim();

  if (!quantity) {
    return {};
  }

  // ingredient quantity formats are annoying:
  // 1 1/2 cups
  // ^^^^^      -- amount, can be fraction/whole+fraction/decimal/whole
  //      ^     -- space to split
  //       ^^^^ -- unit
  // all of which are optional
  const qParts = quantity.split(" ");
  const amountParts = [];
  const labelParts = [];

  let pastAmounts = false;
  for (const part of qParts) {
    if (!pastAmounts && HAS_AMOUNT_REGEX.test(part)) {
      amountParts.push(part);
      continue;
    }

    pastAmounts = true;
    labelParts.push(part);
  }

  const amount = amountParts.join(" ");
  let label = labelParts.join(" ");

  if (ABBREVIATION_MAP.has(label.toLowerCase())) {
    label = ABBREVIATION_MAP.get(label.toLowerCase());
  }

  return { amount, label };
}

/**
 * Convert a string representation of a numeric amount into a number
 * @param amount
 */
export function normalizeQuantityAmount(amount?: string): number {
  // supported formats for quantity amounts (note: without labels)
  // [[M] W] N/Dn -- simple fraction
  // [M] W.Dc     -- simple decimals
  if (!amount) {
    return 0;
  }

  const amountParts = amount.trim().split(" ");
  let whole = 0;
  let numerator = 0;
  let denominator = 1;

  for (const part of amountParts) {
    try {
      if (IS_SIMPLE_FRACTION_REGEX.test(part)) {
        const fractionParts = part.split("/");
        numerator = parseInt(fractionParts[0], 10);
        denominator = parseInt(fractionParts[1], 10);
      } else {
        whole = parseFloat(part);
      }
    } catch (e) {
      console.log(e);
    }
  }

  return whole + new Fraction(numerator, denominator).valueOf();
}

/**
 * Sort ingredients
 * @param a
 * @param b
 */
function ingredientComparator(a: IIngredient, b: IIngredient) {
  const qA = a.quantity;
  const qB = b.quantity;
  if (!qA) return 1;
  if (!qB) return -1;

  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0;
}

/**
 * Collect ingredients from steps
 * @param instructions
 */
export function collectIngredients(instructions: IInstruction[]) {
  let ingredients: IIngredient[] = [];

  for (const instruction of instructions) {
    ingredients = [...ingredients, ...instruction.ingredients];
  }

  return ingredients;
}

/**
 * Sort recipes in place
 * @param recipes
 */
export function sortRecipes(recipes: IRecipe[]): IRecipe[] {
  let sortedRecipes = [...recipes]; // eslint-disable-line prefer-const
  sortedRecipes.sort((a: IRecipe, b: IRecipe) => {
    const al: string = a.name.toLowerCase();
    const bl: string = b.name.toLowerCase();
    if (al < bl) return -1;
    if (bl < al) return 1;
    return 0;
  });
  return sortedRecipes;
}
