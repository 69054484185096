import {TextField} from "@material-ui/core";
import {Color} from "lib/colors";
import GAPIData, {IGAPIDataState, useGData} from "lib/GAPIData";
import GCheckbox from "lib/GCheckbox";
import {contains, localDateString} from "lib/string";
import Header from "main/Header";
import MaterialTable from "material-table";
import React, {useState} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {IStoreState} from "store";
import styled from "@material-ui/core/styles/styled";
import {newPackageVersionsRequest} from "../actions/packageVersionsRequest";
import {IPackageVersion} from "../ToolsApi";

interface IPackageDataRow {
  packageName: string;
  hostVersions: Map<string, string>;
  latest: string;
}

const PackageHeaderRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  marginBottom: "2em",
});

const HostCheckboxColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

interface IPackageVersionsDisplayProps {
  packages: IPackageVersion[];
}

function doHostsHaveDiffs(hostVersions: Map<string, string>, selectedHosts: string[]): boolean {
  const versions = [];

  for (const h of selectedHosts) {
    versions.push(hostVersions.get(h));
  }

  return !versions.every((val, i, arr) => val === arr[0]);
}

function PackageVersionsDisplay(props: IPackageVersionsDisplayProps) {
  const [state, setState] = useState<IPackageVersionsState>({shownHosts: new Map(), onlyDiff: false});
  const {packages} = props;
  const {shownHosts, searchFilter, onlyDiff} = state;

  /**
   * State usages
   */
  function isHostChecked(h: string): boolean {
    return !shownHosts.has(h) || shownHosts.get(h) === true;
  }

  function checkHost(host: string, checked: boolean) {
    shownHosts.set(host, !checked);
    setState({ ...state, shownHosts });
  }

  function searchOnChange(e: any) {
    setState({ ...state, searchFilter: e.target.value });
  }

  /**
   * Build row data
   */
  let rows: IPackageDataRow[] = [];
  const hostUpdates = new Map<string, Date>();

  if (packages) {
    for (const pkg of packages) {
      const hostVersions = new Map();

      for (const host of pkg.versions) {
        hostUpdates.set(host.hostname, host.reported_at);
        hostVersions.set(host.hostname, host.version);
      }

      if (!searchFilter || contains(searchFilter, pkg.name)) {
        for (const h of hostVersions.keys()) {
          if (isHostChecked(h)) {
            // only add the row if at least 1 host in the row is checked
            rows.push({packageName: pkg.name, latest: pkg.latest, hostVersions});
            break;
          }
        }
      }
    }
  }

  const hosts = [...hostUpdates.keys()].sort();

  /**
   * Build checkboxes
   */
  const hostSelects: JSX.Element[] = [];
  const selectedHosts = [];
  for (const h of hosts) {
    const checked: boolean = isHostChecked(h);
    if (checked) {
      selectedHosts.push(h);
    }
    hostSelects.push(
      (
        <HostCheckboxColumn key={h}>
          <GCheckbox checked={checked} onChange={() => checkHost(h, checked)} label={h}/>
          {localDateString(hostUpdates.get(h), false)}
        </HostCheckboxColumn>
      )
    );
  }

  /**
   * Filter out columns without differences
   */
  if (onlyDiff) {
    const newRows = [];

    for (const row of rows) {
      if (doHostsHaveDiffs(row.hostVersions, selectedHosts)) {
        newRows.push(row);
      }
    }

    rows = newRows;
  }

  /**
   * Build columns
   */
  function hostColumn(name: string) {
    return {
      title: name,
      cellStyle: (_: any, row: IPackageDataRow) => {
        if (!row.hostVersions.has(name)) {
          return {color: Color.Gray};
        }
        if (row.latest !== row.hostVersions.get(name)) {
          return {color: Color.Red};
        }
        return {color: Color.Green};
      },
      render: (row: IPackageDataRow) => row.hostVersions.get(name) || "?",
    };
  }

  function buildColumns(): any[] {
    const columns: any[] = [{ title: "Name", field: "packageName" }];

    for (const h of hosts) {
      if (isHostChecked(h)) {
        columns.push(hostColumn(h));
      }
    }

    return columns;
  }

  return (
    <>
      <PackageHeaderRow>
        <TextField
          label="Search field"
          type="search"
          margin="normal"
          onChange={searchOnChange}
          variant={"outlined"}
          fullWidth={true}
        />
      </PackageHeaderRow>
      <PackageHeaderRow>
        <HostCheckboxColumn key={"z"}>
          <GCheckbox checked={onlyDiff} onChange={() => setState({...state, onlyDiff: !onlyDiff})} label={"Only Differences"}/>
        </HostCheckboxColumn>
        {hostSelects}
      </PackageHeaderRow>
      <MaterialTable
        data={rows}
        style={{
          backgroundColor: Color.Background,
        }}
        columns={buildColumns()}
        options={{
          search: false,
          showTitle: false,
          toolbar: false,
          pageSize: 20,
          headerStyle: {
            color: Color.Primary,
            fontSize: "large",
          },
        }}
      />
    </>
  );
}

interface IPackageVersionsProps {
  requestPackageVersions: () => void;
  packagesData?: IGAPIDataState<IPackageVersion>;
}

interface IPackageVersionsState {
  searchFilter?: string;
  shownHosts: Map<string, boolean>;
  onlyDiff: boolean;
}

export default connect(
  (store: IStoreState) => {
    return {
      packagesData: store.tools.packagesData,
    };
  },
  (dispatch: Dispatch) => {
    return {
      requestPackageVersions: () => dispatch(newPackageVersionsRequest()),
    };
  },
)((props: IPackageVersionsProps) => {
  const {packagesData, requestPackageVersions} = props;

  return (
    <>
      <Header pageTitle={"Packages"}/>
      <GAPIData request={requestPackageVersions} state={packagesData}>
        <PackageVersionsDisplay packages={useGData(packagesData)}/>
      </GAPIData>
    </>
  );
});
