import * as React from "react";
// import {useState} from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import Header from "../../main/Header";
import {IStoreState} from "../../store";

/**
 * Primary component
 */
// interface IProps {
// }
//
// interface IState {
// }

export default connect(
  (store: IStoreState) => {
    return {};
  },
  (dispatch: Dispatch) => {
    return {};
  },
)((props: any) => {
  // const [state, setState] = useState<IState>({});
  // const {} = props;

  return (
    <Header pageTitle={"Meal Planner"}/>
  );
});
