import * as React from "react";
import styled from "@material-ui/core/styles/styled";
import GCard from "../../lib/GCard";
import {Color} from "../../lib/colors";

const binghamtonGreen = "#005A43";

const BingTitle = styled("span")({
  color: binghamtonGreen,
  fontSize: "14pt",
});

const EducationContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export default function EducationCard() {
  return (
    <GCard center variant={Color.White}>
      <EducationContainer>
        <BingTitle>Binghamton University<br/><br/></BingTitle>
        <img src="/resume/binghamton.jpg" alt=""/>
        <div>
          <br/>
          B.S. Computer Science<br/>
          Class of 2012
        </div>
      </EducationContainer>
    </GCard>
  );
}
