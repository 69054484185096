import {applyMiddleware, combineReducers, createStore} from "redux";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/es/storage";
import createSagaMiddleware from "redux-saga";
import {authReducer, authSagas, IAuthStore} from "auth";
import {IRecipeStore, recipesReducer, recipesSagas} from "recipes";
import {IResumeStore, resumeReducer} from "resume";
import {IToolsStore, toolsReducer, toolsSagas} from "tools";
import {IStocksStore, stocksReducer, stocksSagas} from "./stocks";

export interface IStoreState {
  auth: IAuthStore;
  tools: IToolsStore;
  recipes: IRecipeStore;
  resume: IResumeStore;
  stocks: IStocksStore;
}

const rootReducer = combineReducers({
  auth: authReducer,
  tools: toolsReducer,
  recipes: recipesReducer,
  resume: resumeReducer,
  stocks: stocksReducer,
});
const sagaMiddleware = createSagaMiddleware();
export const STORE = createStore<any, any, any, any>(
  persistReducer({key: "root", storage}, rootReducer),
  applyMiddleware(sagaMiddleware),
);
sagaMiddleware.run(authSagas);
sagaMiddleware.run(toolsSagas);
sagaMiddleware.run(recipesSagas);
sagaMiddleware.run(stocksSagas);
export const PERSIST_STORE = persistStore(STORE);
