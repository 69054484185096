import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  handleItemAddition,
  handleItemReplacement,
  handleRequestErrorState,
  handleRequestState,
  handleRequestSuccessState,
  handleStale,
} from "../lib/GAPIData";
import { IAPIError } from "../lib/GRequest";
import { CheckType, ICart, ICartItem } from "./CartApi1";
import { IRecipe } from "./RecipeApi1";
import { IRecipeStore } from "./store";

const CART_MAX_AGE = 60;

function cartQuickUpdate(
  state: IRecipeStore,
  cartUpdate: (cart: ICart) => ICart
) {
  if (state.cartData?.data) {
    const cart: ICart = { ...state.cartData.data[0] };
    return {
      ...state,
      cartData: handleRequestSuccessState(
        [cartUpdate(cart)],
        state.cartData,
        CART_MAX_AGE,
        false
      ),
    };
  } else {
    return state;
  }
}

function checkUpdateSimpleItem(
  cart: ICart,
  item: ICartItem,
  checked: boolean
): ICart {
  const items: ICartItem[] = [];

  cart.items.forEach((ci) => {
    if (ci.quantity === item.quantity && ci.name === item.name) {
      items.push({ ...ci, checked });
    } else {
      items.push({ ...ci });
    }
  });

  return { ...cart, items: items };
}

function checkItem(cart: ICart, item: ICartItem): ICart {
  if (item.type === CheckType.Item) {
    return checkUpdateSimpleItem(cart, item, true);
  } else {
    let inCheckedSet = false;
    const checked_ingredients = [...cart.checked_ingredients];

    for (const i of checked_ingredients) {
      if (i.quantity === item.quantity && i.name === item.name) {
        inCheckedSet = true;
        break;
      }
    }

    if (!inCheckedSet) {
      checked_ingredients.push({
        name: item.name,
        quantity: item.quantity,
      });
    }

    return { ...cart, checked_ingredients };
  }
}

function unCheckItem(cart: ICart, item: ICartItem): ICart {
  if (item.type === CheckType.Item) {
    return checkUpdateSimpleItem(cart, item, false);
  } else {
    let indexToRemove = -1;
    const checked_ingredients = [...cart.checked_ingredients];

    for (let i = 0; i < checked_ingredients.length; i++) {
      const ing = checked_ingredients[i];
      if (ing.quantity === item.quantity && ing.name === item.name) {
        indexToRemove = i;
        break;
      }
    }

    if (indexToRemove >= 0) {
      checked_ingredients.splice(indexToRemove, 1);
    }

    return { ...cart, checked_ingredients };
  }
}

export const createRecipeRequest = createAction<string>(
  "recipes/createRecipeRequest"
);
export const deleteRecipeRequest = createAction<IRecipe>(
  "recipes/deleteRecipeRequest"
);
export const updateRecipeRequest = createAction<IRecipe>(
  "recipes/updateRecipeRequest"
);

export const cartAddItemRequest = createAction<ICartItem>(
  "recipes/cartAddItemRequest"
);
export const cartAddRecipeRequest = createAction<number>(
  "recipes/cartAddRecipeRequest"
);

export const cartCheckRequest = createAction<ICartItem>(
  "recipes/cartCheckRequest"
);
export const cartUncheckRequest = createAction<ICartItem>(
  "recipes/cartUncheckRequest"
);

const recipeSlice = createSlice({
  name: "recipes",
  initialState: {} as IRecipeStore,
  reducers: {
    /**
     * Request Recipes
     */
    recipesRequest(state) {
      return {
        ...state,
        recipeData: handleRequestState(state.recipeData),
      };
    },
    recipesRequestSuccess(state, action: PayloadAction<IRecipe[]>) {
      return {
        ...state,
        recipeData: handleRequestSuccessState(action.payload, state.recipeData),
      };
    },
    recipesRequestError(state, action: PayloadAction<IAPIError>) {
      return {
        ...state,
        recipeData: handleRequestErrorState(
          action.payload.message,
          state.recipeData
        ),
      };
    },

    /**
     * Create recipe
     */
    createRecipeRequestSuccess(state, action: PayloadAction<IRecipe>) {
      return {
        ...state,
        createRecipeError: "",
        recipeData: handleItemAddition(action.payload, state.recipeData),
      };
    },
    createRecipeRequestError(state, action: PayloadAction<IAPIError>) {
      return {
        ...state,
        createRecipeError: action.payload.message,
      };
    },

    deleteRecipeRequestSuccess(state, action: PayloadAction<IRecipe>) {
      return { ...state, recipeData: handleStale(state.recipeData) };
    },
    deleteRecipeRequestError(state, action: PayloadAction<IRecipe>) {
      return { ...state, recipeData: handleStale(state.recipeData) };
    },

    /**
     * Update recipe
     */
    updateRecipeRequestSuccess(state, action: PayloadAction<IRecipe>) {
      return {
        ...state,
        updateRecipeError: "",
        recipeData: handleItemReplacement(
          action.payload,
          (r) => r.id,
          state.recipeData
        ),
      };
    },
    updateRecipeRequestError(state, action: PayloadAction<IAPIError>) {
      return {
        ...state,
        updateRecipeError: action.payload.message,
      };
    },

    /**
     * Cart
     */
    cartRequest(state) {
      return {
        ...state,
        cartData: handleRequestState(state.cartData, CART_MAX_AGE),
      };
    },
    cartRequestSuccess(state, action: PayloadAction<ICart>) {
      return {
        ...state,
        cartData: handleRequestSuccessState(
          [action.payload],
          state.cartData,
          CART_MAX_AGE
        ),
      };
    },
    cartRequestError(state, action: PayloadAction<IAPIError>) {
      return {
        ...state,
        cartData: handleRequestErrorState(
          action.payload.message,
          state.cartData,
          CART_MAX_AGE
        ),
      };
    },

    /**
     * Cart Check/Uncheck
     */
    cartCheckRequestSuccess(state, action: PayloadAction<ICartItem>) {
      if (state.cartData?.data) {
        const cart: ICart = { ...state.cartData.data[0] };
        return {
          ...state,
          cartData: handleRequestSuccessState(
            [checkItem(cart, action.payload)],
            state.cartData,
            CART_MAX_AGE,
            false
          ),
        };
      }
    },
    cartUncheckRequestSuccess(state, action: PayloadAction<ICartItem>) {
      if (state.cartData?.data) {
        const cart: ICart = { ...state.cartData.data[0] };
        return {
          ...state,
          cartData: handleRequestSuccessState(
            [unCheckItem(cart, action.payload)],
            state.cartData,
            CART_MAX_AGE,
            false
          ),
        };
      }
    },
    cartCheckRequestError(state) {
      return state;
    },

    /**
     * Cart Clear
     */
    cartClearRequest(state) {
      return state;
    },
    cartClearRequestSuccess(state) {
      return {
        ...state,
        cartData: handleRequestSuccessState([], state.cartData, CART_MAX_AGE),
      };
    },
    cartClearRequestError(state, action: PayloadAction<IAPIError>) {
      return state;
    },

    /**
     * Cart Add Item
     */
    cartAddItemRequestSuccess(state, action: PayloadAction<ICartItem>) {
      return cartQuickUpdate(state, (cart) => {
        const cartItems = [
          ...(cart.items || []),
          {
            name: action.payload.name,
            quantity: action.payload.quantity,
            checked: false,
          },
        ];
        return { ...cart, items: cartItems };
      });
    },
    cartAddItemRequestError(state, action: PayloadAction<IAPIError>) {
      return state;
    },

    /**
     * Cart Add Recipe
     */
    cartAddRecipeRequestSuccess(state, action: PayloadAction<number>) {
      return cartQuickUpdate(state, (cart) => {
        const cartRecipes = [
          ...(cart.recipes || []),
          { recipe_id: action.payload, quantity: 1 },
        ];
        return { ...cart, recipes: cartRecipes };
      });
    },
    cartAddRecipeRequestError(state, action: PayloadAction<IAPIError>) {
      return state;
    },
  },
});

export const {
  recipesRequest,
  recipesRequestSuccess,
  recipesRequestError,
  cartAddItemRequestSuccess,
  cartAddRecipeRequestSuccess,
  cartCheckRequestError,
  cartCheckRequestSuccess,
  cartClearRequestSuccess,
  cartRequest,
  cartRequestError,
  cartRequestSuccess,
  cartUncheckRequestSuccess,
  createRecipeRequestError,
  createRecipeRequestSuccess,
  updateRecipeRequestError,
  updateRecipeRequestSuccess,
  cartAddItemRequestError,
  cartAddRecipeRequestError,
  cartClearRequest,
  cartClearRequestError,
  deleteRecipeRequestError,
  deleteRecipeRequestSuccess,
} = recipeSlice.actions;
export default recipeSlice.reducer;
