import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import * as React from "react";
import {IRecipe} from "../RecipeApi1";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  recipe: IRecipe;
}

export default function RecipeStepCount(props: IProps) {
  const { recipe } = props;
  const numSteps = recipe.instruction_list.length;
  return <RecipeAttr title={"Total Steps"} icon={<PlaylistAddCheckIcon/>} value={numSteps.toString()} suffix={"steps"}/>;
}
