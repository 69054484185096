import Header from "main/Header";
import * as React from "react";
import { ReactNode } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IStoreState } from "store";
import { IGAPIDataState, useGData, usePeriodically } from "../../lib/GAPIData";
import GLoading from "../../lib/GLoading";
import RefreshFooter from "../components/RefreshFooter";
import StockRow, { StockRowHeader } from "../components/StockRow";
import { stockDataRequest } from "../reducer";
import AccountSummary from "../components/AccountSummary";
import { IStockData } from "../StocksApi";
import { STOCK_DATA_MAX_AGE_SECONDS } from "../store";

export const bigText = {
  fontSize: "16pt",
  fontWeight: 100,
};

interface IStockStoreProps {
  stockData?: IGAPIDataState<IStockData>;
}

interface IStockDispatchProps {
  requestStockData: () => void;
}

type IStockProps = IStockStoreProps & IStockDispatchProps;

export default connect(
  (store: IStoreState): IStockStoreProps => {
    return {
      stockData: store.stocks.stockData,
    };
  },
  (dispatch: Dispatch): IStockDispatchProps => {
    return {
      requestStockData: () => dispatch(stockDataRequest()),
    };
  }
)((props: IStockProps) => {
  const { stockData, requestStockData } = props;

  const sd = useGData(stockData, requestStockData)[0];
  usePeriodically(STOCK_DATA_MAX_AGE_SECONDS, requestStockData, stockData);

  if (!sd) {
    return (
      <>
        <Header pageTitle={"Stocks"} />
        <GLoading />
      </>
    );
  }

  const stockRows: ReactNode[] = [];

  sd.stocks.forEach((sdq) => {
    if (!sdq.all_sold) {
      stockRows.push(<StockRow key={sdq.symbol} sdq={sdq} />);
    }
  });

  return (
    <>
      <Header pageTitle={"Stocks"} />
      <AccountSummary stockData={sd} />
      <StockRowHeader />
      {stockRows}
      <RefreshFooter stockData={sd} />
    </>
  );
});
