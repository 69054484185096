import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import Account from "./routes/Account";
import AddStocks from "./routes/AddStocks";
import DetailStock from "./routes/DetailStock";
import SellStocks from "./routes/SellStocks";
import Stocks from "./routes/Stocks";

export default () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/add`} component={AddStocks} />
      <Route path={`${match.path}/sell`} component={SellStocks} />
      <Route path={`${match.path}/account`} component={Account} />
      <Route path={`${match.path}/:symbol`} component={DetailStock} />
      <Route path={"/"} component={Stocks} />
    </Switch>
  );
};
