import GRequest from "../lib/GRequest";
import { condenseIngredients, IIngredient, IRecipe } from "./RecipeApi1";

export interface ICart {
  id: number;
  items: ICartItem[];
  recipes: ICartRecipe[];
  checked_ingredients: ICartCheckedIngredient[];
}

export interface ICartItem {
  name: string;
  quantity: string;
  checked: boolean;
  type?: CheckType;
}

export interface ICartRecipe {
  recipe_id: number;
  quantity: number;
}

export interface ICartCheckedIngredient {
  name: string;
  quantity: string;
}

export enum CheckType {
  Item = "item",
  Ingredient = "ingredient",
}

function ingredientKey(ingredient: IIngredient | ICartItem) {
  return `${ingredient.name}:${ingredient.quantity}`;
}

/**
 * Get all manual items and recipe ingredients from the cart as cart items
 * @param cart
 * @param recipes
 */
export function allCartItems(cart: ICart, recipes: IRecipe[]) {
  if (!cart) {
    return [];
  }

  // checked ingredients form this list
  const checkedIngredients = new Set(
    (cart.checked_ingredients || []).map(ingredientKey)
  );

  // map of recipes by id for easy lookup
  const recipeMap = new Map<number, IRecipe>();
  for (const recipe of (recipes || [])) {
    recipeMap.set(recipe.id, recipe);
  }

  let ingredients: IIngredient[] = [];
  for (const cartRecipe of (cart.recipes || [])) {
    const recipe = recipeMap.get(cartRecipe.recipe_id);
    if (recipe) {
      for (let i = 0; i < cartRecipe.quantity; i++) {
        ingredients = [...ingredients, ...recipe.ingredient_list];
      }
    }
  }

  const allItems: ICartItem[] = (cart.items || []).map((ci) => {
    return { ...ci, type: CheckType.Item };
  });
  if (ingredients) {
    for (const ci of condenseIngredients(ingredients)) {
      const key = ingredientKey(ci);
      allItems.push({
        ...ci,
        checked: checkedIngredients.has(key),
        type: CheckType.Ingredient,
      });
    }
  }

  return allItems.sort((a: ICartItem, b: ICartItem) => {
    if (a.checked && !b.checked) return 1;
    else if (!a.checked && b.checked) return -1;

    const aLowerName = a.name.toLocaleLowerCase();
    const bLowerName = b.name.toLocaleLowerCase();
    if (aLowerName < bLowerName) return -1;
    else if (bLowerName < aLowerName) return 1;

    return 0;
  });
}

export default class CartApi1 {
  public static getCart(token: string): Promise<ICart> {
    return new GRequest("cart", "GET", token).fetch();
  }

  public static clearCart(token: string): Promise<any> {
    return new GRequest("cart", "DELETE", token).fetch();
  }

  public static addItemToCart(
    token: string,
    name: string,
    quantity: string
  ): Promise<any> {
    return new GRequest("cart/item", "POST", token)
      .body({ name, quantity })
      .fetch();
  }

  public static removeItemFromCart(
    token: string,
    name: string,
    quantity: string
  ): Promise<any> {
    return new GRequest("cart/item", "PATCH", token)
      .body({ name, quantity })
      .fetch();
  }

  public static addRecipeToCart(
    token: string,
    recipeId: number,
    quantity: number
  ): Promise<any> {
    return new GRequest("cart/recipe", "POST", token)
      .body({
        recipe_id: recipeId,
        quantity,
      })
      .fetch();
  }

  public static removeRecipeFromCart(
    token: string,
    recipeId: number
  ): Promise<any> {
    return new GRequest("cart/recipe", "PATCH", token)
      .body({
        recipe_id: recipeId,
      })
      .fetch();
  }

  public static check(token: string, item: ICartItem): Promise<any> {
    return new GRequest("cart/check", "POST", token)
      .body({
        name: item.name,
        quantity: item.quantity,
        type: item.type,
      })
      .fetch()
      .then(() => item);
  }

  public static uncheck(token: string, item: ICartItem): Promise<any> {
    return new GRequest("cart/check", "PATCH", token)
      .body({
        name: item.name,
        quantity: item.quantity,
        type: item.type,
      })
      .fetch()
      .then(() => item);
  }
}
