import * as React from "react";
import { Route, Switch } from "react-router-dom";
import UserAccount from "./components/UserAccount";

export default () => {
  return (
    <Switch>
      <Route path={"/"} component={UserAccount} />
    </Switch>
  );
};
