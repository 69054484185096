import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ReactNode, useState} from "react";
import * as React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Color} from "lib/colors";
import GAPIData, {IGAPIDataState, useGData} from "lib/GAPIData";
import GCard from "lib/GCard";
import GTitle from "lib/GTitle";
import {localDateString} from "lib/string";
import Header from "main/Header";
import {IStoreState} from "store";
import {newSetZfsDriveBayRequest} from "../actions/setZfsDriveBayRequest";
import {newZfsDrivesRequest} from "../actions/zfsDrivesRequest";
import {IZfsDrive, IZfsDriveChange, ZfsDriveState} from "../ToolsApi";
import styled from "@material-ui/core/styles/styled";

interface IZfsDriveBoxProps {
  drive: IZfsDrive;
  openSetBayNumber: (newSerial: string) => void;
}

function ZfsDriveBox(props: IZfsDriveBoxProps) {
  const {drive, openSetBayNumber} = props;
  const changes = [];
  const ageInDays = driveAgeDays(drive);

  for (const change of drive.changelog) {
    changes.push((
      <Grid key={new Date(change.occurred_at).getTime()} item xs={12} sm={8}>
        <ZfsDriveChange change={change}/>
      </Grid>
    ));
  }

  return (
    <GCard key={drive.serial}>
      <Grid container alignItems={"center"} justify={"center"}>
        <Grid item xs={2} sm={1}>
          <Button onClick={() => openSetBayNumber(drive.serial)}>
            <BayNumber>{drive.bay_number}</BayNumber>
          </Button>
        </Grid>
        <Grid item xs={5} sm={2}>
          <ZfsDriveStatus state={drive.state}>{drive.state}</ZfsDriveStatus>
        </Grid>
        <Grid item xs={1}>
          <ErrorCount count={drive.read_errors} title={"read errors"}>{drive.read_errors}</ErrorCount>
        </Grid>
        <Grid item xs={1}>
          <ErrorCount count={drive.write_errors} title={"write errors"}>{drive.write_errors}</ErrorCount>
        </Grid>
        <Grid item xs={1}>
          <ErrorCount count={drive.checksum_errors} title={"checksum errors"}>{drive.checksum_errors}</ErrorCount>
        </Grid>
        <Grid item xs={12} sm={6}>
          <SerialNumber>{drive.serial}</SerialNumber>
        </Grid>
        <Grid item xs={5} sm={2}>
          <DriveAgeLabel>online</DriveAgeLabel> <DriveAgeValue>{ageInDays}</DriveAgeValue> <DriveAgeLabel>days</DriveAgeLabel>
        </Grid>
        <Grid item xs={12} sm={8}>
          <DriveTimeRange>{localDateString(drive.first_seen)} {'->'} {localDateString(drive.last_seen)}</DriveTimeRange>
        </Grid>
        {changes}
      </Grid>
    </GCard>
  );
}

interface IZfsDriveChangeProps {
  change: IZfsDriveChange;
}

function ZfsDriveChange(props: IZfsDriveChangeProps) {
  const {change, occurred_at} = props.change;

  return (
    <ZfsDriveChangeContainer>
      <Grid container alignItems={"center"}>
        <Grid item xs={6}>
          <DriveTimeRange>{localDateString(occurred_at)}</DriveTimeRange>
        </Grid>
        <Grid item xs={6}>
          <DriveChange>{change}</DriveChange>
        </Grid>
      </Grid>
    </ZfsDriveChangeContainer>
  );
}

function driveAgeDays(drive: IZfsDrive) {
  const ageInMs = new Date(drive.last_seen).getTime() - new Date(drive.first_seen).getTime();
  return Math.ceil(ageInMs / 1000 / 60 / 60 / 24);
}

const ZfsDriveChangeContainer = styled("div")({
  marginTop: "0.3em",
});

interface IZfsDriveStatusProps {
  state: ZfsDriveState;
}

const ZfsDriveStatus = styled("div")({
  color: (p: IZfsDriveStatusProps) => p.state === ZfsDriveState.Online ? Color.Green : Color.Red,
  fontSize: "22pt",
  fontWeight: 200,
});

const BayNumber = styled("div")({
  color: Color.Primary,
  fontSize: "32pt",
  fontWeight: 100,
  marginRight: "0.3em",
});

interface IErrorCountProps {
  count: number;
}

const ErrorCount = styled("div")({
  color: (p: IErrorCountProps) => p.count > 0 ? Color.Red : Color.Green,
  fontSize: "22pt",
  fontWeight: 200,
});

const SerialNumber = styled("div")({
  textTransform: "uppercase",
  fontSize: "16pt",
  fontWeight: 100,
});

const DriveTimeRange = styled("span")({
  color: Color.Gray,
  fontSize: "14pt",
  fontWeight: 200,
});

const DriveAgeValue = styled("span")({
  color: Color.Blue,
  fontSize: "14pt",
  fontWeight: 200
});

const DriveAgeLabel = styled("span")({
  color: Color.Gray,
  fontSize: "12pt",
  fontWeight: 200,
});

const DriveChange = styled("span")({
  color: Color.Yellow,
  fontSize: "14pt",
  fontWeight: 200,
});

interface IProps {
  zfsDrivesData?: IGAPIDataState<IZfsDrive>;
  requestZfsDrives: () => void;
  setBayNumber: (serial: string, bay: number) => void;
}

interface IState {
  dialogOpen: boolean;
  serial: string;
}

export default connect(
  (store: IStoreState) => {
    return {
      zfsDrivesData: store.tools.zfsDrivesData,
    };
  },
  (dispatch: Dispatch) => {
    return {
      requestZfsDrives: () => dispatch(newZfsDrivesRequest()),
      setBayNumber: (s: string, b: number) => dispatch(newSetZfsDriveBayRequest(s, b)),
    };
  },
)((props: IProps) => {
  const {requestZfsDrives, zfsDrivesData, setBayNumber} = props;
  const [state, setState] = useState<IState>({dialogOpen: false, serial: ""});
  const {dialogOpen, serial} = state;

  function openSetBayNumber(newSerial: string) {
    setState({...state, dialogOpen: true, serial: newSerial});
  }

  function handleDialogClose() {
    setState({...state, dialogOpen: false});
  }

  function handleSetBayNumber(bay: number) {
    setBayNumber(serial, bay);
    setState({...state, dialogOpen: false});
  }

  const driveBoxes: ReactNode[] = [];
  const oldDriveBoxes: ReactNode[] = [];
  let latestCheckTsMs = 0;
  const zfsDrives = useGData(zfsDrivesData);

  if (zfsDrives) {
    for (const drive of zfsDrives) {
      const tsMs = new Date(drive.last_seen).getTime();
      if (tsMs > latestCheckTsMs) {
        latestCheckTsMs = tsMs;
      }
    }

    const currentDrives = [];
    const pastDrives = [];

    for (const drive of zfsDrives) {
      const tsMs = new Date(drive.last_seen).getTime();

      // if the drive was seen in the last 10 minutes
      if (Math.abs(tsMs - latestCheckTsMs) < 10 * 60 * 1000) {
        currentDrives.push(drive);
      } else {
        pastDrives.push(drive);
      }
    }

    currentDrives.sort((a, b) => a.bay_number - b.bay_number);
    pastDrives.sort((a, b) => new Date(a.last_seen).getTime() - new Date(b.last_seen).getTime());

    currentDrives.map(drive =>
      driveBoxes.push(<ZfsDriveBox key={drive.serial} drive={drive} openSetBayNumber={openSetBayNumber}/>));
    pastDrives.map(drive =>
      oldDriveBoxes.push(<ZfsDriveBox key={drive.serial} drive={drive} openSetBayNumber={openSetBayNumber}/>));
  }

  return (
    <>
      <Header pageTitle={"ZFS"}/>
      <GAPIData request={requestZfsDrives} state={zfsDrivesData}>
        <GTitle title={"Current Drives"}/>
        {driveBoxes}
        <GTitle title={"Past Drives"}/>
        {oldDriveBoxes}
      </GAPIData>
        <Dialog onClose={handleDialogClose} open={dialogOpen}>
          <DialogTitle>Set Drive Bay Number</DialogTitle>
          <List>
            {[1, 2, 3, 4, 5, 6].map(bay => (
              <ListItem button onClick={() => handleSetBayNumber(bay)} key={bay}>
                <ListItemText primary={`Bay ${bay}`} />
              </ListItem>
            ))}
          </List>
        </Dialog>
    </>
  );
});
