import Icon, { IconProps } from "@material-ui/core/Icon/Icon";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { Color, textColorForBackgroundColor } from "./colors";

interface IGLabelProps {
  big?: boolean;
  extraLabel?: string;
  extraVariant?: Color;
  icon?: string;
  invert?: boolean;
  label?: string;
  title?: string;
  variant?: Color;
}

const defaultColor = Color.White;

const LabelContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: textColorForBackgroundColor(defaultColor),
  margin: "0.1em",
});

interface IStyledIconProps {
  big?: boolean;
  variant?: Color;
  invert?: boolean;
}

const StyledIcon = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ big, variant, invert, ...other }: IStyledIconProps & IconProps) => (
    <Icon {...other} />
  )
)({
  lineHeight: 1,
  marginLeft: "-1px",
  marginRight: "0.2em",
  fontSize: (props: IStyledIconProps) =>
    props.big ? "28pt !important" : "12pt !important",
  marginBottom: (props: IStyledIconProps) => (props.big ? "0.4em" : 0),
  color: (props: IStyledIconProps) =>
    props.big ? props.variant || defaultColor : "inherit",
});

interface IStyledLabelProps {
  extra?: boolean;
  invert?: boolean;
  variant?: Color;
  children?: React.ReactNode;
}

const StyledLabel = styled(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ extra, invert, variant, ...other }: IStyledLabelProps) => (
    <span {...other} />
  )
)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",

  borderRadius: ".25em",
  padding: ".2em .6em .3em",

  textTransform: "lowercase",
  fontSize: "12pt",
  fontWeight: 400,
  lineHeight: 1,
  whiteSpace: "nowrap",

  color: (props: IStyledLabelProps) =>
    props.invert ? props.variant : textColorForBackgroundColor(props.variant),
  backgroundColor: (props: IStyledLabelProps) =>
    props.invert ? textColorForBackgroundColor(props.variant) : props.variant,

  zIndex: (props: IStyledLabelProps) => (props.extra ? 1 : 2),
  marginLeft: (props: IStyledLabelProps) => (props.extra ? "-1em" : 0),
  paddingLeft: (props: IStyledLabelProps) => (props.extra ? "1.3em" : ""),
});

function bigIcon(big?: boolean, icon?: string, variant?: Color) {
  if (icon && big) {
    return (
      <StyledIcon big={true} variant={variant}>
        {icon}
      </StyledIcon>
    );
  }
}

function extraLabel(big?: boolean, exLabel?: string, extraVariant?: Color) {
  if (exLabel) {
    return (
      <StyledLabel variant={extraVariant} extra={true}>
        {exLabel}
      </StyledLabel>
    );
  }
}

const Row = styled("div")({
  display: "flex",
});

function GLabel(props: IGLabelProps) {
  const { big, icon, label, title, variant, invert } = props;

  return (
    <LabelContainer>
      {/* Render The Big Icon */}
      {bigIcon(big, icon, variant)}

      <Row title={title}>
        <StyledLabel variant={variant} invert={invert}>
          {/* Render The Normal Sized Icon */}
          {icon && !big && <StyledIcon variant={variant}>{icon}</StyledIcon>}

          {/* Render the actual label text */}
          {label}
        </StyledLabel>

        {extraLabel(big, props.extraLabel, props.extraVariant)}
      </Row>
    </LabelContainer>
  );
}

export default GLabel;
