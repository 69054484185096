import { useEffect } from "react";
import * as React from "react";
import GLoading from "./GLoading";
import GRequestError from "./GRequestError";

export interface IGAPIDataState<T> {
  maxAgeSeconds: number;
  data?: T[];
  error?: string;
  refreshed?: Date;
  requestInProgress: boolean;
}

/**
 * Create a fresh data state, uninitialized with data
 * @param maxAgeSeconds the maximum number of seconds data will be cached before
 *        being re-requested from the server
 */
export function createAPIDataState<T>(
  maxAgeSeconds: number
): IGAPIDataState<T> {
  return { maxAgeSeconds, requestInProgress: false };
}

/**
 * Handle state change for hydrating the cache
 * @param state
 * @param maxAgeSeconds
 */
export function handleRequestState<T>(
  state?: IGAPIDataState<T>,
  maxAgeSeconds: number = 30 * 60
): IGAPIDataState<T> {
  if (!state) {
    state = createAPIDataState(maxAgeSeconds);
  }
  return {
    ...state,
    requestInProgress: true,
    error: "",
    refreshed: new Date(),
  };
}

/**
 * Handle state change after receiving an error hydrating the cache
 * @param error
 * @param state
 * @param maxAgeSeconds
 */
export function handleRequestErrorState<T>(
  error: string,
  state?: IGAPIDataState<T>,
  maxAgeSeconds: number = 30 * 60
): IGAPIDataState<T> {
  if (!state) {
    state = createAPIDataState(maxAgeSeconds);
  }
  return {
    ...state,
    requestInProgress: false,
    error,
  };
}

/**
 * Handle state change after receiving successful response for data
 * @param data
 * @param state
 * @param maxAgeSeconds
 * @param refreshed
 */
export function handleRequestSuccessState<T>(
  data: T[],
  state?: IGAPIDataState<T>,
  maxAgeSeconds: number = 30 * 60,
  refreshed = true
): IGAPIDataState<T> {
  if (!state) {
    state = createAPIDataState(maxAgeSeconds);
  }

  return {
    ...state,
    requestInProgress: false,
    error: "",
    refreshed: refreshed ? new Date() : state.refreshed,
    data,
  };
}

/**
 * Handle state change after receiving successful response for updating a single
 * entry in the cache, replacing just that item in the cache.
 * @param replacement
 * @param selector
 * @param state
 * @param maxAgeSeconds
 */
export function handleItemReplacement<T>(
  replacement: T,
  selector: (d: T) => any,
  state?: IGAPIDataState<T>,
  maxAgeSeconds: number = 30 * 60
): IGAPIDataState<T> {
  if (!state) {
    state = createAPIDataState(maxAgeSeconds);
  }

  const data: T[] = [];
  const replacementId = selector(replacement);

  for (const d of state.data || []) {
    if (selector(d) === replacementId) {
      data.push(replacement);
    } else {
      data.push(d);
    }
  }

  return { ...state, data };
}

export function handleItemAddition<T>(
  newItem: T,
  state?: IGAPIDataState<T>,
  maxAgeSeconds: number = 30 * 60
): IGAPIDataState<T> {
  if (!state) {
    state = createAPIDataState(maxAgeSeconds);
  }

  const data: T[] = [...(state.data || [])];
  data.push(newItem);

  return { ...state, data };
}

export function handleStale<T>(
  state?: IGAPIDataState<T>,
  maxAgeSeconds: number = 30 * 60
) {
  if (!state) {
    state = createAPIDataState(maxAgeSeconds);
  }
  return {
    ...state,
    requestInProgress: false,
    error: "",
    refreshed: new Date(0),
  };
}

export enum APIDataState {
  Stale = "STALE",
  Error = "ERROR",
  InProgress = "IN_PROGRESS",
  Current = "CURRENT",
}

/**
 * Safely select empty data from a possibly undefined api data state
 * @param state
 * @param request
 */
export function useGData<T>(
  state?: IGAPIDataState<T>,
  request?: () => void
): T[] {
  useEffect(() => {
    if (request && getAPIDataState(state) === APIDataState.Stale) {
      request();
    }
  });
  if (state === undefined || state.data === undefined) {
    return [];
  }
  return state.data;
}

export function usePeriodically<T>(
  seconds: number,
  request: () => void,
  state?: IGAPIDataState<T>
) {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (getAPIDataState(state) != APIDataState.InProgress) {
        request();
      }
    }, seconds * 1000);
    return () => clearTimeout(timer);
  });
}

function isDataExpired(maxAgeSeconds: number, refreshed?: Date): boolean {
  return (
    !refreshed ||
    Date.now() - new Date(refreshed).getTime() > maxAgeSeconds * 1000
  );
}

export function isDataLoading<T>(state?: IGAPIDataState<T>) {
  const dataState = getAPIDataState(state);

  switch (dataState) {
    case APIDataState.Stale:
    case APIDataState.InProgress:
      return true;
  }

  return false;
}

interface IProps<T> {
  request: () => void;
  state?: IGAPIDataState<T>;
  children?: any;
}

export function getAPIDataState<T>(state?: IGAPIDataState<T>) {
  if (state === undefined) {
    return APIDataState.Stale;
  }

  const { error, requestInProgress, refreshed, maxAgeSeconds } = state;

  if (error) {
    return APIDataState.Error;
  }

  if (requestInProgress) {
    if (isDataExpired(60 * 5, refreshed)) {
      // guard against infinite in progress
      return APIDataState.Stale;
    }
    return APIDataState.InProgress;
  }

  if (isDataExpired(maxAgeSeconds, refreshed)) {
    return APIDataState.Stale;
  }

  return APIDataState.Current;
}

export function checkStaleData<T>(
  request: () => void,
  state?: IGAPIDataState<T>
) {
  const dataState = getAPIDataState(state);

  switch (dataState) {
    case APIDataState.Stale:
      request();
      break;
  }
}

export function getDataComponent<T>(
  request: () => void,
  state?: IGAPIDataState<T>
) {
  const dataState = getAPIDataState(state);

  switch (dataState) {
    case APIDataState.Stale:
    case APIDataState.InProgress:
      return <GLoading />;
    case APIDataState.Error:
      if (state && state.error) {
        return <GRequestError message={state.error} retry={request} />;
      }
  }

  return null;
}

export default function GAPIData<T>(props: IProps<T>) {
  const { children, request, state } = props;
  const dataState = getAPIDataState(state);

  switch (dataState) {
    case APIDataState.Stale:
      request();
      return <GLoading />;
    case APIDataState.InProgress:
      return <GLoading />;
    case APIDataState.Error:
      if (state && state.error) {
        return <GRequestError message={state.error} retry={request} />;
      }
  }

  return children;
}
