import styled from "@material-ui/core/styles/styled";
import { ReactNode } from "react";
import { TooltipPayload } from "recharts";
import { Color } from "./colors";

export interface ITooltipProps {
  payload: ReadonlyArray<TooltipPayload>;
  label: any;
  active: any;
}

export const TooltipWrapper = styled("div")({
  backgroundColor: Color.Background,
  border: `1px solid ${Color.Primary}`,
  padding: "1em",
});

export interface ITooltipLabelProps {
  color?: string;
  children?: ReactNode;
}

export const TooltipLabel = styled("span")({
  color: (props: ITooltipLabelProps) => props.color,
  marginRight: "0.3em",
});
