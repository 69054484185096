import {Grid} from "@material-ui/core";
import * as React from "react";
import styled from "@material-ui/core/styles/styled";
import GCard, {GCardSize} from "../../lib/GCard";
import GTitle from "../../lib/GTitle";
import {Color} from "../../lib/colors";
import {IWorkExperience} from "../ResumeDataService";
import Proficiency from "./Proficiency";

//
// Company Logo
//
const WorkLogo = styled("img")({
  maxWidth: "230px",
  maxHeight: "40px",
});

interface IWorkLogoCardProps {
  name: string;
  imagePath: string;
}

const WorkLogoRow = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

function WorkLogoCard(props: IWorkLogoCardProps) {
  return (
    <GCard variant={Color.White} size={GCardSize.LARGE}>
      <WorkLogoRow>
        <WorkLogo src={props.imagePath} alt={props.name}/>
      </WorkLogoRow>
    </GCard>
  );
}

//
// Title and Dates
//
const JobTitle = styled("div")({
  marginTop: "0.6em",
  marginLeft: "0.4em",
  fontSize: "20pt",
});

const JobTenure = styled("div")({
  marginLeft: "1em",
  fontStyle: "italic",
  fontSize: "10pt",
});

//
// Proficiencies
//
interface IProficiencies {
  techs: string[];
}

const ProficienciesContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  flexWrap: "wrap",
});

function Proficiencies(props: IProficiencies) {
  const profs: JSX.Element[] = [];

  props.techs.forEach((e) => profs.push(<Proficiency key={e} name={e} small/>));

  return <ProficienciesContainer>{profs}</ProficienciesContainer>;
}

//
// Bullets
//
const BulletList = styled("ul")({
  fontSize: "12pt",
  margin: 0,

  "& li": {
    marginBottom: "0.5em",
  }
});

interface IBullets {
  bullets: string[];
}

function Bullets(props: IBullets): JSX.Element {
  const {bullets} = props;
  const displayedBullets = [];

  for (const bullet of bullets) {
    displayedBullets.push(<li key={bullet}>{bullet}</li>);
  }

  return <BulletList>{displayedBullets}</BulletList>;
}

//
// Main
//

interface IWorkExperienceProps {
  experience: IWorkExperience;
}

const Container = styled("div")({
  padding: "1em",
  marginBottom: "2em",
});

export default function WorkExperience(props: IWorkExperienceProps) {
  const {name, title, image, start, end, bullets, tech} = props.experience;

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={4}>
          {image ? (
            <WorkLogoCard imagePath={image} name={name}/>
          ) : (
            <GTitle title={name} sub/>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <JobTitle>{title}</JobTitle>
          <JobTenure>{start} - {end}</JobTenure>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Proficiencies techs={tech}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Bullets bullets={bullets}/>
        </Grid>
      </Grid>
    </Container>
  );
}
