/**
 * Request
 */
import {IAPIError} from "../../lib/GRequest";
import {IZfsDrive} from "../ToolsApi";

export const SET_ZFS_DRIVE_BAY_REQUEST = "SET_ZFS_DRIVE_BAY_REQUEST";
export type SET_ZFS_DRIVE_BAY_REQUEST = typeof SET_ZFS_DRIVE_BAY_REQUEST;

export interface ISetZfsDriveBayRequest {
  bayNumber: number;
  serial: string;
  type: SET_ZFS_DRIVE_BAY_REQUEST;
}

export function newSetZfsDriveBayRequest(serial: string, bayNumber: number): ISetZfsDriveBayRequest {
  return {
    bayNumber, serial,
    type: SET_ZFS_DRIVE_BAY_REQUEST,
  };
}

/**
 * Error
 */
export const SET_ZFS_DRIVE_BAY_REQUEST_ERROR = "SET_ZFS_DRIVE_BAY_REQUEST_ERROR";
export type SET_ZFS_DRIVE_BAY_REQUEST_ERROR = typeof SET_ZFS_DRIVE_BAY_REQUEST_ERROR;

export interface ISetZfsDriveBayRequestError {
  error: string;
  type: SET_ZFS_DRIVE_BAY_REQUEST_ERROR;
}

export function newSetZfsDriveBayRequestError(error: IAPIError): ISetZfsDriveBayRequestError {
  return {
    error: error.message,
    type: SET_ZFS_DRIVE_BAY_REQUEST_ERROR,
  };
}

/**
 * Success
 */
export const SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS = "SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS";
export type SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS = typeof SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS;

export interface ISetZfsDriveBayRequestSuccess {
  drive: IZfsDrive;
  type: SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS;
}

export function newSetZfsDriveBayRequestSuccess(response: IZfsDrive): ISetZfsDriveBayRequestSuccess {
  return {
    drive: response,
    type: SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS,
  };
}
