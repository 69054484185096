import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import GCard from "../../lib/GCard";
import {Color} from "../../lib/colors";
import {IInstruction} from "../RecipeApi1";
import Ingredient from "./Ingredient";
import RecipeTime from "./RecipeTime";

interface IProps {
  instruction: IInstruction;
}

function instructionIngredients(instruction: IInstruction) {
  const ingredients = [];

  for (const index of instruction.ingredients.keys()) {
    const ingredient = instruction.ingredients[index];
    ingredients.push(<Ingredient key={index} ingredient={ingredient}/>);
  }

  return ingredients;
}

function instructionTime(instruction: IInstruction) {
  if (!instruction.minutes) {
    return;
  }

  return <RecipeTime minutes={instruction.minutes}/>;
}

const InstructionContainer = styled(GCard)({
  padding: "0.8em",
  marginBottom: "2em",
});

const Step = styled("div")({
  color: Color.Primary,
  fontWeight: 200,
  fontSize: "32pt",
});

const Header = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  paddingRight: "2em",
  justifyContent: "space-between",
});

const InstructionText = styled("div")({
  fontWeight: 200,
  fontSize: "16pt",
});

export default function Instruction(props: IProps) {
  const {instruction} = props;
  const hasIngredients = instruction.ingredients.length > 0;

  const textWidth = hasIngredients ? 8 : 12;

  return (
    <InstructionContainer>
      <Header>
        <Step>#{instruction.step}</Step>
        {instructionTime(instruction)}
      </Header>
      <Grid container alignItems={"center"} alignContent={"center"} justify={"center"} spacing={2}>
        <Grid item sm={12} md={textWidth}>
          <InstructionText>
            {instruction.text}
          </InstructionText>
        </Grid>
        {hasIngredients && (
          <Grid item xs={11} md={4}>
            {instructionIngredients(instruction)}
          </Grid>
        )}
      </Grid>
    </InstructionContainer>
  );
}
