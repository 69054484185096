import { Fab } from "@material-ui/core";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { ReactElement } from "react";

interface IFabContainerProps {
  offset?: number;
}

const FabContainer = styled("div")({
  position: "fixed",
  right: "1em",
  bottom: (props: IFabContainerProps) =>
    props.offset ? `${props.offset}em` : "1em",
  zIndex: 100,
});

interface IProps {
  icon: ReactElement;
  onClick?: (e: any) => void;
  offset?: number;
}

export default function (props: IProps) {
  const { icon, onClick, offset } = props;

  return (
    <FabContainer offset={offset}>
      <Fab color={"primary"} onClick={onClick}>
        {icon}
      </Fab>
    </FabContainer>
  );
}
