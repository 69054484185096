import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import * as React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Cell, Pie, PieChart, Tooltip } from "recharts";
import { Dispatch } from "redux";
import Header from "main/Header";
import { IStoreState } from "store";
import { Color } from "../../lib/colors";
import { IGAPIDataState, useGData, usePeriodically } from "../../lib/GAPIData";
import GLabel from "../../lib/GLabel";
import GTitle from "../../lib/GTitle";
import { ITooltipProps, TooltipWrapper } from "../../lib/recharts";
import AccountBalanceChart from "../components/AccountBalanceChart";
import AccountSummary from "../components/AccountSummary";
import { currFormat, placeRound, shortDate } from "../components/common";
import DeltaChart from "../components/DeltaChart";
import { IStockDividendDetails } from "../components/DividendBox";
import RealizedProfitChart from "../components/RealizedProfitChart";
import RefreshFooter from "../components/RefreshFooter";
import { IStockData } from "../StocksApi";
import { stockDataRequest } from "../reducer";
import { STOCK_DATA_MAX_AGE_SECONDS } from "../store";

const COLOR_ORDER = [
  Color.Purple,
  Color.Red,
  Color.Gray,
  Color.Green,
  Color.Yellow,
  Color.Blue,
  Color.Orange,
  Color.White,
];

const CustomTooltip = (props: ITooltipProps) => {
  const { active, payload } = props;
  if (active && payload) {
    const firstPayload = payload[0];

    return (
      <TooltipWrapper>
        {firstPayload.name}: {firstPayload.value}%
      </TooltipWrapper>
    );
  } else {
    return null;
  }
};

interface IStoreProps {
  stockData?: IGAPIDataState<IStockData>;
}

interface IDispatchProps {
  requestStockData: () => void;
}

export default connect(
  (store: IStoreState): IStoreProps => {
    return {
      stockData: store.stocks.stockData,
    };
  },
  (dispatch: Dispatch): IDispatchProps => {
    return {
      requestStockData: () => dispatch(stockDataRequest()),
    };
  }
)((props: IStoreProps & IDispatchProps) => {
  const { stockData, requestStockData } = props;
  const history = useHistory();

  const sd = useGData(stockData, requestStockData)[0];
  usePeriodically(STOCK_DATA_MAX_AGE_SECONDS, requestStockData, stockData);

  const activity: any = [];
  const dividendByTsbySymbol: Map<
    string,
    Map<number, IStockDividendDetails>
  > = new Map();
  const data: any[] = [];
  let otherTotal = 0;
  let totalSoFar = 0;
  const stockAccountValue = new Map<string, number>();

  sd.stocks.forEach((sq) => {
    sq.buys.forEach((sb) => {
      if (!sb.sale_date) {
        // agg data for pie chart
        stockAccountValue.set(
          sb.symbol,
          (stockAccountValue.get(sb.symbol) || 0) + sb.total_current
        );
      }

      //
      // STORE ACTIVITY
      //

      // purchase
      if (sb.purchase_date) {
        activity.push({
          date: shortDate(sb.purchase_date),
          symbol: sb.symbol,
          amount: currFormat.format(sb.total_cost),
          type: <GLabel label={"purchase"} variant={Color.Purple} />,
          desc: `${sb.quantity} shares purchased.`,
        });
      }
      // dividends
      sb.dividends?.forEach((d) => {
        let dividendByTs = dividendByTsbySymbol.get(sb.symbol);
        if (!dividendByTs) {
          dividendByTs = new Map();
          dividendByTsbySymbol.set(sb.symbol, dividendByTs);
        }

        const p = dividendByTs.get(d.date);
        if (p) {
          dividendByTs.set(d.date, {
            ...p,
            quantity: sb.quantity + p.quantity,
          });
        } else {
          dividendByTs.set(d.date, { ...d, quantity: sb.quantity });
        }
      });
      // sale
      if (sb.sale_date) {
        activity.push({
          date: shortDate(sb.sale_date),
          symbol: sb.symbol,
          amount: currFormat.format(sb.total_sale),
          type: <GLabel label={"sale"} variant={Color.Orange} />,
          desc: `${sb.quantity} shares sold.`,
        });
      }
    });
  });

  dividendByTsbySymbol.forEach((dividendByTs, symbol) =>
    dividendByTs.forEach((d) =>
      activity.push({
        date: shortDate(d.date),
        symbol: symbol,
        amount: currFormat.format(d.value * d.quantity),
        type: <GLabel label={"dividend"} variant={Color.Blue} />,
        desc: `${currFormat.format(d.value)} per share dividend.`,
      })
    )
  );

  for (const [symbol, value] of stockAccountValue.entries()) {
    const stockPct = placeRound(value, sd.total_current);
    if (stockPct < 5 || totalSoFar > 50) {
      otherTotal += value;
    } else {
      totalSoFar += stockPct;
      data.push({ name: symbol, value: stockPct });
    }
  }
  data.sort((a, b) => b.value - a.value);
  if (otherTotal > 0) {
    data.push({
      name: "Other",
      value: placeRound(otherTotal, sd.total_current),
      color: Color.Background,
    });
  }

  return (
    <>
      <Header pageTitle={"Account"} backRoute={"/stocks"} />
      <AccountSummary onAccount stockData={sd} />
      <Grid container>
        <Grid item xs={4}>
          <PieChart width={330} height={200}>
            <Pie
              data={data}
              innerRadius={35}
              outerRadius={60}
              dataKey="value"
              startAngle={150}
              endAngle={360 + 150}
              animationDuration={0}
              label={({ percent, name }) =>
                `${name}: ${(100 * (percent || 0)).toFixed(1)}%`
              }
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.color || COLOR_ORDER[index % COLOR_ORDER.length]}
                  strokeWidth={1}
                  strokeOpacity={0.5}
                />
              ))}
            </Pie>
            <Tooltip content={CustomTooltip} />
          </PieChart>
        </Grid>
        <Grid item xs={8}>
          <DeltaChart
            maxMillisecondsOld={60 * 24 * 60 * 60 * 1000}
            width={650}
            height={200}
            s={sd}
          />
        </Grid>
      </Grid>
      <AccountBalanceChart sd={sd} />
      <RealizedProfitChart sd={sd} />
      <GTitle title={"Activity"} sub />
      <MaterialTable
        data={activity}
        style={{
          backgroundColor: Color.Background,
          boxShadow: "0px 0px 0px 0px",
          marginBottom: "2em",
        }}
        columns={[
          {
            title: "Date",
            field: "date",
            customSort: (a, b) =>
              new Date(a.date).getTime() - new Date(b.date).getTime(),
            defaultSort: "desc",
            filtering: false,
          },
          { title: "Symbol", field: "symbol", align: "left" },
          {
            title: "Amount",
            field: "amount",
            align: "right",
            filtering: false,
          },
          { title: "Description", field: "desc" },
          { title: "Type", field: "type", align: "center", filtering: false },
        ]}
        options={{
          search: false,
          showTitle: false,
          toolbar: false,
          filtering: true,
          pageSize: 25,
          headerStyle: {
            color: Color.Primary,
            backgroundColor: Color.Background,
          },
        }}
        onRowClick={(event, rowData) =>
          rowData && history.push(`/stocks/${rowData.symbol}?back=account`)
        }
      />
      <RefreshFooter stockData={sd} />
    </>
  );
});
