import {Grid} from "@material-ui/core";
import {default as React} from "react";
import {contains} from "lib/string";
import RecipeBox from "./RecipeBox";
import {DietaryFlag, IRecipe, MealType, sortRecipes} from "../RecipeApi1";

interface IRecipesListProps {
  recipes: IRecipe[];
  searchFilter?: string;
  dietaryFlagsSelected: Set<DietaryFlag>;
  mealTypesSelected: Set<MealType>;
}

function recipeHasFilterFlags<T>(selectedFlags: Set<T>, recipeFlags?: T[]) {
  if (selectedFlags.size === 0) {
    // nothing is selected, everything is good
    return true;
  }

  if (!recipeFlags) {
    // something is selected, this recipe has nothing, pass
    return false;
  }

  const recipeFlagSet = new Set(recipeFlags);
  for (const flag of selectedFlags) {
    if (!recipeFlagSet.has(flag)) {
      // a selected flag is missing from the recipe, pass
      return false;
    }
  }

  // we've made it this far, it's gucci
  return true;
}

function getRecipes(props: IRecipesListProps) {
  const {recipes, searchFilter, dietaryFlagsSelected, mealTypesSelected} = props;
  let r: IRecipe[] = [];
  const f: JSX.Element[] = [];

  for (const recipe of recipes) {
    if (!recipeHasFilterFlags(dietaryFlagsSelected, recipe.dietary_flags)) {
      continue;
    }

    if (!recipeHasFilterFlags(mealTypesSelected, recipe.meal_types)) {
      continue;
    }

    if (searchFilter && !contains(searchFilter, recipe.name)) {
      continue;
    }
    r.push(recipe);
  }

  r = sortRecipes(r);

  for (const recipe of r) {
    f.push((
      <Grid item xs={12} sm={6} key={recipe.id}>
        <RecipeBox key={recipe.id} recipe={recipe} noIngredients narrow/>
      </Grid>
    ));
  }

  return f;
}

function RecipesList(props: IRecipesListProps) {
  return <Grid container>{getRecipes(props)}</Grid>;
}

export default RecipesList;
