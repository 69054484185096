import Fraction from "fraction.js/fraction";
import * as React from "react";
import styled from "@material-ui/core/styles/styled";
import { normalizeQuantityAmount } from "../recipes/RecipeApi1";

interface IProps {
  value: string;
}

const NumberContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const Whole = styled("div")({
  fontSize: "16pt",
  marginRight: "0.3em",
});

const FractionContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const Numerator = styled("sup")({
  fontSize: "12pt",
  marginBottom: "-1.2em",
  marginLeft: "-0.4em",
});

const Slash = styled("div")({
  fontSize: "16pt",
});

const Denominator = styled("sub")({
  fontSize: "12pt",
  marginTop: "-1.0em",
  marginLeft: "0.4em",
});

export default function GFraction(props: IProps) {
  const value = normalizeQuantityAmount(props.value);

  const whole = Math.floor(value);
  const fraction = new Fraction(value).sub(whole);
  const numerator = fraction.n;
  const denominator = fraction.d;

  return (
    <NumberContainer>
      <Whole>{whole > 0 && whole}</Whole>
      {numerator > 0 && (
        <FractionContainer>
          <Numerator>{numerator}</Numerator>
          <Slash>/</Slash>
          <Denominator>{denominator}</Denominator>
        </FractionContainer>
      )}
    </NumberContainer>
  );
}
