/**
 * Request
 */
import {IAPIError} from "../../lib/GRequest";
import {IZfsDrive, IZfsDrivesResponse} from "../ToolsApi";

export const ZFS_DRIVES_REQUEST = "ZFS_DRIVES_REQUEST";
export type ZFS_DRIVES_REQUEST = typeof ZFS_DRIVES_REQUEST;

export interface IZfsDrivesRequest {
  type: ZFS_DRIVES_REQUEST;
}

export function newZfsDrivesRequest(): IZfsDrivesRequest {
  return {
    type: ZFS_DRIVES_REQUEST,
  };
}

/**
 * Error
 */
export const ZFS_DRIVES_REQUEST_ERROR = "ZFS_DRIVES_REQUEST_ERROR";
export type ZFS_DRIVES_REQUEST_ERROR = typeof ZFS_DRIVES_REQUEST_ERROR;

export interface IZfsDrivesRequestError {
  error: string;
  type: ZFS_DRIVES_REQUEST_ERROR;
}

export function newZfsDrivesRequestError(error: IAPIError): IZfsDrivesRequestError {
  return {
    error: error.message,
    type: ZFS_DRIVES_REQUEST_ERROR,
  };
}

/**
 * Success
 */
export const ZFS_DRIVES_REQUEST_SUCCESS = "ZFS_DRIVES_REQUEST_SUCCESS";
export type ZFS_DRIVES_REQUEST_SUCCESS = typeof ZFS_DRIVES_REQUEST_SUCCESS;

export interface IZfsDrivesRequestSuccess {
  drives: IZfsDrive[];
  type: ZFS_DRIVES_REQUEST_SUCCESS;
}

export function newZfsDrivesRequestSuccess(response: IZfsDrivesResponse): IZfsDrivesRequestSuccess {
  return {
    drives: response.drives,
    type: ZFS_DRIVES_REQUEST_SUCCESS,
  };
}
