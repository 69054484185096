import {Button, Grid} from "@material-ui/core";
import * as React from "react";
import styled from "@material-ui/core/styles/styled";
import GCard, {GCardSize} from "../../lib/GCard";
import {IWorkExperience} from "../ResumeDataService";
import Proficiency from "./Proficiency";

//
// Proficiencies
//
interface IProficiencies {
  techs: string[];
}

const ProficienciesContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-around",
  flexWrap: "wrap",
  marginTop: "-1.5em",
});

function Proficiencies(props: IProficiencies) {
  const profs: JSX.Element[] = [];

  props.techs.forEach((e) => profs.push(<Proficiency key={e} name={e} small/>));

  return <ProficienciesContainer>{profs}</ProficienciesContainer>;
}

//
// Bullets
//
const BulletList = styled("ul")({
  fontSize: "12pt",
  margin: 0,
  marginTop: "1em",

  li: {
    marginBottom: "0.5em",
  },
});

interface IBullets {
  bullets: string[];
}

function Bullets(props: IBullets): JSX.Element {
  const {bullets} = props;
  const displayedBullets = [];

  for (const bullet of bullets) {
    displayedBullets.push(<li key={bullet}>{bullet}</li>);
  }

  return <BulletList>{displayedBullets}</BulletList>;
}

//
// Main
//

interface IPersonalProjectProps {
  experience: IWorkExperience;
}

export default function PersonalProject(props: IPersonalProjectProps) {
  const {name, bullets, tech, github} = props.experience;

  return (
    <GCard title={name} size={GCardSize.SMALL} actions={github ? <Button href={github}>GitHub</Button> : undefined}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={12}>
          <Proficiencies techs={tech}/>
        </Grid>
        <Grid item sm={12} md={12}>
          <Bullets bullets={bullets}/>
        </Grid>
      </Grid>
    </GCard>
  );
}
