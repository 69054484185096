export enum Color {
  White = "#FCFCFA",
  Gray = "#939293",
  Dark = "#373737",
  Background = "#2D2A2E",
  Black = "#080808",

  Blue = "#78DCE8",
  Green = "#A9DC76",
  Orange = "#FC9867",
  Purple = "#AB9DF2",
  Red = "#FF6188",
  Yellow = "#FFD866",

  Primary = Purple,
  Accent = Yellow,
  Secondary = Green,

  Good = Green,
  Ok = Green,

  Warning = Yellow,
  Warn = Yellow,

  Alert = Red,
}

const whiteTextColors = [Color.Black, Color.Dark, Color.Background];

export function textColorForBackgroundColor(color?: Color) {
  if (!color || whiteTextColors.indexOf(color) !== -1) {
    return Color.White;
  }

  return Color.Background;
}
