import styled from "@material-ui/core/styles/styled";
import { AttachMoney } from "@material-ui/icons";
import * as React from "react";
import { Color } from "../../lib/colors";
import { currFormat, shortDate } from "./common";

const Container = styled("div")({
  borderColor: Color.Blue,
  borderStyle: "solid",
  borderWidth: "2px",
  borderRadius: "3px",
  margin: "0.3em",
  marginBottom: "1.2em",
  padding: "0.3em",
  display: "flex",
  "& svg": {
    color: Color.Blue,
    height: "40px",
    width: "40px",
  },
});

const DateWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  fontFamily: "monospace",
});

const RestWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexGrow: 1,
});

const TotalWrapper = styled(DateWrapper)({
  color: Color.Blue,
  fontSize: "14pt",
  marginRight: "0.3em",
});

export interface IStockDividendDetails {
  quantity: number;
  date: number;
  value: number;
}

interface IProps {
  dividend: IStockDividendDetails;
}

export default (props: IProps) => {
  const { dividend } = props;

  return (
    <Container>
      <AttachMoney />
      <DateWrapper>{shortDate(dividend.date + 6 * 60 * 60)}</DateWrapper>
      <RestWrapper>
        {dividend.quantity}s @ {currFormat.format(dividend.value)}
      </RestWrapper>
      <TotalWrapper>
        {currFormat.format(dividend.value * dividend.quantity)}
      </TotalWrapper>
    </Container>
  );
};
