import Typography from "@material-ui/core/Typography";
import * as React from "react";
import styled from "@material-ui/core/styles/styled";
import { Color } from "./colors";

const MAIN = "h3";
const SUB = "h5";

interface IProps {
  title: string;
  sub?: boolean;
}

interface ITitleProps {
  variant: string;
}

const Title = styled(Typography)({
  color: Color.White,
  marginTop: "1.5em",
  fontWeight: 100,
  paddingLeft: `${(props: ITitleProps) =>
    props.variant === MAIN ? 0.3 : 0.5}em`,
  marginBottom: "0.4em",
});

function GTitle(props: IProps) {
  return <Title variant={props.sub ? SUB : MAIN}>{props.title}</Title>;
}

export default GTitle;
