const URL_ROOT = "/api/";

export interface IAPIError {
  message: string;
  status: number;
  error: boolean;
}

class GRequest {
  private readonly url: string;
  private apiMethod = "GET";
  private apiToken = "";
  private apiVersion = 1;
  private requestBody: any = {};

  /**
   * Set the url path after the api version
   * @param url
   * @param method
   * @param token
   */
  constructor(url: string, method?: string, token?: string) {
    this.url = url;
    if (method !== undefined) {
      this.method(method);
    }
    if (token !== undefined) {
      this.token(token);
    }
    return this;
  }

  /**
   * Set the HTTP method to use
   * @param method
   */
  public method(method: string) {
    this.apiMethod = method;
    return this;
  }

  /**
   * Set the authentication token to use for the request
   * @param token
   */
  public token(token: string) {
    this.apiToken = token;
    return this;
  }

  /**
   * Set the body object of the request, will be stringified
   * @param body
   */
  public body(body: any) {
    this.requestBody = body;
    return this;
  }

  /**
   * Set the API version, defaults to 1
   * @param version
   */
  public version(version: number) {
    this.apiVersion = version;
    return this;
  }

  /**
   * Register an error handling function to call
   * @param onError
   */
  public onError(onError: (response: any) => IAPIError) {
    this.onErrorHandler = onError;
    return this;
  }

  /**
   * Make a request to the backend server
   */
  public fetch() {
    const apiUrl = `${URL_ROOT}${this.apiVersion}/${this.url}`;

    return fetch(apiUrl, this.setupFetch()).then((response) => {
      if (!response.ok) {
        return this.onErrorHandler(response);
      } else {
        return response.json();
      }
    });
  }

  private setupFetch() {
    const headers = this.getHeaders();
    const method = this.apiMethod;

    if (method === "GET") {
      return { headers, method };
    } else {
      return {
        body: this.getBody(),
        headers,
        method,
      };
    }
  }

  private onErrorHandler: (response: any) => IAPIError = (response) => {
    let message = "Unknown Error Occurred";

    switch (response.status) {
      case 400:
        message = "Bad Request";
        break;
      case 401:
        message = "Incorrect username or password";
        break;
      default:
        // eslint-disable-next-line
        console.log(response);
      // eslint-disable-next-line
    }

    return { status: response.status, message, error: true };
  };

  private getBody() {
    return JSON.stringify(this.requestBody);
  }

  private getHeaders() {
    return {
      "Content-Type": "application/json",
      token: this.apiToken,
    };
  }
}

export default GRequest;
