import { IGAPIDataState } from "lib/GAPIData";
import { IPackageVersion, ISpeedTest, IUsedIp, IZfsDrive } from "./ToolsApi";

export const ZFS_DRIVE_MAX_AGE_SECONDS = 5 * 30;

export interface IToolsStore {
  ipAddressData?: IGAPIDataState<IUsedIp>;
  packagesData?: IGAPIDataState<IPackageVersion>;
  speedTestData?: IGAPIDataState<ISpeedTest>;
  zfsDrivesData?: IGAPIDataState<IZfsDrive>;
}
