import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import Emphasis from "./Emphasis";

interface IProps {
  title: string;
  icon: JSX.Element;
  value: any;
  suffix?: string;
}

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

export default function RecipeAttr(props: IProps) {
  const {title, icon, value, suffix} = props;

  return (
    <Container title={title}>
      {icon} &nbsp; <Emphasis text={value}/>&nbsp;{suffix}
    </Container>
  );
}
