import {
  handleItemReplacement,
  handleRequestErrorState,
  handleRequestState,
  handleRequestSuccessState,
} from "../lib/GAPIData";
import {
  IIpAddressesRequest,
  IIpAddressesRequestError,
  IIpAddressesRequestSuccess,
  IP_ADDRESSES_REQUEST,
  IP_ADDRESSES_REQUEST_ERROR,
  IP_ADDRESSES_REQUEST_SUCCESS,
} from "./actions/ipAddressesRequest";
import {
  IPackageVersionsRequest,
  IPackageVersionsRequestError,
  IPackageVersionsRequestSuccess,
  PACKAGE_VERSIONS_REQUEST,
  PACKAGE_VERSIONS_REQUEST_ERROR,
  PACKAGE_VERSIONS_REQUEST_SUCCESS,
} from "./actions/packageVersionsRequest";
import {
  ISetZfsDriveBayRequestSuccess,
  SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS,
} from "./actions/setZfsDriveBayRequest";
import {
  ISpeedTestsRequest,
  ISpeedTestsRequestError,
  ISpeedTestsRequestSuccess,
  SPEED_TESTS_REQUEST,
  SPEED_TESTS_REQUEST_ERROR,
  SPEED_TESTS_REQUEST_SUCCESS,
} from "./actions/speedTestsRequest";
import {
  IZfsDrivesRequest,
  IZfsDrivesRequestError,
  IZfsDrivesRequestSuccess,
  ZFS_DRIVES_REQUEST,
  ZFS_DRIVES_REQUEST_ERROR,
  ZFS_DRIVES_REQUEST_SUCCESS,
} from "./actions/zfsDrivesRequest";
import { IToolsStore, ZFS_DRIVE_MAX_AGE_SECONDS } from "./store";

type ToolAction =
  | IPackageVersionsRequest
  | IPackageVersionsRequestError
  | IPackageVersionsRequestSuccess
  | IIpAddressesRequest
  | IIpAddressesRequestError
  | IIpAddressesRequestSuccess
  | ISpeedTestsRequest
  | ISpeedTestsRequestError
  | ISpeedTestsRequestSuccess
  | IZfsDrivesRequest
  | IZfsDrivesRequestError
  | IZfsDrivesRequestSuccess
  | ISetZfsDriveBayRequestSuccess;

export default function reducer(
  state: IToolsStore = {},
  action: ToolAction
): IToolsStore {
  switch (action.type) {
    /**
     * IP Address History
     */
    case IP_ADDRESSES_REQUEST:
      return {
        ...state,
        ipAddressData: handleRequestState(state.ipAddressData),
      };
    case IP_ADDRESSES_REQUEST_ERROR:
      return {
        ...state,
        ipAddressData: handleRequestErrorState(
          action.error,
          state.ipAddressData
        ),
      };
    case IP_ADDRESSES_REQUEST_SUCCESS:
      return {
        ...state,
        ipAddressData: handleRequestSuccessState(
          action.ips,
          state.ipAddressData
        ),
      };
    /**
     * Package Versions
     */
    case PACKAGE_VERSIONS_REQUEST:
      return {
        ...state,
        packagesData: handleRequestState(state.packagesData),
      };
    case PACKAGE_VERSIONS_REQUEST_ERROR:
      return {
        ...state,
        packagesData: handleRequestErrorState(action.error, state.packagesData),
      };
    case PACKAGE_VERSIONS_REQUEST_SUCCESS:
      return {
        ...state,
        packagesData: handleRequestSuccessState(
          action.packages,
          state.packagesData
        ),
      };
    /**
     * Speed Tests
     */
    case SPEED_TESTS_REQUEST:
      return {
        ...state,
        speedTestData: handleRequestState(state.speedTestData),
      };
    case SPEED_TESTS_REQUEST_ERROR:
      return {
        ...state,
        speedTestData: handleRequestErrorState(
          action.error,
          state.speedTestData
        ),
      };
    case SPEED_TESTS_REQUEST_SUCCESS:
      return {
        ...state,
        speedTestData: handleRequestSuccessState(
          action.speedtests,
          state.speedTestData
        ),
      };
    /**
     * ZFS Drives
     */
    case ZFS_DRIVES_REQUEST:
      return {
        ...state,
        zfsDrivesData: handleRequestState(
          state.zfsDrivesData,
          ZFS_DRIVE_MAX_AGE_SECONDS
        ),
      };
    case ZFS_DRIVES_REQUEST_ERROR:
      return {
        ...state,
        zfsDrivesData: handleRequestErrorState(
          action.error,
          state.zfsDrivesData,
          ZFS_DRIVE_MAX_AGE_SECONDS
        ),
      };
    case ZFS_DRIVES_REQUEST_SUCCESS:
      return {
        ...state,
        zfsDrivesData: handleRequestSuccessState(
          action.drives,
          state.zfsDrivesData,
          ZFS_DRIVE_MAX_AGE_SECONDS
        ),
      };
    case SET_ZFS_DRIVE_BAY_REQUEST_SUCCESS:
      return {
        ...state,
        zfsDrivesData: handleItemReplacement(
          action.drive,
          (d) => d.serial,
          state.zfsDrivesData,
          ZFS_DRIVE_MAX_AGE_SECONDS
        ),
      };
  }

  return state;
}
