export function contains(
  needle: string,
  haystack: string,
  caseSensitive = false
): boolean {
  const h: string = caseSensitive ? haystack : haystack.toLowerCase();
  const n: string = caseSensitive ? needle : needle.toLowerCase();
  return h.indexOf(n) >= 0;
}

export function localDateString(t: any, includeTime = true): string {
  const time = new Date(t);
  let r = time.toDateString();

  if (includeTime) {
    const timeString = time.toTimeString().split(" ")[0];
    r += ` ${timeString}`;
  }

  return r;
}
