import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import Header from "main/Header";
import { ISellStock } from "../StocksApi";
import { stockSaleRequest } from "../reducer";

const FieldContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  "& > *": {
    margin: "1em",
  },
});

const getDefaultDate = () => {
  const curr = new Date();
  return curr.toISOString().substr(0, 10);
};

interface IProps {
  requestSellStock: (s: ISellStock) => void;
}

interface IState {
  sellStockSymbol?: string;
  sellStockQuantity?: string;
  sellStockCents?: string;
  sellStockDate: string;
}

const FRESH_STATE = {
  sellStockDate: getDefaultDate(),
};

export default connect(
  () => {
    return {};
  },
  (dispatch: Dispatch) => {
    return {
      requestSellStock: (s: ISellStock) => dispatch(stockSaleRequest(s)),
    };
  }
)((props: IProps) => {
  const [state, setState] = useState<IState>(FRESH_STATE);
  const history = useHistory();
  const {
    sellStockSymbol,
    sellStockQuantity,
    sellStockCents,
    sellStockDate,
  } = state;
  const { requestSellStock } = props;

  const sellStock = () => {
    if (
      sellStockQuantity &&
      sellStockCents &&
      sellStockDate &&
      sellStockSymbol
    ) {
      requestSellStock({
        symbol: sellStockSymbol,
        quantity: parseInt(sellStockQuantity, 10),
        sale_date: new Date(sellStockDate),
        sale_price_cents: parseInt(sellStockCents, 10),
      });
      setState(FRESH_STATE);
      history.push("/stocks");
    }
  };

  return (
    <>
      <Header pageTitle={"Sell Stock"} backRoute={"/stocks"} />
      <br />
      <FieldContainer>
        <TextField
          variant={"outlined"}
          label={"Symbol"}
          value={sellStockSymbol}
          onChange={(e) =>
            setState({ ...state, sellStockSymbol: e.target.value })
          }
          fullWidth={true}
        />
        <TextField
          variant={"outlined"}
          label={"Date Sold"}
          value={sellStockDate}
          type={"date"}
          onChange={(e) =>
            setState({ ...state, sellStockDate: e.target.value })
          }
          fullWidth={true}
        />
        <TextField
          variant={"outlined"}
          label={"Cents per Share"}
          value={sellStockCents}
          type={"number"}
          onChange={(e) =>
            setState({ ...state, sellStockCents: e.target.value })
          }
          fullWidth={true}
        />
        <TextField
          variant={"outlined"}
          label={"Quantity"}
          value={sellStockQuantity}
          type={"number"}
          onChange={(e) =>
            setState({ ...state, sellStockQuantity: e.target.value })
          }
          fullWidth={true}
        />
      </FieldContainer>
      <Button onClick={sellStock} variant={"outlined"} type={"submit"}>
        Sell
      </Button>
    </>
  );
});
