import { CardActionArea } from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import CardActions from "@material-ui/core/CardActions/CardActions";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import { ReactNode } from "react";
import * as React from "react";
import { useHistory } from "react-router-dom";
import styled from "@material-ui/core/styles/styled";
import { Color, textColorForBackgroundColor } from "./colors";

interface IProps {
  actions?: any;
  avatar?: any;
  center?: boolean;
  size?: GCardSize;
  subheader?: string;
  title?: string;
  variant?: Color;
  route?: string;
  img?: string;
  onClick?: () => void;
  children: ReactNode;
}

interface IStyledCardProps {
  size?: GCardSize;
  color?: Color;
}

export enum GCardSize {
  SMALL,
  MEDIUM,
  LARGE,
}

const StyledCard = styled(Card)({
  margin: "0.8em",
  maxWidth: (props: IStyledCardProps) => {
    if (props.size != null) {
      switch (props.size) {
        case GCardSize.SMALL:
          return "30em";
        case GCardSize.MEDIUM:
          return "50em";
        case GCardSize.LARGE:
          return "100%";
        default:
          return;
      }
    }
  },
  color: (props: IStyledCardProps) =>
    textColorForBackgroundColor(props.color || Color.Dark),
  backgroundColor: (props: IStyledCardProps) => props.color || Color.Dark,
});

interface IStyledCardContentProps {
  textAlignCenter: boolean;
}

const StyledCardContent = styled(CardContent)({
  padding: "1.3em",
  textAlign: (props: IStyledCardContentProps) =>
    props.textAlignCenter ? "center" : undefined,

  "& a": {
    color: Color.Blue,
  },
});

function cardHeader(avatar?: any, title?: string, subheader?: string) {
  if (avatar || title || subheader) {
    return <CardHeader avatar={avatar} subheader={subheader} title={title} />;
  }
}

function cardActions(actions: any) {
  if (actions) {
    return <CardActions>{actions}</CardActions>;
  }
}

const CardImage = styled(CardMedia)({
  height: "140px",
});

function cardBody(props: IProps) {
  const { children, avatar, center, title, subheader, img } = props;

  return (
    <>
      {img && <CardImage image={img} title="Contemplative Reptile" />}
      {cardHeader(avatar, title, subheader)}
      <StyledCardContent textAlignCenter={!!center}>
        {children}
      </StyledCardContent>
      {cardActions(props.actions)}
    </>
  );
}

function GCard(props: IProps) {
  const { size, variant, route } = props;
  const history = useHistory();
  let body = cardBody(props);
  let onClick = props.onClick;

  if (route) {
    onClick = () => history.push(route);
  }

  if (onClick) {
    body = <CardActionArea onClick={onClick}>{body}</CardActionArea>;
  }
  return (
    <StyledCard size={size} color={variant}>
      {body}
    </StyledCard>
  );
}

export default GCard;
