import {Button, styled} from "@material-ui/core";
import * as React from "react";
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {Color} from "../../lib/colors";
import GCard from "../../lib/GCard";
import GLabel from "../../lib/GLabel";
import GTitle from "../../lib/GTitle";
import Header from "../../main/Header";
import {IStoreState} from "../../store";
import {Permission} from "../AuthApi1";
import Login from "./Login";
import {loginRequest, logoutRequest} from "../reducer";

interface IProps {
  authError: string;
  currentUserName: string;
  currentUserToken: string;
  isUserLoggedIn: boolean;
  permissions: Permission[];

  loginAction: (username: string, password: string) => void;
  logoutAction: () => void;
}

const TokenText = styled("span")({
  color: Color.Purple,
});

const PermissionsRow = styled("div")({
  display: "flex",
  flexDirection: "row",
});

export default connect(
  (store: IStoreState) => {
    return {
      authError: store.auth.error,
      currentUserName: store.auth.userName,
      currentUserToken: store.auth.userToken,
      permissions: store.auth.permissions,
    };
  },
  (dispatch: Dispatch) => {
    return {
      loginAction: (username: string, password: string) =>
        dispatch(loginRequest({username, password})),
      logoutAction: () => dispatch(logoutRequest()),
    };
  }
)((props: IProps) => {
  const {currentUserName, currentUserToken, logoutAction, permissions} = props;

  const permissionLabels: React.ReactNode[] = [];
  permissions.forEach(p => permissionLabels.push(<GLabel key={p} label={p} variant={Color.Purple}/>));

  if (currentUserToken) {
    return (
      <>
        <Header pageTitle={"Account"} />
        <GTitle title={"Welcome, " + currentUserName}/>
        <GCard actions={<Button onClick={logoutAction} color={"secondary"}>Logout</Button>}>
          <ul>
            <li>Token: <TokenText>{currentUserToken}</TokenText></li>
            <li>Permissions: <PermissionsRow>{permissionLabels}</PermissionsRow></li>
          </ul>
        </GCard>
      </>
    );
  }

  const {authError, loginAction} = props;
  return (
    <>
      <Header pageTitle={"Login"}/>
      <Login authError={authError} loginAction={loginAction}/>
    </>
  );
});
