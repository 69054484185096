import { all, takeEvery } from "redux-saga/effects";
import authenticatedSaga from "../auth/authenticatedSaga";
import {
  IIpAddressesRequest,
  IP_ADDRESSES_REQUEST,
  newIpAddressesRequestError,
  newIpAddressesRequestSuccess,
} from "./actions/ipAddressesRequest";
import {
  IPackageVersionsRequest,
  PACKAGE_VERSIONS_REQUEST,
  newPackageVersionsRequestError,
  newPackageVersionsRequestSuccess,
} from "./actions/packageVersionsRequest";
import {
  ISetZfsDriveBayRequest,
  newSetZfsDriveBayRequestError,
  newSetZfsDriveBayRequestSuccess,
  SET_ZFS_DRIVE_BAY_REQUEST,
} from "./actions/setZfsDriveBayRequest";
import {
  ISpeedTestsRequest,
  SPEED_TESTS_REQUEST,
  newSpeedTestsRequestError,
  newSpeedTestsRequestSuccess,
} from "./actions/speedTestsRequest";
import {
  IZfsDrivesRequest,
  ZFS_DRIVES_REQUEST,
  newZfsDrivesRequestError,
  newZfsDrivesRequestSuccess,
} from "./actions/zfsDrivesRequest";
import ToolsApi from "./ToolsApi";

function* ipAddressesRequestSaga(action: IIpAddressesRequest) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: ToolsApi.ipAddresses,
    success: newIpAddressesRequestSuccess,
    error: newIpAddressesRequestError,
  });
}

function* packageVersionsRequestSaga(action: IPackageVersionsRequest) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: ToolsApi.packageVersions,
    success: newPackageVersionsRequestSuccess,
    error: newPackageVersionsRequestError,
  });
}

function* speedTestsRequestSaga(action: ISpeedTestsRequest) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: ToolsApi.speedtests,
    success: newSpeedTestsRequestSuccess,
    error: newSpeedTestsRequestError,
  });
}

function* zfsDrivesRequestSaga(action: IZfsDrivesRequest) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: ToolsApi.zfsDrives,
    success: newZfsDrivesRequestSuccess,
    error: newZfsDrivesRequestError,
  });
}

function* setZfsDriveBayRequestSaga(action: ISetZfsDriveBayRequest) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: ToolsApi.setBayNumber,
    success: newSetZfsDriveBayRequestSuccess,
    error: newSetZfsDriveBayRequestError,
    args: [action.serial, action.bayNumber],
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(IP_ADDRESSES_REQUEST, ipAddressesRequestSaga),
    takeEvery(PACKAGE_VERSIONS_REQUEST, packageVersionsRequestSaga),
    takeEvery(SPEED_TESTS_REQUEST, speedTestsRequestSaga),
    takeEvery(ZFS_DRIVES_REQUEST, zfsDrivesRequestSaga),
    takeEvery(SET_ZFS_DRIVE_BAY_REQUEST, setZfsDriveBayRequestSaga),
  ]);
}
