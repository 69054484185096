import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import * as React from "react";
import {IRecipe} from "../RecipeApi1";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  recipe: IRecipe;
}

export default function RecipeCost(props: IProps) {
  const {recipe} = props;
  const cost = recipe.cost || "";

  return <RecipeAttr title={"Cost"} icon={<AttachMoneyIcon/>} value={cost} suffix={""}/>;
}
