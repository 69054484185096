import { FormControlLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

interface IGCheckboxProps {
  checked: boolean;
  onChange: () => void;
  label?: React.ReactNode;
}

export default function GCheckbox(props: IGCheckboxProps) {
  const { checked, onChange, label } = props;

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={label}
    />
  );
}
