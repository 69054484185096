import ClearAllIcon from "@material-ui/icons/ClearAll";
import * as React from "react";
import {IRecipe} from "../RecipeApi1";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  recipe: IRecipe;
}

export default function RecipePans(props: IProps) {
  const {recipe} = props;
  const potsAndPans = recipe.pots_and_pans || "";

  return <RecipeAttr title={"Pots/Pans Required"} icon={<ClearAllIcon/>} value={potsAndPans} suffix={"pans"}/>;
}
