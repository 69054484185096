import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import PackageVersions from "./routes/PackageVersions";
import ZfsMonitor from "./routes/ZfsMonitor";

export default () => {
  const match = useRouteMatch();
  return (
    <Switch>
      <Route path={`${match.path}/packages`} component={PackageVersions} />
      <Route path={`${match.path}/zfs`} component={ZfsMonitor} />
    </Switch>
  );
};
