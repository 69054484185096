import {BottomNavigation, BottomNavigationAction} from "@material-ui/core";
import styled from "@material-ui/core/styles/styled";
import {LocalDining, ShoppingCart} from "@material-ui/icons";
import * as React from "react";
import {Color} from "../../lib/colors";
import BrowseRecipes from "./BrowseRecipes";
import Cart from "./Cart";
import Planner from "./Planner";
import EventNoteIcon from '@material-ui/icons/EventNote';
import {useHistory, useLocation} from "react-router-dom";

const RecipeNavigationBuffer = styled("div")({
  marginBottom: "6em",
});

const RecipeNavigationContainer = styled(BottomNavigation)({
  position: "fixed",
  width: "100%",
  height: "65px",
  bottom: 0,
  left: 0,
  zIndex: 1000,
  borderTop: `solid 2px ${Color.Primary}`,

  "& svg": {
    fontSize: "1.75rem",
  },
});

export default () => {
  const history = useHistory();
  const location = useLocation();
  const currentTab = new URLSearchParams(location.search).get('t') || 'list';

  function handleTabChange(event: any, newValue: string) {
    history.push({search: `?t=${newValue}`});
    return newValue;
  }

  return (
    <>
      <RecipeNavigationBuffer>
        {currentTab === "list" && <BrowseRecipes/>}
        {currentTab === "cart" && <Cart/>}
        {currentTab === "plan" && <Planner/>}
      </RecipeNavigationBuffer>
      <RecipeNavigationContainer value={currentTab} onChange={handleTabChange} showLabels>
        <BottomNavigationAction label={"Recipes"} value={"list"} icon={<LocalDining/>}/>
        <BottomNavigationAction label={"Cart"} value={"cart"} icon={<ShoppingCart/>}/>
        <BottomNavigationAction label={"Planner"} value={"plan"} icon={<EventNoteIcon/>}/>
      </RecipeNavigationContainer>
    </>
  );
};
