import styled from "@material-ui/core/styles/styled";
import {
  ArrowDownwardOutlined,
  ArrowRightAltOutlined,
  ArrowUpwardOutlined,
  Brightness5,
} from "@material-ui/icons";
import * as React from "react";
import { Color } from "../../lib/colors";
import { IStockDataNews, IStockDataRecommendation } from "../StocksApi";
import { prettyTime, shortDate } from "./common";

const Container = styled("div")({
  padding: "0.5em",
  margin: "0.5em",
  borderWidth: "0 0 1px 0",
  borderColor: Color.Gray,
  borderStyle: "dashed",
  display: "flex",
});

const NewsLink = styled("a")({
  color: Color.Purple,
  fontSize: "14pt",
});

const Publisher = styled("div")({
  color: Color.Blue,
  fontStyle: "italic",
  fontSize: "12pt",
});

const DateWrapper = styled("div")({
  textAlign: "center",
  width: "13%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: "monospace",
});

const RestWrapper = styled("div")({
  width: "87%",
});

interface IProps {
  news: IStockDataNews;
}

export default (props: IProps) => {
  const { news } = props;

  return (
    <Container>
      <DateWrapper>
        {shortDate(news.publish_ts)}
        <br />
        {prettyTime(news.publish_ts).replace("M", "")}
      </DateWrapper>
      <RestWrapper>
        <NewsLink href={news.link} target={"_blank"}>
          {news.title}
        </NewsLink>
        <Publisher>{news.publisher}</Publisher>
      </RestWrapper>
    </Container>
  );
};

interface IStockNewsRecommendationProps {
  rec: IStockDataRecommendation;
}

interface IActionIconWrapperProps {
  color: string;
}

const ActionIconWrapper = styled("div")({
  "& svg": {
    color: (props: IActionIconWrapperProps) => props.color,
    height: "40px",
    width: "40px",
  },
});

const Grade = styled("div")({
  fontWeight: "bold",
});

export const StockNewsRecommendation = (
  props: IStockNewsRecommendationProps
) => {
  const { rec } = props;

  let icon;
  let color = Color.White;
  switch (rec.action) {
    case "up":
      icon = <ArrowUpwardOutlined />;
      color = Color.Green;
      break;
    case "down":
      icon = <ArrowDownwardOutlined />;
      color = Color.Red;
      break;
    case "main":
      icon = <ArrowRightAltOutlined />;
      color = Color.Blue;
      break;
    case "init":
      icon = <Brightness5 />;
      color = Color.Yellow;
  }

  return (
    <Container>
      <DateWrapper>
        {shortDate(rec.when)}
        <br />
        {prettyTime(rec.when).replace("M", "")}
      </DateWrapper>
      <RestWrapper>
        <Grade>
          {rec.from_grade
            ? `${rec.from_grade} to ${rec.to_grade}`
            : rec.to_grade}
        </Grade>
        <Publisher>{rec.firm}</Publisher>
      </RestWrapper>
      <ActionIconWrapper color={color}>{icon}</ActionIconWrapper>
    </Container>
  );
};
