import { Button } from "@material-ui/core";
import { Refresh } from "@material-ui/icons";
import * as React from "react";
import styled from "@material-ui/core/styles/styled";
import { Color } from "./colors";
import GCard from "./GCard";

interface IProps {
  message: string;
  retry: () => void;
}

const ErrorContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

function GRequestError(props: IProps) {
  const { message, retry } = props;

  return (
    <GCard variant={Color.Alert}>
      <ErrorContainer>
        {message}
        <Button onClick={retry}>
          <Refresh />
        </Button>
      </ErrorContainer>
    </GCard>
  );
}

export default GRequestError;
