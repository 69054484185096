export const PROFICIENCY_TOGGLE = "PROFICIENCY_TOGGLE";
export type PROFICIENCY_TOGGLE = typeof PROFICIENCY_TOGGLE;

export interface IProficiencyToggle {
  name: string;
  type: PROFICIENCY_TOGGLE;
}

export function newProficiencyToggle(name: string): IProficiencyToggle {
  return {
    name: name.toLocaleLowerCase(),
    type: PROFICIENCY_TOGGLE,
  };
}
