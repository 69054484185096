import { Grid } from "@material-ui/core";
import * as React from "react";
import { Github } from "react-social-github";
import styled from "@material-ui/core/styles/styled";
import GCard from "../lib/GCard";
import GTitle from "../lib/GTitle";
import Header from "../main/Header";
import { Color, textColorForBackgroundColor } from "../lib/colors";
import EducationCard from "./components/EducationCard";
import PersonalProject from "./components/PersonalProject";
import Proficiency from "./components/Proficiency";
import WorkExperience from "./components/WorkExperience";
import ResumeDataService from "./ResumeDataService";

const ResumeContainer = styled("div")({
  "& .rsg-github-wrapper, & .rsg-github": {
    boxShadow: "none",
    padding: 0,
    backgroundColor: Color.Dark,
    color: textColorForBackgroundColor(Color.Dark),

    "& .rsg-info": {
      display: "none",
    },
  },

  "& .rsg-text": {
    color: "#000",
  },
});

const SelfInfoContainer = styled("div")({
  margin: "2em",
});

const ResumeList = styled("ul")({
  padding: 0,

  "& li": {
    marginBottom: "1em",
  },
});

function SelfInfo(): JSX.Element {
  return (
    <SelfInfoContainer>
      <ResumeList>
        <li>Full-Stack, Backend, & Infrastructure</li>
        <li>Distributed & Multi-Threaded Applications</li>
        <li>Performance Analysis and Tuning</li>
        <li>Development Velocity, Tooling, and Automation</li>
        <li>(firstname)@(this domain)</li>
      </ResumeList>
    </SelfInfoContainer>
  );
}

function workExperiences() {
  const experiences: JSX.Element[] = [];

  for (const experience of ResumeDataService.getWorkExperience()) {
    experiences.push(
      <WorkExperience key={experience.name} experience={experience} />
    );
  }

  return experiences;
}

const PersonalProjectsContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  flexWrap: "wrap",
});

function personalProjects() {
  const projects: JSX.Element[] = [];

  for (const project of ResumeDataService.getProjects()) {
    projects.push(<PersonalProject key={project.name} experience={project} />);
  }

  return <PersonalProjectsContainer>{projects}</PersonalProjectsContainer>;
}

const ProficiencyContainer = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
});

function TopTech(): JSX.Element {
  const topTechData = ResumeDataService.getTopTech();
  const topTechs: JSX.Element[] = [];

  topTechData.forEach((tech) => {
    topTechs.push(<Proficiency name={tech} key={tech} />);
  });

  return <ProficiencyContainer>{topTechs}</ProficiencyContainer>;
}

export default function Resume() {
  return (
    <>
      <Header pageTitle={"Résumé"} />
      <ResumeContainer>
        <Grid
          container
          alignItems={"center"}
          justify={"space-around"}
          spacing={2}
        >
          <Grid item xs={12} sm={5} md={4}>
            <GCard center>
              <Github type="widget" user="gregflynn" />
            </GCard>
          </Grid>
          <Grid item xs={12} sm={7} md={4}>
            <SelfInfo />
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
            <EducationCard />
          </Grid>
        </Grid>

        <GTitle title={"Proficiency"} />
        <TopTech />

        <GTitle title={"Experience"} />
        {workExperiences()}

        <GTitle title={"Projects"} />
        {personalProjects()}
      </ResumeContainer>
    </>
  );
}
