import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import * as React from "react";
import {IRecipe} from "../RecipeApi1";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  recipe: IRecipe;
}

export default function RecipeIngredientCount(props: IProps) {
  const { recipe } = props;
  const numIngredients = recipe.ingredient_list.length;
  return <RecipeAttr title={"Total Ingredients"} icon={<ShoppingBasketIcon/>} value={numIngredients.toString()} suffix={"ingr."}/>;
}
