import * as React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import EditRecipe from "./routes/EditRecipe";
import RecipeRoot from "./routes/RecipeRoot";
import ViewRecipe from "./routes/ViewRecipe";

export default () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/:id/edit`} component={EditRecipe} />
      <Route path={`${match.path}/:id`} component={ViewRecipe} />
      <Route path={"/"} component={RecipeRoot} />
    </Switch>
  );
};
