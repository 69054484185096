import { Permission } from "./AuthApi1";

export interface IAuthStore {
  error: string;
  userId: number;
  userName: string;
  userToken: string;
  permissions: Permission[];
}

export const DEFAULT_AUTH_STORE: IAuthStore = {
  error: "",
  userId: -1,
  userName: "",
  userToken: "",
  permissions: [],
};
