import styled from "@material-ui/core/styles/styled";
import React from "react";
import {Color} from "../../lib/colors";

interface IProps {
  text: string;
}

const Coloring = styled("span")({
  color: Color.Primary,
  fontWeight: 500,
  fontSize: "16pt",
});

export default function(props: IProps) {
  const { text } = props;
  return (
    <Coloring>
      {text}
    </Coloring>
  );
}
