import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { Bar, BarChart, Tooltip, XAxis } from "recharts";
import { Color } from "../../lib/colors";
import { ITooltipProps, TooltipWrapper } from "../../lib/recharts";
import { IStockData, IStockDataBuy, IStockDataQuote } from "../StocksApi";
import { currFormat } from "./common";

const DEFAULT_MAX_AGE = 90 * 24 * 60 * 60 * 1000;

const GoodPrice = styled("span")({
  color: Color.Good,
});

const BadPrice = styled("span")({
  color: Color.Alert,
});

const CustomTooltip = (props: ITooltipProps) => {
  const { active, label, payload } = props;

  if (active && payload) {
    let value = 0;

    payload.forEach((p) => {
      if (p.value && p.value !== 0) {
        value += typeof p.value === "number" ? p.value : 0;
      }
    });

    const price = currFormat.format(value);
    return (
      <TooltipWrapper>
        {/*HACK: push the UTC date far enough so that Central time is that day too*/}
        {new Date(label + 6 * 60 * 60 * 1000).toLocaleDateString()}
        <br />
        {value >= 0 ? (
          <GoodPrice>{price}</GoodPrice>
        ) : (
          <BadPrice>{price}</BadPrice>
        )}
      </TooltipWrapper>
    );
  } else {
    return null;
  }
};

interface IDeltaData {
  timestamp: number;
  posPrice?: number;
  negPrice?: number;
}

interface IProps {
  s: IStockData | IStockDataBuy | IStockDataQuote;
  maxMillisecondsOld?: number;
  width?: number;
  height?: number;
}

export default (props: IProps) => {
  const { maxMillisecondsOld, width, height, s } = props;

  const now = new Date();
  const now_ts = now.getTime();
  const historyData: IDeltaData[] = [];
  const maxAge = maxMillisecondsOld || DEFAULT_MAX_AGE;

  s.series.forEach((p, index) => {
    const date = new Date(p.timestamp * 1000);
    if (now_ts - date.getTime() > maxAge) {
      return;
    }

    let prev;
    if (index > 0) {
      prev = s.series[index - 1];
    }

    if (prev && new Date(prev.timestamp * 1000).getDay() + 1 != date.getDay()) {
      // push a spacer before every monday
      historyData.push({
        timestamp: date.getTime() - 24 * 60 * 60 * 1000,
      });
    }

    historyData.push({
      timestamp: date.getTime(),
      posPrice: p.delta > 0 ? p.delta : 0,
      negPrice: p.delta < 0 ? p.delta : 0,
    });
  });

  return (
    <BarChart width={width || 1000} height={height || 250} data={historyData}>
      <Bar dataKey="posPrice" stackId={"1"} fill={Color.Green} />
      <Bar dataKey="negPrice" stackId={"1"} fill={Color.Red} />
      <Tooltip content={CustomTooltip} />
      <XAxis dataKey={"timestamp"} hide={true} />
    </BarChart>
  );
};
