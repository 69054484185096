import { GridSize, useMediaQuery, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";
import { Color } from "../../lib/colors";
import { IStockDataQuote } from "../StocksApi";
import { colorForStock, colorForValue, currFormat } from "./common";

const RowGrid = styled("div")({
  fontSize: "16pt",
  fontWeight: 100,
  borderTop: `2px solid ${Color.Dark}`,
  paddingTop: "0.2em",
  paddingBottom: "0.2em",
  cursor: "pointer",
  "&:hover": { backgroundColor: Color.Dark },
  paddingRight: "0.5em",
});

interface IRowIndicatorProps {
  color: string;
}

const RowIndicator = styled("div")({
  borderLeft: (props: IRowIndicatorProps) => `5px solid ${props.color}`,
});

const CellWrapper = styled("div")({
  color: (props: any) => props.color,
});

interface ICellProps {
  value: string;
  raw?: number;
  center?: boolean;
  small?: true;
}

const Cell = (props: ICellProps) => {
  const { value, center, small, raw } = props;

  return (
    <Grid item xs={3} md={small ? 1 : 2}>
      <CellWrapper
        align={center ? "center" : "right"}
        color={colorForValue(raw || 0)}
      >
        {value}
      </CellWrapper>
    </Grid>
  );
};

interface IStockRowProps {
  sdq: IStockDataQuote;
}

export default (props: IStockRowProps): JSX.Element => {
  const { sdq } = props;
  const history = useHistory();

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  const symbolDiv = <Cell value={sdq.symbol} center />;
  return (
    <RowGrid
      onClick={() => {
        history.push(`/stocks/${sdq.symbol}`);
      }}
    >
      <RowIndicator color={colorForStock(sdq)}>
        <Grid container>
          {symbolDiv}
          {isLarge && (
            <>
              <Cell small value={sdq.total_quantity.toString()} />
              <Cell small value={currFormat.format(sdq.average_cost)} />
            </>
          )}
          <Cell
            value={currFormat.format(sdq.current_price)}
            raw={sdq.current_price - sdq.average_cost}
          />
          {isLarge && (
            <Cell
              value={currFormat.format(sdq.total_profit)}
              raw={sdq.total_profit}
            />
          )}
          <Cell value={`${sdq.total_roi.toFixed(1)}%`} raw={sdq.total_roi} />
          <Cell
            value={currFormat.format(sdq.last_close_delta)}
            raw={sdq.last_close_delta}
          />
        </Grid>
      </RowIndicator>
    </RowGrid>
  );
};

const HeaderGrid = styled(Grid)({
  paddingRight: "1em",
});

export const StockRowHeader = (): JSX.Element => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const stockRowHeaders: ReactNode[] = [
    "symbol",
    "# ",
    "buy",
    "current",
    "total profit",
    "ROI",
    "𝚫1d",
  ].map((h, i) => {
    let size: GridSize = [1, 2].indexOf(i) === -1 ? 2 : 1;
    if (isSmall) {
      if (i === 1 || i === 2 || i === 4) {
        return null;
      }
      size = 3;
    }

    return (
      <Grid item xs={size} key={h}>
        <CellWrapper
          color={Color.Purple}
          align={[0, 2].indexOf(i) != -1 ? "center" : "right"}
        >
          {h}
        </CellWrapper>
      </Grid>
    );
  });
  return <HeaderGrid container>{stockRowHeaders}</HeaderGrid>;
};
