import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Delete } from "@material-ui/icons";
import ClearIcon from "@material-ui/icons/Clear";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import LinkIcon from "@material-ui/icons/Link";
import RestaurantIcon from "@material-ui/icons/Restaurant";
import SaveIcon from "@material-ui/icons/Save";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import { Color } from "lib/colors";
import GCard from "lib/GCard";
import Header from "main/Header";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { IStoreState } from "store";
import styled from "@material-ui/core/styles/styled";
import { IGAPIDataState, useGData } from "../../lib/GAPIData";
import GCheckbox from "../../lib/GCheckbox";
import GFab from "../../lib/GFab";
import DietaryFlagLabel from "../components/DietaryFlagLabel";
import Instruction from "../components/Instruction";
import MealTypeLabel from "../components/MealTypeLabel";
import RecipeBox from "../components/RecipeBox";
import {
  collectIngredients,
  condenseIngredients,
  DietaryFlag,
  IIngredient,
  IInstruction,
  IRecipe,
  MealType,
  selectRecipe,
} from "../RecipeApi1";
import AddIcon from "@material-ui/icons/Add";
import {
  updateRecipeRequest,
  recipesRequest,
  deleteRecipeRequest,
} from "../reducer";

const DeleteButtonRow = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  marginTop: "1.7em",
  marginBottom: "3em",
});

interface IFieldProps {
  label: string;
  value?: any;
  onChange?: any;
  icon?: any;
  xs?: 1 | 2 | 3 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  type?: string;
}

function makeField(props: IFieldProps) {
  const { label, onChange, icon, value, type, xs } = props;
  let ip = {};
  if (icon) {
    ip = {
      startAdornment: <InputAdornment position="start">{icon}</InputAdornment>,
    };
  }
  return (
    <Grid item xs={xs || 12}>
      <TextField
        variant={"outlined"}
        label={label}
        value={value}
        onChange={onChange}
        fullWidth={true}
        InputProps={ip}
        type={type || "text"}
      />
    </Grid>
  );
}

function makeNumberField(props: IFieldProps) {
  return makeField({ type: "number", xs: 3, ...props });
}

function makeMealTypeCheckboxes(
  recipe: IRecipe,
  state: IState,
  setState: (s: IState) => void
) {
  const checkboxes = [];
  const checkedMealTypes = new Set(recipe.meal_types);

  function toggleMealType(mealType: MealType) {
    if (checkedMealTypes.has(mealType)) {
      checkedMealTypes.delete(mealType);
    } else {
      checkedMealTypes.add(mealType);
    }
    setState({ ...state, recipeMealTypes: [...checkedMealTypes] });
  }

  for (const mt of Object.entries(MealType)) {
    const value = mt[1];
    checkboxes.push(
      <Grid key={value} item xs={6} sm={2}>
        <GCheckbox
          checked={checkedMealTypes.has(value)}
          onChange={() => toggleMealType(value)}
          label={<MealTypeLabel name={value} />}
        />
      </Grid>
    );
  }

  return checkboxes;
}

function makeDietaryCheckboxes(
  recipe: IRecipe,
  state: IState,
  setState: (s: IState) => void
) {
  const checkboxes = [];
  const checkedDiet = new Set(recipe.dietary_flags);

  function toggleDietFlag(dietFlag: DietaryFlag) {
    if (checkedDiet.has(dietFlag)) {
      checkedDiet.delete(dietFlag);
    } else {
      checkedDiet.add(dietFlag);
    }
    setState({ ...state, recipeDietFlags: [...checkedDiet] });
  }

  for (const df of Object.entries(DietaryFlag)) {
    const value = df[1];
    checkboxes.push(
      <Grid key={value} item xs={6} sm={2}>
        <GCheckbox
          checked={checkedDiet.has(value)}
          onChange={() => toggleDietFlag(value)}
          label={<DietaryFlagLabel name={value} />}
        />
      </Grid>
    );
  }

  return checkboxes;
}

const InstructionActions = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
});

const OverlapContainer = styled("div")({
  width: "100%",
  marginBottom: "-1.2em",
  zIndex: 2,
  position: "relative",
});

const AddIconWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

function reflowSteps(steps: IInstruction[]) {
  return steps.map((value, i) => {
    return { ...value, step: i + 1 };
  });
}

function renderInstructions(
  recipe: IRecipe,
  state: IState,
  setState: (s: IState) => void
) {
  /**
   * State updates
   */

  function addInstruction(index: number) {
    const instructions = [...recipe.instruction_list];
    instructions.splice(index, 0, {
      step: 0,
      ingredients: [],
      text: "",
      minutes: 5,
    });
    setState({ ...state, recipeInstructions: reflowSteps(instructions) });
  }

  function removeInstruction(instruction: IInstruction) {
    const instructions = [...recipe.instruction_list];
    instructions.splice(instruction.step - 1, 1);
    setState({ ...state, recipeInstructions: reflowSteps(instructions) });
  }

  function setStateForInstruction(
    instruction: IInstruction,
    condense?: boolean
  ) {
    const instructions = [...recipe.instruction_list];
    instructions[instruction.step - 1] = instruction;
    let newState = { ...state, recipeInstructions: instructions };
    if (condense) {
      newState = {
        ...newState,
        recipeCondensedIngredients: condenseIngredients(
          collectIngredients(instructions)
        ),
      };
    }
    setState(newState);
  }

  function addIngredient(instruction: IInstruction) {
    const ingredients = [...instruction.ingredients];
    ingredients.push({ quantity: "", name: "" });
    setStateForInstruction({ ...instruction, ingredients });
  }

  function removeIngredient(instruction: IInstruction, index: number) {
    const ingredients = [...instruction.ingredients];
    ingredients.splice(index, 1);
    setStateForInstruction({ ...instruction, ingredients }, true);
  }

  function setStateForIngredient(
    instruction: IInstruction,
    ingredient: IIngredient,
    index: number
  ) {
    const ingredients = [...instruction.ingredients];
    ingredients[index] = ingredient;
    setStateForInstruction({ ...instruction, ingredients }, true);
  }

  /**
   * Instruction/Ingredients
   */

  function renderIngredient(
    instruction: IInstruction,
    ingredient: IIngredient,
    index: number
  ) {
    function onIngredientChange(i: IIngredient) {
      setStateForIngredient(instruction, i, index);
    }

    return (
      <Grid item key={index}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {makeField({
              label: "Quantity",
              value: ingredient.quantity,
              onChange: (e: any) =>
                onIngredientChange({ ...ingredient, quantity: e.target.value }),
            })}
          </Grid>
          <Grid item xs={6}>
            {makeField({
              label: "Name",
              value: ingredient.name,
              onChange: (e: any) =>
                onIngredientChange({ ...ingredient, name: e.target.value }),
            })}
          </Grid>
          <Grid item xs={2}>
            <IconButton
              color={"secondary"}
              onClick={() => removeIngredient(instruction, index)}
            >
              <ClearIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  function instructionActions(instruction: IInstruction) {
    return (
      <InstructionActions>
        <Button color={"primary"} onClick={() => addIngredient(instruction)}>
          Add Ingredient
        </Button>
        <Button onClick={() => removeInstruction(instruction)}>
          Remove Step
        </Button>
      </InstructionActions>
    );
  }

  function renderInstruction(instruction: IInstruction) {
    return (
      <GCard
        key={`e-${instruction.step}`}
        variant={Color.Black}
        actions={instructionActions(instruction)}
      >
        <br />
        <Grid container spacing={2} justify={"center"}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4}>
              {makeNumberField({
                label: "Minutes",
                value: instruction.minutes,
                onChange: (e: any) =>
                  setStateForInstruction({
                    ...instruction,
                    minutes: e.target.value,
                  }),
                icon: <TimelapseIcon />,
              })}
              <Grid item xs={12}>
                <TextField
                  multiline
                  fullWidth
                  placeholder={"Instruction text..."}
                  value={instruction.text}
                  variant={"outlined"}
                  onChange={(e: any) =>
                    setStateForInstruction({
                      ...instruction,
                      text: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              spacing={1}
              justify={"center"}
              alignItems={"center"}
            >
              {instruction.ingredients.map((ingredient, index) =>
                renderIngredient(instruction, ingredient, index)
              )}
            </Grid>
          </Grid>
        </Grid>
      </GCard>
    );
  }

  /**
   * Build the list of instructions
   */

  function createAddButton(index: number) {
    return (
      <AddIconWrapper key={index}>
        <IconButton onClick={() => addInstruction(index)}>
          <AddIcon />
        </IconButton>
      </AddIconWrapper>
    );
  }

  const instructionElements = [createAddButton(0)];

  for (const instruction of recipe.instruction_list) {
    instructionElements.push(
      <OverlapContainer key={`i-${instruction.step}`}>
        <Instruction instruction={instruction} />
      </OverlapContainer>
    );
    instructionElements.push(renderInstruction(instruction));
    instructionElements.push(createAddButton(instruction.step));
  }

  return instructionElements;
}

/**
 * Primary component
 */
interface IStoreProps {
  recipeData?: IGAPIDataState<IRecipe>;
  updateRecipeError?: string;
}

interface IDispatchProps {
  updateRecipeRequest: (recipe: IRecipe) => void;
  recipesRequest: () => void;
  deleteRecipeRequest: (r: IRecipe) => void;
}

////////////////////////////////////////////
// ADD NEW FIELD #1: add to state definition
interface IState {
  deleteConfirmOpen?: boolean;

  recipeName: string;
  recipeSource: string;
  recipeNumMeals: number;
  recipeLeftoverDays: number;
  recipeCost: number;
  recipePots: number;
  recipeMealTypes: MealType[];
  recipeDietFlags: DietaryFlag[];
  recipeInstructions: IInstruction[];
  recipeCondensedIngredients: IIngredient[];
  recipeImage: string;
}

export default connect(
  (store: IStoreState): IStoreProps => {
    return {
      recipeData: store.recipes.recipeData,
      updateRecipeError: store.recipes.updateRecipeError,
    };
  },
  (dispatch: Dispatch): IDispatchProps => {
    return {
      updateRecipeRequest: (r: IRecipe) => dispatch(updateRecipeRequest(r)),
      recipesRequest: () => dispatch(recipesRequest()),
      deleteRecipeRequest: (r: IRecipe) => dispatch(deleteRecipeRequest(r)),
    };
  }
)((props: IStoreProps & IDispatchProps) => {
  const {
    recipeData,
    updateRecipeRequest,
    updateRecipeError,
    recipesRequest,
    deleteRecipeRequest,
  } = props;
  const { id } = useParams<{ id: string }>();
  const recipes = useGData(recipeData, recipesRequest);
  const recipe = selectRecipe(recipes, id);

  ///////////////////////////////////////////////////////
  // ADD NEW FIELD #2: set state initial to recipe actual
  const [state, setState] = useState<IState>({
    recipeName: recipe ? recipe.name : "",
    recipeSource: recipe ? recipe.source_url || "" : "",
    recipeNumMeals: recipe ? recipe.meals || 0 : 0,
    recipeLeftoverDays: recipe ? recipe.leftover_days || 0 : 0,
    recipeCost: recipe ? recipe.cost || 0 : 0,
    recipePots: recipe ? recipe.pots_and_pans || 0 : 0,
    recipeMealTypes: recipe ? recipe.meal_types || [] : [],
    recipeDietFlags: recipe ? recipe.dietary_flags || [] : [],
    recipeInstructions: recipe ? recipe.instruction_list || [] : [],
    recipeCondensedIngredients: recipe ? recipe.ingredient_list : [],
    recipeImage: recipe ? recipe.photo || "" : "",
  });

  if (!recipe) {
    return <GCard variant={Color.Warn}>Recipe not found</GCard>;
  }

  ///////////////////////////////////
  // ADD NEW FIELD #3: read state out
  const {
    deleteConfirmOpen,
    recipeName,
    recipeSource,
    recipeNumMeals,
    recipeLeftoverDays,
    recipeCost,
    recipePots,
    recipeMealTypes,
    recipeDietFlags,
    recipeInstructions,
    recipeCondensedIngredients,
    recipeImage,
  } = state;

  ///////////////////////////////////////////
  // ADD NEW FIELD #4: update field on recipe
  const updatedRecipe = {
    ...recipe,
    name: recipeName,
    source_url: recipeSource,
    meals: recipeNumMeals,
    leftover_days: recipeLeftoverDays,
    cost: recipeCost,
    pots_and_pans: recipePots,
    meal_types: recipeMealTypes,
    dietary_flags: recipeDietFlags,
    instruction_list: recipeInstructions,
    ingredient_list: recipeCondensedIngredients,
    photo: recipeImage,
  };

  const openDeleteConfirm = () =>
    setState({ ...state, deleteConfirmOpen: true });
  const closeDeleteConfirm = () =>
    setState({ ...state, deleteConfirmOpen: false });
  const deleteRecipe = () => {
    if (recipe) {
      closeDeleteConfirm();
      deleteRecipeRequest(recipe);
    }
  };

  return (
    <>
      <Header pageTitle={"Recipe Edit"} backRoute={`/recipe/${id}`} />
      {updateRecipeError && (
        <GCard variant={Color.Alert}>{updateRecipeError}</GCard>
      )}
      <OverlapContainer>
        <RecipeBox recipe={updatedRecipe} noLink />
      </OverlapContainer>
      <GCard variant={Color.Black}>
        <br />
        <Grid container spacing={2} justify={"center"}>
          {/* ///////////////////////////////////// */}
          {/* ADD NEW FIELD #5: make the damn field */}
          {makeField({
            label: "Recipe Name",
            value: recipeName,
            onChange: (e: any) =>
              setState({ ...state, recipeName: e.target.value }),
          })}
          {makeField({
            label: "Recipe Source",
            value: recipeSource,
            onChange: (e: any) =>
              setState({ ...state, recipeSource: e.target.value }),
            icon: <LinkIcon />,
          })}
          {makeNumberField({
            label: "Meals",
            value: recipeNumMeals,
            onChange: (e: any) =>
              setState({ ...state, recipeNumMeals: e.target.value }),
            icon: <RestaurantIcon />,
          })}
          {/*{makeNumberField({*/}
          {/*  label: "Leftover Days",*/}
          {/*  value: recipeLeftoverDays,*/}
          {/*  onChange: (e: any) =>*/}
          {/*    setState({ ...state, recipeLeftoverDays: e.target.value }),*/}
          {/*  icon: <KitchenIcon />,*/}
          {/*})}*/}
          {/*{makeNumberField({*/}
          {/*  label: "Cost",*/}
          {/*  value: recipeCost,*/}
          {/*  onChange: (e: any) =>*/}
          {/*    setState({ ...state, recipeCost: e.target.value }),*/}
          {/*  icon: <AttachMoneyIcon />,*/}
          {/*})}*/}
          {makeNumberField({
            label: "Pans",
            value: recipePots,
            onChange: (e: any) =>
              setState({ ...state, recipePots: e.target.value }),
            icon: <ClearAllIcon />,
          })}
          {makeMealTypeCheckboxes(updatedRecipe, state, setState)}
          {makeDietaryCheckboxes(updatedRecipe, state, setState)}
          {makeField({
            label: "Photo",
            type: "file",
            onChange: (e: any) => {
              if (e.target.files.length > 0) {
                const file = e.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                  let result = reader.result;
                  if (typeof result !== "string") {
                    result = "";
                  }
                  setState({ ...state, recipeImage: result });
                };
              } else {
                setState({ ...state, recipeImage: "" });
              }
            },
            xs: 6,
          })}
        </Grid>
      </GCard>
      {renderInstructions(updatedRecipe, state, setState)}
      <DeleteButtonRow>
        <Button
          variant={"outlined"}
          startIcon={<Delete />}
          onClick={openDeleteConfirm}
        >
          Delete Recipe
        </Button>
      </DeleteButtonRow>

      <Dialog open={deleteConfirmOpen || false}>
        <DialogTitle>Confirm Clear Cart</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm that you want to delete {recipe.name}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirm}>Cancel</Button>
          <Button onClick={deleteRecipe} color={"primary"}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <GFab
        icon={<SaveIcon />}
        onClick={() => updateRecipeRequest(updatedRecipe)}
      />
    </>
  );
});
