/**
 * Request
 */
import {IAPIError} from "../../lib/GRequest";
import {ISpeedTest, ISpeedTestsResponse} from "../ToolsApi";

export const SPEED_TESTS_REQUEST = "SPEED_TESTS_REQUEST";
export type SPEED_TESTS_REQUEST = typeof SPEED_TESTS_REQUEST;

export interface ISpeedTestsRequest {
  type: SPEED_TESTS_REQUEST;
}

export function newSpeedTestsRequest(): ISpeedTestsRequest {
  return {
    type: SPEED_TESTS_REQUEST,
  };
}

/**
 * Error
 */
export const SPEED_TESTS_REQUEST_ERROR = "SPEED_TESTS_REQUEST_ERROR";
export type SPEED_TESTS_REQUEST_ERROR = typeof SPEED_TESTS_REQUEST_ERROR;

export interface ISpeedTestsRequestError {
  error: string;
  type: SPEED_TESTS_REQUEST_ERROR;
}

export function newSpeedTestsRequestError(error: IAPIError): ISpeedTestsRequestError {
  return {
    error: error.message,
    type: SPEED_TESTS_REQUEST_ERROR,
  };
}

/**
 * Success
 */
export const SPEED_TESTS_REQUEST_SUCCESS = "SPEED_TESTS_REQUEST_SUCCESS";
export type SPEED_TESTS_REQUEST_SUCCESS = typeof SPEED_TESTS_REQUEST_SUCCESS;

export interface ISpeedTestsRequestSuccess {
  speedtests: ISpeedTest[];
  type: SPEED_TESTS_REQUEST_SUCCESS;
}

export function newSpeedTestsRequestSuccess(response: ISpeedTestsResponse): ISpeedTestsRequestSuccess {
  return {
    speedtests: response.speedtests,
    type: SPEED_TESTS_REQUEST_SUCCESS,
  };
}
