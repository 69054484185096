const WORK_EXPERIENCE = [
  {
    name: "Klaviyo",
    image: "/resume/klaviyo.png",
    title: "Lead Site Reliability Engineer",
    start: "Oct. 2017",
    end: "Dec. 2022",
    tech: [
      "python",
      "redis",
      "django",
      "docker",
      "java",
      "flink",
      "celery",
      "cassandra",
      "aws",
      "MySQL",
      "javascript",
      "lua",
    ],
    bullets: [
      "Architectural vision creation, project planning, and day-to-day for a small engineering team",
      "Lead cross-department effort to standardize our public APIs",
      "Optimized validation and deployment of new code, taking significant time off the process",
      "Participated in engineering groups to review and validate new architectural and API patterns",
      "Lead team of engineers developing internal tools to optimize internal processes to Klaviyo Engineering.",
      "Identified and resolved various security issues with testing infrastructure.",
      "Rolled out Jenkins adoption across the engineering organization.",
      "Lead weekly tech talk session, soliciting presenters within the organization and contributed my own.",
      "Created libraries for generic internal patterns and hosted them on Artifactory.",
      "Designed distributed and fault tolerant generic data aggregation library backed by redis.",
      "Optimized and distributed knowledge on performance optimized Cassandra querying.",
    ],
  },
  {
    name: "Conduit",
    image: "/resume/conduit.png",
    title: "Founder/Director of Engineering",
    start: "May 2017",
    end: "Sep. 2017",
    tech: [
      "python",
      "docker",
      "PostgreSQL",
      "redis",
      "flask",
      "bash",
      "javascript",
    ],
    bullets: [
      "Built out a distributed service for analyzing incoming user data from various third party communication " +
        "services and process that data asynchronously",
      "Created REST APIs to interact with the imported user data and optimize future imports",
      "Designed database tables in PostgreSQL to represent graph objects",
      "Led scrum and filled the role of project manager for a small team",
      "Optimized performance of the service through custom job reporting, sqlalchemy query logging, and python " +
        "cProfile",
    ],
  },
  {
    name: "Jana Mobile",
    image: "/resume/jana.png",
    title: "Senior Software Engineer",
    start: "Mar. 2015",
    end: "May 2017",
    tech: [
      "python",
      "cassandra",
      "snowflake",
      "Android",
      "bash",
      "flask",
      "java",
      "javascript",
      "AWS",
    ],
    bullets: [
      "Implemented new user facing features end to end, including tooling; from Android, python API, to " +
        "javascript internal tools",
      "Worked directly with sales to improve tooling and implement client requests",
      "Refactored critical pieces of infrastructure improving performance, design patterns, and documentation",
      "Identified and removed unused parts of codebase and infrastructure",
      "Designed and built production auditing tools for tracking changes made to document stores",
    ],
  },
  {
    name: "Vistaprint",
    image: "/resume/vistaprint.png",
    title: "Senior Software Engineer",
    start: "Mar. 2013",
    end: "Mar. 2015",
    tech: [
      "C#.Net",
      "MSSQL",
      "puppet",
      "ServiceStack",
      "NancyFx",
      "javascript",
      "powershell",
    ],
    bullets: [
      "Interfaced with new payment processors",
      "Created services for Vistaprint and subsidiaries to calculate appropriate regional taxes anywhere in " +
        "the world",
      "Built REST APIs for merchants acquired by Vistaprint to store accounting and tax information about " +
        "products they’re selling and tooling to allow financial experts to validate the system",
      "Documented and trained coworkers on Swagger best practices for use with new REST services",
      "Wrapped APIs around an internal job queue system to allow remote job management",
    ],
  },
  {
    name: "Crimson Hexagon",
    image: "/resume/crimson-hexagon.jpg",
    title: "Software Engineer",
    start: "Jun. 2012",
    end: "Mar. 2013",
    tech: [
      "java",
      "Spring",
      "Hibernate",
      "PostgreSQL",
      "Apache Tomcat",
      "maven",
      "subversion",
      "bash",
    ],
    bullets: [
      "Built internal tools which allowed account managers to toggle features of the platform at will",
      "Streamlined software releases, reducing time required to perform releases down to minutes",
      "Maintained primary and backup Twitter Stream downloader",
    ],
  },
];

const PERSONAL_PROJECTS = [
  {
    name: "settler",
    tech: ["python", "PostgreSQL", "flask"],
    bullets: [
      "Settler is a simple migration framework for Flask and SQLAlchemy",
    ],
    github: "https://github.com/gregflynn/settler",
  },
];

const INTERNSHIPS = [
  {
    name: "CouchConnect",
    title: "Contracted Web Programmer",
    start: "Jun. 2011",
    end: "Nov. 2011",
    tech: ["python", "django", "PostgreSQL", "AWS"],
    bullets: [
      "Developed new features for an existing site including optimizations, caching, and load testing",
    ],
    isInternship: true,
  },
  {
    name: "Binghamton Psych",
    title: "Programmer / Designer",
    start: "Apr. 2010",
    end: "Mar. 2011",
    tech: ["PHP", "MySQL", "Javascript", "HTML"],
    bullets: ["Created research tools to assist the department"],
    isInternship: true,
  },
  {
    name: "25eight Inc.",
    title: "Remote Programming Intern",
    start: "Jun. 2010",
    end: "Dec. 2010",
    tech: ["python", "PostgreSQL", "Javascript", "Django"],
    bullets: [
      "Implemented new features, fixed bugs, and database model design",
    ],
    isInternship: true,
  },
];

const techMap = new Map();
for (const section of [
  ...WORK_EXPERIENCE,
  ...PERSONAL_PROJECTS,
  ...INTERNSHIPS,
]) {
  for (const tech of section.tech) {
    const key = tech.toLocaleLowerCase();
    const count = techMap.get(key);

    if (count > 0) {
      techMap.set(key, count + 1);
    } else {
      techMap.set(key, 1);
    }
  }
}
const TOP_TECH = Array.from(techMap.keys())
  .sort((a, b) => techMap.get(b) - techMap.get(a))
  .slice(0, 18);

export interface IWorkExperience {
  name: string;
  title?: string;
  start?: string;
  end?: string;
  tech: string[];
  bullets: string[];
  isInternship?: boolean;
  image?: string;
  github?: string;
}

class ResumeDataService {
  public static getWorkExperience(): IWorkExperience[] {
    return WORK_EXPERIENCE;
  }

  public static getProjects(): IWorkExperience[] {
    return PERSONAL_PROJECTS;
  }

  public static getInternships(): IWorkExperience[] {
    return INTERNSHIPS;
  }

  public static getTopTech() {
    return TOP_TECH;
  }
}

export default ResumeDataService;
