import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, takeLatest } from "redux-saga/effects";
import authenticatedSaga from "../auth/authenticatedSaga";
import { all } from "redux-saga/effects";
import {
  recipesRequest,
  recipesRequestSuccess,
  recipesRequestError,
  updateRecipeRequest,
  updateRecipeRequestSuccess,
  updateRecipeRequestError,
  createRecipeRequest,
  createRecipeRequestError,
  createRecipeRequestSuccess,
  cartRequest,
  cartRequestError,
  cartRequestSuccess,
  cartCheckRequest,
  cartCheckRequestError,
  cartCheckRequestSuccess,
  cartUncheckRequest,
  cartUncheckRequestSuccess,
  cartClearRequestSuccess,
  cartClearRequest,
  cartClearRequestError,
  cartAddItemRequest,
  cartAddItemRequestError,
  cartAddItemRequestSuccess,
  cartAddRecipeRequest,
  cartAddRecipeRequestError,
  cartAddRecipeRequestSuccess,
  deleteRecipeRequestSuccess,
  deleteRecipeRequestError,
  deleteRecipeRequest,
} from "./reducer";
import CartApi1, { ICartItem } from "./CartApi1";
import RecipeApi1, { IRecipe } from "./RecipeApi1";

function* recipesRequestSaga(action: PayloadAction) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: RecipeApi1.recipes,
    success: (response: any) => recipesRequestSuccess(response.recipes),
    error: recipesRequestError,
  });
}

function* updateRecipeRequestSaga(action: PayloadAction<IRecipe>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: RecipeApi1.updateRecipe,
    success: updateRecipeRequestSuccess,
    error: updateRecipeRequestError,
    args: [action.payload],
    successRoute: (a: any) => `/recipe/${a.payload.id}`,
  });
}

function* createRecipeRequestSaga(action: PayloadAction<string>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: RecipeApi1.createRecipe,
    success: createRecipeRequestSuccess,
    error: createRecipeRequestError,
    args: [action.payload],
    successRoute: (a: any) => `/recipe/${a.payload.id}/edit`,
  });
}

function* cartRequestSaga(action: PayloadAction) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: CartApi1.getCart,
    success: cartRequestSuccess,
    error: cartRequestError,
  });
}

function* cartCheckRequestSaga(action: PayloadAction<ICartItem>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: CartApi1.check,
    success: cartCheckRequestSuccess,
    error: cartCheckRequestError,
    args: [action.payload],
  });
}

function* cartUnCheckRequestSaga(action: PayloadAction<ICartItem>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: CartApi1.uncheck,
    success: cartUncheckRequestSuccess,
    error: cartCheckRequestError,
    args: [action.payload],
  });
}

function* cartClearRequestSaga(action: PayloadAction) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: CartApi1.clearCart,
    success: cartClearRequestSuccess,
    error: cartClearRequestError,
  });
}

function* cartAddItemRequestSaga(action: PayloadAction<ICartItem>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: CartApi1.addItemToCart,
    success: () => cartAddItemRequestSuccess(action.payload),
    error: cartAddItemRequestError,
    args: [action.payload.name, action.payload.quantity],
  });
}

function* cartAddRecipeRequestSaga(action: PayloadAction<number>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: CartApi1.addRecipeToCart,
    success: () => cartAddRecipeRequestSuccess(action.payload),
    error: cartAddRecipeRequestError,
    args: [action.payload, 1],
  });
}

function* deleteRecipeRequestSaga(action: PayloadAction<IRecipe>) {
  yield* authenticatedSaga({
    sourceAction: action,
    api: RecipeApi1.deleteRecipe,
    success: deleteRecipeRequestSuccess,
    error: deleteRecipeRequestError,
    args: [action.payload.id],
    successRoute: () => `/recipe/`,
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(recipesRequest.toString(), recipesRequestSaga),
    takeEvery(updateRecipeRequest.toString(), updateRecipeRequestSaga),
    takeEvery(createRecipeRequest.toString(), createRecipeRequestSaga),
    takeEvery(cartRequest.toString(), cartRequestSaga),
    takeEvery(cartCheckRequest.toString(), cartCheckRequestSaga),
    takeEvery(cartUncheckRequest.toString(), cartUnCheckRequestSaga),
    takeEvery(cartClearRequest.toString(), cartClearRequestSaga),
    takeEvery(cartAddItemRequest.toString(), cartAddItemRequestSaga),
    takeEvery(cartAddRecipeRequest.toString(), cartAddRecipeRequestSaga),
    takeLatest(deleteRecipeRequest.toString(), deleteRecipeRequestSaga),
  ]);
}
