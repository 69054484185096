import * as React from "react";
import GLabel from "../../lib/GLabel";
import {Color} from "../../lib/colors";
import {DietaryFlag} from "../RecipeApi1";

interface IProps {
  name: DietaryFlag;
}

export default function DietaryFlagLabel(props: IProps) {
  const {name} = props;

  return <GLabel key={name} label={name} variant={Color.Green} invert/>;
}
