import { call, put, select } from "redux-saga/effects";
import history from "../main/history";
import { IStoreState } from "../store";
import { logoutSuccess } from "./reducer";

export interface AuthenticatedSagaProps {
  sourceAction: any;
  api: any;
  success: (r: any) => any;
  error: (r: any) => any;
  args?: any[];
  successRoute?: (a: any) => string;
  errorRoute?: (a: any) => string;
}

export default function* authenticatedSaga(
  props: AuthenticatedSagaProps
): Generator<any, any, any> {
  const {
    sourceAction,
    api,
    success,
    error,
    args,
    successRoute,
    errorRoute,
  } = props;

  const apiToken: string = yield select(
    (store: IStoreState) => store.auth.userToken
  );

  const authAndReturn = () =>
    history.push("/account", {
      from: history.location.pathname,
      search: history.location.search,
      sourceAction,
    });

  if (!apiToken) {
    yield put(logoutSuccess());
    yield authAndReturn();
  } else {
    const response = yield call(api, ...[apiToken, ...(args || [])]);

    if (response.error) {
      if (response.status === 401) {
        yield put(logoutSuccess());
        yield authAndReturn();
      } else {
        const errorAction = error(response);
        yield put(errorAction);
        if (errorRoute) {
          yield history.push(errorRoute(errorAction));
        }
      }
    } else {
      const successAction = success(response);
      yield put(successAction);
      if (successRoute) {
        yield history.push(successRoute(successAction));
      }
    }
  }
}
