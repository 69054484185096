import { Link } from "@material-ui/core";
import * as React from "react";
import { useHistory } from "react-router-dom";
import GText from "../lib/GText";
import GTitle from "../lib/GTitle";
import Header from "./Header";

export default function Home() {
  const history = useHistory();

  return (
    <>
      <Header pageTitle={"gfly.io"} />
      <GTitle title={"Welcome"} />
      <GText>
        There isn't a whole lot here yet and I haven't decided if there will be
        much in the way of public facing content on this website, aside from
        my&nbsp;
        <Link onClick={() => history.push("/resume")}>résumé</Link>.
      </GText>
    </>
  );
}
