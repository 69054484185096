import { CardActionArea, Checkbox } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IStoreState } from "../../store";
import { ICartItem } from "../CartApi1";
import { cartCheckRequest, cartUncheckRequest } from "../reducer";
import Ingredient from "./Ingredient";

interface IProps {
  item: ICartItem;
  check: (i: ICartItem) => void;
  uncheck: (i: ICartItem) => void;
}

interface IStyledCardProps {
  checked: boolean;
}

const StyledCard = styled(Card)({
  margin: "0.5em",
  maxWidth: "35em",
  width: "95%",
  opacity: (props: IStyledCardProps) => (props.checked ? 0.3 : 1),
});

const StyledCardContent = styled(CardContent)({
  padding: "0.2em",
  display: "flex",
});

export default connect(
  (store: IStoreState) => {
    return {};
  },
  (dispatch: Dispatch) => {
    return {
      check: (i: ICartItem) => dispatch(cartCheckRequest(i)),
      uncheck: (i: ICartItem) => dispatch(cartUncheckRequest(i)),
    };
  }
)((props: IProps) => {
  const { item } = props;

  function onClick() {
    if (item.checked) {
      props.uncheck(item);
    } else {
      props.check(item);
    }
  }

  return (
    <StyledCard checked={item.checked}>
      <CardActionArea onClick={onClick}>
        <StyledCardContent>
          <Checkbox checked={item.checked} />
          <Ingredient
            ingredient={{ name: item.name, quantity: item.quantity || "" }}
          />
        </StyledCardContent>
      </CardActionArea>
    </StyledCard>
  );
});
