import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "@material-ui/core/styles/styled";
import {Layers, Storage} from "@material-ui/icons";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BuildIcon from "@material-ui/icons/Build";
import HomeIcon from "@material-ui/icons/Home";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import WorkIcon from "@material-ui/icons/Work";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Permission, userHasPermission } from "../auth/AuthApi1";
import { Color } from "../lib/colors";
import { prettyDateTime } from "../stocks/components/common";
import { IStoreState } from "../store";
import MultilineChartIcon from "@material-ui/icons/MultilineChart";
import RefreshIcon from "@material-ui/icons/Refresh";

const Row = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  position: "sticky",
  top: 0,
  zIndex: 100,
  background: `linear-gradient(${Color.Background}FF, 95%, ${Color.Background}00)`,
});

const Title = styled("div")({
  fontSize: "36pt",
  fontWeight: 100,
  color: Color.Purple,
});

const Refreshed = styled("div")({
  fontSize: "10pt",
  color: Color.Gray,
  position: "absolute",
  bottom: "5%",
  left: "70%",
  "& svg": {
    marginBottom: "-2px",
  },
});

const MenuButton = styled(IconButton)({
  width: "2.5em",

  "& svg": {
    fontSize: "2.2rem",
  },
});

function makeMenuItem(icon: any, label: string, onClick: any) {
  return (
    <MenuItem key={label} onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{label}</ListItemText>
    </MenuItem>
  );
}

interface IProps {
  pageTitle: string;
  backRoute?: string;
  lastRefreshed?: number;
  userToken?: string;
  userPermissions?: Permission[];
}

interface IState {
  menuTarget?: HTMLElement;
  backRouteLocation?: string;
}

export default connect((store: IStoreState) => {
  return {
    userToken: store.auth.userToken,
    userPermissions: store.auth.permissions,
  };
})((props: IProps) => {
  const {
    pageTitle,
    backRoute,
    userToken,
    userPermissions,
    lastRefreshed,
  } = props;
  const [state, setState] = useState<IState>({});
  const { menuTarget } = state;
  const menuOpen = Boolean(menuTarget);
  const history = useHistory();
  let lastRefreshedDate;

  if (lastRefreshed) {
    lastRefreshedDate = prettyDateTime(lastRefreshed);
  }

  function onBackClick() {
    if (backRoute) {
      history.push(backRoute);
    }
  }

  function onMenuClose() {
    setState({ ...state, menuTarget: undefined });
  }

  function onMenuClick(event: React.MouseEvent<HTMLElement>) {
    if (menuOpen) {
      onMenuClose();
    } else {
      setState({ ...state, menuTarget: event.currentTarget });
    }
  }

  function route(r: string) {
    return () => {
      onMenuClose();
      history.push(r);
    };
  }

  const menu = [
    makeMenuItem(<HomeIcon />, "Home", route("/")),
    makeMenuItem(
      <AccountCircleIcon />,
      userToken ? "Account" : "Login",
      route("/account")
    ),
  ];

  if (userToken) {
    menu.push(makeMenuItem(<LocalDiningIcon />, "Recipes", route("/recipe")));

    if (userHasPermission(userPermissions || [], Permission.System)) {
      menu.push(
        makeMenuItem(<MultilineChartIcon />, "Stocks", route("/stocks"))
      );
      menu.push(makeMenuItem(<Layers/>, "Packages", route("/tools/packages")));
      menu.push(makeMenuItem(<Storage/>, "ZFS Monitor", route("/tools/zfs")));
    }
  }

  menu.push(makeMenuItem(<WorkIcon />, "Resume", route("/resume")));

  return (
    <Row>
      <MenuButton onClick={onBackClick} color={"primary"} disabled={!backRoute}>
        {/* NOTE: we don't hide the element so it takes up space and keeps the
                  title centered */}
        {backRoute && <ArrowBackIcon />}
      </MenuButton>
      <Title>{pageTitle}</Title>
      {lastRefreshedDate && (
        <Refreshed>
          <RefreshIcon fontSize={"inherit"} />
          {lastRefreshedDate}
        </Refreshed>
      )}
      <MenuButton onClick={onMenuClick} color={"primary"}>
        {menuOpen ? <MenuOpenIcon /> : <MenuIcon />}
      </MenuButton>
      <Menu
        open={menuOpen}
        anchorEl={menuTarget}
        onClose={onMenuClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {menu}
      </Menu>
    </Row>
  );
});
