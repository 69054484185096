import GRequest, { IAPIError } from "../lib/GRequest";

const API_NS = "auth";

export interface IAuthUser {
  id: number;
  username: string;
  permissions: Permission[];
  email: string;
}

export interface IAuthLoginResponse {
  token: string;
  user: IAuthUser;
}

export enum Permission {
  Tasks = "tasks",
  Users = "users",
  Recipes = "recipes",
  System = "system",
  Digestion = "digestion",
  AppTokens = "app_tokens",
}

export type AuthResponse = IAuthLoginResponse | IAPIError;

class AuthApi1 {
  /**
   * Login and fetch current user data
   * @param username
   * @param password
   */
  public static login(
    username: string,
    password: string
  ): Promise<AuthResponse> {
    return AuthApi1.request()
      .method("POST")
      .body({ password, username })
      .fetch();
  }

  /**
   * Logout the current user, removing the auth token from use
   * @param token
   */
  public static logout(token: string): Promise<Record<string, never>> {
    return AuthApi1.request().method("DELETE").token(token).fetch();
  }

  private static request(url?: string): GRequest {
    return new GRequest(API_NS + (url ? `/${url}` : ""));
  }
}

export default AuthApi1;

export function userHasPermission(
  permissions: Permission[],
  required: Permission
): boolean {
  return permissions.indexOf(required) >= 1;
}
