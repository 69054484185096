import {Grid} from "@material-ui/core";
import styled from "@material-ui/core/styles/styled";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import GFraction from "../../lib/GFraction";
import {Color} from "../../lib/colors";
import {IIngredient, splitQuantity} from "../RecipeApi1";

interface IProps {
  ingredient: IIngredient;
}

const Quantity = styled("div")({
  display: "flex",
  alignItems: "center",
  fontSize: "16pt",
});

const Amount = styled("div")({
  marginRight: "0.1em",
});

const QuantityLabel = styled(Typography)({
  textTransform: "capitalize",
  fontSize: "11pt",
});


function quantity(quantityString: string) {
  const {amount, label} = splitQuantity(quantityString);
  if (!amount && !label) {
    return;
  }

  return (
    <Quantity>
      {amount && (
        <Amount>
          <GFraction value={amount}/>
        </Amount>
      )}
      <QuantityLabel>
        {label}
      </QuantityLabel>
    </Quantity>
  );
}

const IngredientName = styled(Typography)({
  color: Color.Blue,
  textTransform: "capitalize",
  minHeight: "2em",
  lineHeight: "2em",
  fontSize: "16pt",
  fontWeight: 300,
});

export default function Ingredient(props: IProps) {
  const {ingredient} = props;

  return (
    <Grid container alignItems={"center"} justify={"center"}>
      <Grid item xs={3}>
        {quantity(ingredient.quantity)}
      </Grid>
      <Grid item xs={9}>
        <IngredientName>
          {ingredient.name}
        </IngredientName>
      </Grid>
    </Grid>
  );
}
