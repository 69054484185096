import RestaurantIcon from "@material-ui/icons/Restaurant";
import * as React from "react";
import {IRecipe} from "../RecipeApi1";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  recipe: IRecipe;
}

export default function RecipeTotalMeals(props: IProps) {
  const {recipe} = props;
  const meals = recipe.meals || "";
  return <RecipeAttr title={"Meals Made"} icon={<RestaurantIcon/>} value={meals} suffix={"meals"}/>;
}
