import { CircularProgress } from "@material-ui/core";
import * as React from "react";
import styled from "@material-ui/core/styles/styled";

const LoadingContainer = styled("div")({
  marginTop: "8em",
  marginBottom: "8em",
  width: "100%",
  textAlign: "center",
});

function GLoading() {
  return (
    <LoadingContainer>
      <CircularProgress size={140} />
    </LoadingContainer>
  );
}

export default GLoading;
