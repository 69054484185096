import GRequest, { IAPIError } from "../lib/GRequest";

// TODO: this is only used for adding stocks and isn't really right for that
export interface IStock {
  purchase_date: Date;
  purchase_price_cents: number;
  quantity: number;
  symbol: string;
  dividends?: IStockDividend[];
  sale_date?: Date;
  sale_price_cents?: number;
}

export interface IStockDividend {
  date: number;
  value: number;
}

export interface ISellStock {
  symbol: string;
  quantity: number;
  sale_date: Date;
  sale_price_cents: number;
}

//
// STOCK DATA API
//

export interface IStockDataSeriesPoint {
  timestamp: number;
  delta: number;
  realized_profit: number;
  dividend_profit: number;
  value: number;
  invested: number;
  share_profit: number;
}

// export interface IStockDataHistory {
//   price: number;
//   open: number;
//   close: number;
//   high: number;
//   low: number;
//   date: number;
// }

export interface IStockDataBuy {
  symbol: string;
  quantity: number;
  purchase_price: number;
  purchase_date: number;
  days_owned: number;
  sale_date?: number;
  sale_price?: number;
  dividends?: IStockDividend[];
  total_cost: number;
  total_profit: number;
  total_share_profit: number;
  total_dividend_profit: number;
  total_current: number;
  total_roi: number;
  total_apy: number;
  last_close_delta: number;
  total_sale: number;
  total_sale_profit: number;
  series: IStockDataSeriesPoint[];
}

export interface IStockDataRecommendation {
  when: number;
  firm: string;
  to_grade: string;
  from_grade: string;
  action: string;
}

export interface IStockDataNews {
  title: string;
  publisher: string;
  link: string;
  publish_ts: number;
}

export interface IStockDataQuote {
  symbol: string;
  exchange: string;
  buys: IStockDataBuy[];
  name: string;
  sector: string;
  industry: string;
  country: string;
  website: string;
  logo: string;
  ps_ratio: number;
  pb_ratio: number;
  pe_ratio: number;
  eps: number;
  dividend_yield: number;
  market_cap: number;
  cash: number;
  debt: number;
  debt_to_cash: number;
  recommendations: IStockDataRecommendation[];
  news: IStockDataNews[];
  current_price: number;
  today_change_pct: number;
  // history: IStockDataHistory[];
  last_close_price: number;
  last_close_delta: number;
  total_quantity: number;
  total_cost: number;
  average_cost: number;
  total_current: number;
  total_roi: number;
  total_profit: number;
  total_share_profit: number;
  total_dividend_profit: number;
  series: IStockDataSeriesPoint[];
  all_sold: boolean;
}

export interface IStockDataRefreshed {
  quotes: number;
  history: number;
  info: number;
  response: number;
}

export interface IStockData {
  stocks: IStockDataQuote[];
  refreshed: IStockDataRefreshed;
  market_status: string;
  total_invested: number;
  total_profit: number;
  total_share_profit: number;
  total_dividend_profit: number;
  total_current: number;
  total_roi: number;
  series: IStockDataSeriesPoint[];
}

//
// RESPONSE OBJECTS
//

export type StockAddResponse = IStock | IAPIError;

export type StockDataResponse = IStockData | IAPIError;

class StocksApi {
  public static stockData(token: string): Promise<StockDataResponse> {
    return new GRequest("stocks/data", "GET", token).fetch();
  }

  public static purchaseStock(
    token: string,
    stock: IStock
  ): Promise<StockAddResponse> {
    return new GRequest("stocks", "POST", token).body(stock).fetch();
  }

  public static sellStock(token: string, stock: ISellStock) {
    return new GRequest("stocks", "PUT", token).body(stock).fetch();
  }
}

export default StocksApi;
