import { styled } from "@material-ui/core";
import * as React from "react";
import { ReactNode } from "react";
import { Color } from "../../lib/colors";
import {
  ITooltipProps,
  TooltipLabel,
  TooltipWrapper,
} from "../../lib/recharts";
import { IStockDataBuy, IStockDataQuote } from "../StocksApi";

export const placeRound = (x: number, d: number) =>
  Math.round(1000 * (x / d)) / 10;

export const currFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const bigText = {
  fontSize: "18pt",
  fontWeight: 100,
};

export const StockValueSpan = styled("span")({
  ...bigText,
});

export const Box = styled("div")({
  padding: "2em",
});

export const colorForValue = (r: number) => {
  if (-0.001 <= r && r <= 0.001) {
    return Color.White;
  }
  return r > 0 ? Color.Green : Color.Red;
};

interface IColoredValueProps {
  raw: number;
}

export const ColoredValue = styled(StockValueSpan)({
  color: (props: IColoredValueProps) => colorForValue(props.raw),
});

interface ICustomColoredValueProps {
  color: string;
}

export const CustomColoredValue = styled(StockValueSpan)({
  color: (props: ICustomColoredValueProps) => props.color,
});

export const createColumn = (
  label: string,
  value: ReactNode,
  hint: string | undefined = undefined
) => (
  <ValueColumn title={hint}>
    <ValueLabel>{label}</ValueLabel>
    {value}
  </ValueColumn>
);

export const ValueLabel = styled("span")({
  color: Color.Primary,
  fontSize: "11pt",
});

export const ValueColumn = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const shortDate = (ts: number) => {
  return new Date(ts * 1000).toLocaleDateString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  });
};

export const prettyDateTime = (ts: number) => {
  return new Date(ts * 1000).toLocaleString("en-US", {
    year: "2-digit",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

export const prettyTime = (ts: number) => {
  return new Date(ts * 1000).toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

export const CustomTooltip = (props: ITooltipProps) => {
  const { active, label, payload } = props;

  if (active && payload) {
    const ps = payload.map((p, i) => (
      <div key={i}>
        <TooltipLabel color={p.color}>{p.name}: </TooltipLabel>
        {typeof p.value === "number" ? currFormat.format(p.value) : p.value}
      </div>
    ));

    return (
      <TooltipWrapper>
        {/*HACK: push the UTC date far enough so that Central time is that day too*/}
        {new Date((label + 6 * 60 * 60) * 1000).toLocaleDateString()}
        {ps}
      </TooltipWrapper>
    );
  } else {
    return null;
  }
};

export const colorForPS = (ps: number | null) => {
  if (ps != null) {
    if (ps <= 0.8) return Color.Green;
    if (ps <= 1.0) return Color.Blue;
  }
  return Color.White;
};

export const colorForPE = (pe: number | null) => {
  if (pe != null) {
    if (pe <= 15) return Color.Green;
    if (pe <= 17) return Color.Blue;
    if (pe > 25) return Color.Red;
  }
  return Color.White;
};

export const colorForStock = (sdq: IStockDataQuote | IStockDataBuy) => {
  if (sdq.total_share_profit > 0.001) {
    return Color.Green;
  } else if (sdq.total_dividend_profit > Math.abs(sdq.total_share_profit)) {
    return Color.Blue;
  } else if (sdq.total_share_profit < -0.001) {
    return Color.Red;
  }
  return Color.White;
};

const LABELS = ["", "K", "M", "B", "T"];
export const condenseDollars = (dollars: number): string => {
  let value = dollars;
  let labelIdx = 0;

  while (value > 1000 && labelIdx < LABELS.length - 1) {
    value = value / 1000;
    labelIdx += 1;
  }

  const roundedNumber = Math.round(value * 10) / 10;
  return `${roundedNumber}${LABELS[labelIdx]}`;
};
