import KitchenIcon from "@material-ui/icons/Kitchen";
import * as React from "react";
import {IRecipe} from "../RecipeApi1";
import RecipeAttr from "./RecipeAttr";

interface IProps {
  recipe: IRecipe;
}

export default function RecipeReheatableDays(props: IProps) {
  const {recipe} = props;
  const days = recipe.leftover_days || "";

  return <RecipeAttr title={"Reheatable Days"} icon={<KitchenIcon/>} value={days} suffix={"days"}/>;
}
