import {IAPIError} from "../../lib/GRequest";
import {IIpAddressHistoryResponse, IUsedIp} from "../ToolsApi";

/**
 * Request
 */
export const IP_ADDRESSES_REQUEST = "IP_ADDRESSES_REQUEST";
export type IP_ADDRESSES_REQUEST = typeof IP_ADDRESSES_REQUEST;

export interface IIpAddressesRequest {
  type: IP_ADDRESSES_REQUEST;
}

export function newIpAddressesRequest(): IIpAddressesRequest {
  return {
    type: IP_ADDRESSES_REQUEST,
  };
}

/**
 * Error
 */
export const IP_ADDRESSES_REQUEST_ERROR = "IP_ADDRESSES_REQUEST_ERROR";
export type IP_ADDRESSES_REQUEST_ERROR = typeof IP_ADDRESSES_REQUEST_ERROR;

export interface IIpAddressesRequestError {
  error: string;
  type: IP_ADDRESSES_REQUEST_ERROR;
}

export function newIpAddressesRequestError(response: IAPIError): IIpAddressesRequestError {
  return {
    error: response.message,
    type: IP_ADDRESSES_REQUEST_ERROR,
  };
}

/**
 * Success
 */
export const IP_ADDRESSES_REQUEST_SUCCESS = "IP_ADDRESSES_REQUEST_SUCCESS";
export type IP_ADDRESSES_REQUEST_SUCCESS = typeof IP_ADDRESSES_REQUEST_SUCCESS;

export interface IIpAddressesRequestSuccess {
  ips: IUsedIp[];
  type: IP_ADDRESSES_REQUEST_SUCCESS;
}

export function newIpAddressesRequestSuccess(response: IIpAddressHistoryResponse): IIpAddressesRequestSuccess {
  return {
    ips: response.ips,
    type: IP_ADDRESSES_REQUEST_SUCCESS
  };
}
