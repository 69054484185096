import Button from "@material-ui/core/Button/Button";
import TextField from "@material-ui/core/TextField/TextField";
import {useState} from "react";
import * as React from "react";
import GCard from "../../lib/GCard";
import {Color} from "../../lib/colors";
import styled from "@material-ui/core/styles/styled";

interface ILoginProps {
  authError: string;
  loginAction: (username: string, password: string) => void;
}

interface ILoginState {
  userInput: string;
  passInput: string;
}

const LoginContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  height: "14em",
  marginTop: "3em",
});

function loginError(authError: string): JSX.Element | undefined {
  if (authError) {
    return <GCard variant={Color.Warn} center={true}>{authError}</GCard>;
  }
  return undefined;
}

export default function(props: ILoginProps) {
  const [state, setState] = useState<ILoginState>({ userInput: "", passInput: ""});

  function userInputChange(event: any) {
    setState({...state, userInput: event.target.value});
  }

  function passInputChange(event: any) {
    setState({...state, passInput: event.target.value});
  }

  function attemptLogin(e: any) {
    props.loginAction(state.userInput, state.passInput);
    e.preventDefault();
  }

  return (
    <div>
      {loginError(props.authError)}

      <form onSubmit={attemptLogin}>
        <LoginContainer>
          <TextField
            label="User"
            autoFocus={true}
            autoComplete={"off"}
            required={true}
            onChange={userInputChange}
            value={state.userInput}
            variant={"outlined"}
          />
          <TextField
            label="Password"
            type="password"
            required={true}
            onChange={passInputChange}
            value={state.passInput}
            variant={"outlined"}
          />
          <Button type={"submit"} variant={"outlined"}>Login</Button>
        </LoginContainer>
      </form>
    </div>
  );
}
