import { ButtonGroup, styled } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import * as React from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Dispatch } from "redux";
import Header from "main/Header";
import { IStoreState } from "store";
import { Color } from "../../lib/colors";
import { IGAPIDataState, useGData, usePeriodically } from "../../lib/GAPIData";
import GLoading from "../../lib/GLoading";
import GTitle from "../../lib/GTitle";
import {
  ColoredValue,
  colorForPE,
  colorForPS,
  condenseDollars,
  createColumn,
  currFormat,
  CustomColoredValue,
  StockValueSpan,
} from "../components/common";
import DeltaChart from "../components/DeltaChart";
import DividendBox, { IStockDividendDetails } from "../components/DividendBox";
import RefreshFooter from "../components/RefreshFooter";
import { StockBuy } from "../components/StockBuy";
import StockNewsItem, {
  StockNewsRecommendation,
} from "../components/StockNewsItem";
import { IStockData, IStockDataQuote } from "../StocksApi";
import { stockDataRequest } from "../reducer";
import { STOCK_DATA_MAX_AGE_SECONDS } from "../store";

const CompanyName = styled("div")({
  fontWeight: 100,
  fontSize: "20pt",
  color: Color.Yellow,
  marginLeft: "2em",
});

const Sector = styled("div")({
  fontWeight: 100,
  fontSize: "14pt",
  color: Color.Blue,
  marginLeft: "2.8em",
  marginBottom: "0.6em",
});

const TopRow = styled("div")({
  display: "flex",
  alignItems: "center",
});

const LogoContainer = styled("div")({
  backgroundColor: "#FFF",
  borderRadius: "10px",
  height: "128px",
  width: "128px",
  display: "flex",
  alignItems: "center",
  overflow: "clip",
});

const topButton = (name: string, url: string) => {
  return (
    <Button variant={"outlined"} target={"_blank"} href={url}>
      {name}
    </Button>
  );
};

interface IStoreProps {
  stockData?: IGAPIDataState<IStockData>;
}

interface IDispatchProps {
  requestStockData: () => void;
}

export default connect(
  (store: IStoreState): IStoreProps => {
    return {
      stockData: store.stocks.stockData,
    };
  },
  (dispatch: Dispatch): IDispatchProps => {
    return {
      requestStockData: () => dispatch(stockDataRequest()),
    };
  }
)((props: IStoreProps & IDispatchProps) => {
  const { stockData, requestStockData } = props;
  const { symbol } = useParams<{ symbol: string }>();
  const search = useLocation().search;
  const back = new URLSearchParams(search).get("back");
  const backRoute = back ? `/stocks/${back}` : "/stocks";

  const sd = useGData(stockData, requestStockData)[0];
  usePeriodically(STOCK_DATA_MAX_AGE_SECONDS, requestStockData, stockData);

  if (!sd) {
    return (
      <>
        <Header pageTitle={symbol} backRoute={backRoute} />
        <GLoading />
      </>
    );
  }

  const sqs: IStockDataQuote[] = [];

  sd.stocks.forEach((_sq) => {
    if (_sq.symbol == symbol) {
      sqs.push(_sq);
    }
  });

  if (sqs.length == 0) {
    return (
      <>
        <Header pageTitle={symbol} backRoute={backRoute} />
        Not Found
        <RefreshFooter stockData={sd} />
      </>
    );
  }

  const sq = sqs[0];
  const boxes: JSX.Element[] = [];
  const newsItems: JSX.Element[] = [];
  const newsItemByTs: Map<number, JSX.Element> = new Map();

  const dividendBoxes: JSX.Element[] = [];
  const dividendDataByTs: Map<number, IStockDividendDetails> = new Map();

  sq.buys.forEach((sb, i) => {
    boxes.push(<StockBuy key={`${sb.symbol}-${i}`} sb={sb} />);
    sb.dividends?.forEach((d) => {
      const p = dividendDataByTs.get(d.date);
      if (p) {
        dividendDataByTs.set(d.date, {
          ...p,
          quantity: sb.quantity + p.quantity,
        });
      } else {
        dividendDataByTs.set(d.date, { ...d, quantity: sb.quantity });
      }
    });
  });
  [...dividendDataByTs.keys()]
    .sort()
    .reverse()
    .forEach((ts) => {
      const d = dividendDataByTs.get(ts);
      if (d) {
        dividendBoxes.push(<DividendBox dividend={d} />);
      }
    });

  sq.news.forEach((n) =>
    newsItemByTs.set(n.publish_ts, <StockNewsItem news={n} />)
  );
  sq.recommendations.forEach((r) =>
    newsItemByTs.set(r.when, <StockNewsRecommendation rec={r} />)
  );
  [...newsItemByTs.keys()]
    .sort()
    .reverse()
    .forEach((ts) => {
      const item = newsItemByTs.get(ts);
      if (item) {
        newsItems.push(item);
      }
    });

  return (
    <>
      <Header pageTitle={symbol} backRoute={backRoute} />
      <TopRow>
        {sq.logo && (
          <LogoContainer>
            <img src={sq.logo} alt={"logo"} />
          </LogoContainer>
        )}
        <Grid container justify={"flex-end"} alignItems={"center"}>
          <Grid item xs={8}>
            <CompanyName>{sq.name}</CompanyName>
          </Grid>
          <Grid item xs={4}>
            <ButtonGroup size={"small"} variant={"outlined"}>
              {topButton("Website", sq.website)}
              {topButton("Yahoo", `https://finance.yahoo.com/quote/${symbol}`)}
              {topButton(
                "TipRanks",
                `https://www.tipranks.com/stocks/${symbol}/forecast`
              )}
              {topButton(
                "TradingView",
                `https://www.tradingview.com/symbols/${symbol}/`
              )}
            </ButtonGroup>
          </Grid>
          {(sq.sector || sq.industry || sq.country || sq.exchange) && (
            <Grid item xs={12}>
              <Sector>
                {(sq.sector || sq.industry) && `${sq.sector} / ${sq.industry}`}
                {sq.country && ` [${sq.country}] `}({sq.exchange})
              </Sector>
            </Grid>
          )}
          <Grid item xs={2}>
            {createColumn(
              "P/S",
              <CustomColoredValue color={colorForPS(sq.ps_ratio)}>
                {sq.ps_ratio === null ? "-" : sq.ps_ratio.toFixed(2)}
              </CustomColoredValue>,
              "Price to Sales"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "P/B",
              <StockValueSpan>
                {sq.pb_ratio === null ? "-" : sq.pb_ratio.toFixed(2)}
              </StockValueSpan>,
              "Price to Book (Assets)"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "P/E",
              <CustomColoredValue color={colorForPE(sq.pe_ratio)}>
                {sq.pe_ratio === null ? "-" : sq.pe_ratio.toFixed(2)}
              </CustomColoredValue>,
              "Price to Earnings"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "eps",
              <StockValueSpan>
                {sq.eps === null ? "-" : sq.eps.toFixed(2)}
              </StockValueSpan>,
              "Earnings per Share"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "debt:cash",
              <StockValueSpan>
                {sq.debt_to_cash === null ? "-" : sq.debt_to_cash.toFixed(2)}
              </StockValueSpan>,
              "Debt to Cash"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "div yield",
              <StockValueSpan>
                {sq.dividend_yield > 0
                  ? (sq.dividend_yield * 100).toFixed(2)
                  : 0}
                %
              </StockValueSpan>,
              "Annual Dividend Yield"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "current",
              <StockValueSpan>
                {currFormat.format(sq.current_price)}
              </StockValueSpan>,
              "Current Share Price"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "cap",
              <StockValueSpan>
                ${sq.market_cap ? condenseDollars(sq.market_cap) : "-"}
              </StockValueSpan>,
              "Total Market Cap"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "cash",
              <StockValueSpan>
                ${sq.cash ? condenseDollars(sq.cash) : "-"}
              </StockValueSpan>,
              "Total Cash"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "debt",
              <StockValueSpan>
                ${sq.debt ? condenseDollars(sq.debt) : "-"}
              </StockValueSpan>,
              "Total Debt"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "value",
              <ColoredValue raw={sq.total_share_profit}>
                {currFormat.format(sq.total_current)}
              </ColoredValue>,
              "Total Share Value"
            )}
          </Grid>
          <Grid item xs={2}>
            {createColumn(
              "invested",
              <StockValueSpan>
                {currFormat.format(sq.total_cost)}
              </StockValueSpan>,
              "Total Invested"
            )}
          </Grid>
          <Grid item xs={6}>
            <br />
            {createColumn(
              "𝚫1d",
              <ColoredValue raw={sq.last_close_delta}>
                {currFormat.format(sq.last_close_delta)} (
                {sq.today_change_pct.toFixed(1)}%)
              </ColoredValue>,
              "Change Since Last Close"
            )}
          </Grid>
        </Grid>
      </TopRow>
      <DeltaChart s={sq} />
      <GTitle title={"Buys"} sub />
      {boxes}
      <Grid container>
        <Grid item xs={8}>
          <GTitle title={"News"} sub />
          {newsItems.length > 0 ? newsItems : "No News"}
        </Grid>
        <Grid item xs={4}>
          <GTitle title={"Dividends"} sub />
          {dividendBoxes.length > 0 ? dividendBoxes : "No Dividends"}
        </Grid>
      </Grid>
      <RefreshFooter stockData={sd} />
    </>
  );
});
