import { Typography } from "@material-ui/core";
import * as React from "react";
import styled from "@material-ui/core/styles/styled";
import { Color } from "./colors";

const TextBlock = styled(Typography)({
  padding: "0.4em",
  paddingBottom: "1em",
  color: Color.White,
});

class GText extends React.Component {
  public render() {
    return <TextBlock variant={"body1"}>{this.props.children}</TextBlock>;
  }
}

export default GText;
