import {IAPIError} from "../../lib/GRequest";
import {IPackageVersion, IPackageVersionsResponse} from "../ToolsApi";

/**
 * Request
 */
export const PACKAGE_VERSIONS_REQUEST = "PACKAGE_VERSIONS_REQUEST";
export type PACKAGE_VERSIONS_REQUEST = typeof PACKAGE_VERSIONS_REQUEST;

export interface IPackageVersionsRequest {
  type: PACKAGE_VERSIONS_REQUEST;
}

export function newPackageVersionsRequest(): IPackageVersionsRequest {
  return {
    type: PACKAGE_VERSIONS_REQUEST,
  };
}

/**
 * Error
 */
export const PACKAGE_VERSIONS_REQUEST_ERROR = "PACKAGE_VERSIONS_REQUEST_ERROR";
export type PACKAGE_VERSIONS_REQUEST_ERROR = typeof PACKAGE_VERSIONS_REQUEST_ERROR;

export interface IPackageVersionsRequestError {
  error: string;
  type: PACKAGE_VERSIONS_REQUEST_ERROR;
}

export function newPackageVersionsRequestError(error: IAPIError): IPackageVersionsRequestError {
  return {
    error: error.message,
    type: PACKAGE_VERSIONS_REQUEST_ERROR,
  };
}

/**
 * Success
 */
export const PACKAGE_VERSIONS_REQUEST_SUCCESS = "PACKAGE_VERSIONS_REQUEST_SUCCESS";
export type PACKAGE_VERSIONS_REQUEST_SUCCESS = typeof PACKAGE_VERSIONS_REQUEST_SUCCESS;

export interface IPackageVersionsRequestSuccess {
  packages: IPackageVersion[];
  type: PACKAGE_VERSIONS_REQUEST_SUCCESS;
}

export function newPackageVersionsRequestSuccess(response: IPackageVersionsResponse): IPackageVersionsRequestSuccess {
  return {
    packages: response.packages,
    type: PACKAGE_VERSIONS_REQUEST_SUCCESS,
  };
}
