import {
  IProficiencyToggle,
  PROFICIENCY_TOGGLE,
} from "./actions/proficiencyToggle";
import { DEFAULT_RESUME_STORE, IResumeStore } from "./store";

type ResumeAction = IProficiencyToggle;

export default function reducer(
  state: IResumeStore = DEFAULT_RESUME_STORE,
  action: ResumeAction
): IResumeStore {
  switch (action.type) {
    case PROFICIENCY_TOGGLE:
      // eslint-disable-next-line no-case-declarations
      const name = action.name;
      // eslint-disable-next-line no-case-declarations
      const proficiencies = state.proficiencies;
      // eslint-disable-next-line no-case-declarations
      const newProficiencies: string[] = [];
      // eslint-disable-next-line no-case-declarations
      let removed = false;

      proficiencies.forEach((pName) => {
        if (pName === name) {
          removed = true;
        } else {
          newProficiencies.push(pName);
        }
      });

      if (!removed) {
        newProficiencies.push(name);
      }

      return {
        ...state,
        proficiencies: newProficiencies,
      };
  }
  return state;
}
