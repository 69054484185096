import * as React from "react";
import GLabel from "../../lib/GLabel";
import {Color} from "../../lib/colors";
import {MealType} from "../RecipeApi1";

interface IProps {
  name: MealType;
}

export default function MealTypeLabel(props: IProps) {
  const {name} = props;

  return <GLabel key={name} label={name} variant={Color.Blue} invert/>;
}
