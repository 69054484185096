import { ButtonGroup, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import styled from "@material-ui/core/styles/styled";
import {
  AccountBalance,
  ArrowBack,
  AttachMoney,
  ShoppingBasket,
} from "@material-ui/icons";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { IStockData } from "../StocksApi";
import {
  Box,
  ColoredValue,
  createColumn,
  currFormat,
  StockValueSpan,
} from "./common";

const ButtonContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginTop: "-0.9em",
  "&>*": {
    marginTop: "0.6em",
  },
});

interface IStockSummaryProps {
  stockData: IStockData;
  onAccount?: boolean;
}

export default (props: IStockSummaryProps) => {
  const { onAccount, stockData } = props;
  const history = useHistory();

  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Grid container>
        <Grid item xs={8} md={9}>
          <Grid container justify={"center"} alignContent={"center"}>
            <Grid item xs={5} md={3}>
              {createColumn(
                "status",
                <StockValueSpan>{stockData.market_status}</StockValueSpan>
              )}
            </Grid>
            {isLarge && (
              <>
                <Grid item xs={3}>
                  {createColumn(
                    "invested",
                    <StockValueSpan>
                      {currFormat.format(stockData.total_invested)}
                    </StockValueSpan>
                  )}
                </Grid>
                <Grid item xs={3}>
                  {createColumn(
                    "current",
                    <ColoredValue raw={stockData.total_share_profit}>
                      {currFormat.format(stockData.total_current)}
                    </ColoredValue>
                  )}
                </Grid>
              </>
            )}
            <Grid item xs={7} md={3}>
              {createColumn(
                `${stockData.total_roi.toFixed(2)}%`,
                <ColoredValue raw={stockData.total_profit}>
                  {currFormat.format(stockData.total_profit)}
                </ColoredValue>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} md={3}>
          <ButtonContainer>
            {onAccount ? (
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                startIcon={<ArrowBack />}
                onClick={() => history.push(`/stocks`)}
              >
                Stocks
              </Button>
            ) : (
              <Button
                size={"small"}
                variant={"outlined"}
                color={"primary"}
                startIcon={<AccountBalance />}
                onClick={() => history.push(`/stocks/account`)}
              >
                Account
              </Button>
            )}
            <ButtonGroup size={"small"} variant={"outlined"} color={"primary"}>
              <Button
                startIcon={<ShoppingBasket />}
                onClick={() => history.push(`/stocks/add`)}
              >
                Buy
              </Button>
              <Button
                startIcon={<AttachMoney />}
                onClick={() => history.push(`/stocks/sell`)}
              >
                Sell
              </Button>
            </ButtonGroup>
          </ButtonContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
