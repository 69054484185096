import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import styled from "@material-ui/core/styles/styled";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch } from "redux";
import Header from "main/Header";
import { IStock } from "../StocksApi";
import { stockAddRequest } from "../reducer";

const FieldContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  "& > *": {
    margin: "1em",
  },
});

const getDefaultDate = () => {
  const curr = new Date();
  return curr.toISOString().substr(0, 10);
};

interface IProps {
  requestAddStock: (s: IStock) => void;
}

interface IState {
  addStockSymbol?: string;
  addStockPrice?: string;
  addStockDate: string;
  addStockQuantity?: string;
}

const FRESH_STATE = {
  addStockDate: getDefaultDate(),
};

export default connect(
  () => {
    return {};
  },
  (dispatch: Dispatch) => {
    return {
      requestAddStock: (s: IStock) => dispatch(stockAddRequest(s)),
    };
  }
)((props: IProps) => {
  const [state, setState] = useState<IState>(FRESH_STATE);
  const history = useHistory();
  const {
    addStockSymbol,
    addStockQuantity,
    addStockPrice,
    addStockDate,
  } = state;
  const { requestAddStock } = props;

  const addNewStock = () => {
    if (addStockSymbol && addStockPrice && addStockQuantity && addStockDate) {
      requestAddStock({
        symbol: addStockSymbol,
        purchase_price_cents: parseInt(addStockPrice, 10),
        quantity: parseInt(addStockQuantity, 10),
        purchase_date: new Date(addStockDate),
      });
      setState(FRESH_STATE);
      history.push("/stocks");
    }
  };

  return (
    <>
      <Header pageTitle={"Add Stock"} backRoute={"/stocks"} />
      <br />
      <FieldContainer>
        <TextField
          variant={"outlined"}
          label={"Symbol"}
          value={addStockSymbol}
          onChange={(e) =>
            setState({ ...state, addStockSymbol: e.target.value })
          }
          fullWidth={true}
        />
        <TextField
          variant={"outlined"}
          label={"Quantity"}
          type={"number"}
          value={addStockQuantity}
          onChange={(e) =>
            setState({ ...state, addStockQuantity: e.target.value })
          }
          fullWidth={true}
        />
        <TextField
          variant={"outlined"}
          label={"Price in Cents"}
          type={"number"}
          value={addStockPrice}
          onChange={(e) =>
            setState({ ...state, addStockPrice: e.target.value })
          }
          fullWidth={true}
        />
        <TextField
          variant={"outlined"}
          label={"Date"}
          type={"date"}
          value={addStockDate}
          onChange={(e) => setState({ ...state, addStockDate: e.target.value })}
          fullWidth={true}
        />
      </FieldContainer>
      <Button onClick={addNewStock} variant={"outlined"} type={"submit"}>
        Buy
      </Button>
    </>
  );
});
