import { Button } from "@material-ui/core";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "@material-ui/core/styles/styled";
import { IStoreState } from "../../store";
import { newProficiencyToggle } from "../actions/proficiencyToggle";

interface IProps {
  name: string;
  small?: boolean;
  toggledProficiencies: string[];
  toggleAction: (name: string) => void;
}

interface IPaddedButtonProps {
  small: boolean;
}

const PaddedButton = styled(Button)({
  margin: "0.2em",
  padding: (props: IPaddedButtonProps) => (props.small ? "0.1em" : undefined),
  border: (props: IPaddedButtonProps) => (props.small ? 0 : undefined),
});

function Proficiency(props: IProps): JSX.Element {
  const { toggledProficiencies, toggleAction, name, small } = props;
  const highlighted =
    toggledProficiencies.indexOf(name.toLocaleLowerCase()) !== -1;
  const color = highlighted ? "secondary" : "default";
  const toggle = () => toggleAction(name);

  function getVariant() {
    if (small) {
      return "text";
    } else {
      return "outlined";
    }
  }

  return (
    <PaddedButton
      color={color}
      onClick={toggle}
      variant={getVariant()}
      small={small || false}
    >
      {name}
    </PaddedButton>
  );
}

export default connect(
  (store: IStoreState) => {
    return { toggledProficiencies: store.resume.proficiencies };
  },
  (dispatch: Dispatch) => {
    return {
      toggleAction: (name: string) => dispatch(newProficiencyToggle(name)),
    };
  }
)(Proficiency);
