import Button from "@material-ui/core/Button";
import styled from "@material-ui/core/styles/styled";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import * as React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import GCard from "lib/GCard";
import GScrollToTop from "lib/GScrollToTop";
import Header from "main/Header";
import { Dispatch } from "redux";
import { IStoreState } from "store";
import { Color } from "lib/colors";
import { IGAPIDataState, isDataLoading, useGData } from "../../lib/GAPIData";
import GFab from "../../lib/GFab";
import GLoading from "../../lib/GLoading";
import Instruction from "../components/Instruction";
import RecipeBox from "../components/RecipeBox";
import { IRecipe, selectRecipe } from "../RecipeApi1";
import EditIcon from "@material-ui/icons/Edit";
import { cartAddRecipeRequest, recipesRequest } from "../reducer";

const EditButtonRow = styled("div")({
  display: "flex",
  width: "100%",
  justifyContent: "center",
  marginTop: "1.7em",
  marginBottom: "3em",
});

function recipeInstructions(recipe: IRecipe) {
  const instructions = [];

  for (const instruction of recipe.instruction_list) {
    instructions.push(
      <Instruction key={instruction.step} instruction={instruction} />
    );
  }

  return instructions;
}

/**
 * Primary component
 */
interface IProps {
  recipeData?: IGAPIDataState<IRecipe>;
  addRecipeToCart: (rId: number) => void;
  requestRecipes: () => void;
}

export default connect(
  (store: IStoreState) => {
    return {
      recipeData: store.recipes.recipeData,
    };
  },
  (dispatch: Dispatch) => {
    return {
      addRecipeToCart: (rId: number) => dispatch(cartAddRecipeRequest(rId)),
      requestRecipes: () => dispatch(recipesRequest()),
    };
  }
)((props: IProps) => {
  const { recipeData, addRecipeToCart, requestRecipes } = props;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const recipes = useGData(recipeData, requestRecipes);

  if (isDataLoading(recipeData)) {
    return <GLoading />;
  }

  const recipe = selectRecipe(recipes, id);

  if (!recipe) {
    return <GCard variant={Color.Warn}>Recipe not found</GCard>;
  }

  const recipeId = parseInt(id || "", 10);

  return (
    <>
      <Header pageTitle={"Recipe"} backRoute={"/recipe"} />
      <GScrollToTop />
      <RecipeBox recipe={recipe} noLink />
      {recipeInstructions(recipe)}
      <EditButtonRow>
        <Button
          variant={"outlined"}
          startIcon={<EditIcon />}
          onClick={() => history.push(`/recipe/${id}/edit`)}
        >
          Edit Recipe
        </Button>
      </EditButtonRow>
      <GFab
        icon={<AddShoppingCartIcon />}
        onClick={() => {
          addRecipeToCart(recipeId);
          history.push("/recipe?t=cart");
        }}
      />
    </>
  );
});
