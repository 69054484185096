import { AccordionSummary } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import GCheckbox from "lib/GCheckbox";
import Header from "main/Header";
import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { IStoreState } from "store";
import styled from "@material-ui/core/styles/styled";
import { IGAPIDataState, isDataLoading, useGData } from "../../lib/GAPIData";
import GFab from "../../lib/GFab";
import GLoading from "../../lib/GLoading";
import DietaryFlagLabel from "../components/DietaryFlagLabel";
import MealTypeLabel from "../components/MealTypeLabel";
import RecipesList from "../components/RecipeList";
import { DietaryFlag, IRecipe, MealType } from "../RecipeApi1";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/Add";
import {createRecipeRequest, recipesRequest} from "../reducer"

interface IStoreProps {
  recipeData?: IGAPIDataState<IRecipe>;
}

interface IDispatchProps {
  createNewRecipe: (name: string) => void;
  requestRecipes: () => void;
}

interface IState {
  currentTab: string;
  showAddRecipe: boolean;
  searchFilter: string;
  newRecipeName: string;
  dietaryFlagsSelected: Set<DietaryFlag>;
  mealTypesSelected: Set<MealType>;
}

const FiltersAccordion = styled(Accordion)({
  margin: "0.8em",
  borderRadius: "4px",
  "&::before": {
    backgroundColor: "unset",
  },
});

const SearchContainer = styled("div")({
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexWrap: "wrap",
});

const SelectedFilters = styled("span")({
  fontStyle: "italic",
  marginLeft: "0.3em",
});

export default connect(
  (store: IStoreState): IStoreProps => {
    return {
      recipeData: store.recipes.recipeData,
    };
  },
  (dispatch): IDispatchProps => {
    return {
      createNewRecipe: (name: string) => dispatch(createRecipeRequest(name)),
      requestRecipes: () => dispatch(recipesRequest()),
    };
  }
)((props: IStoreProps & IDispatchProps) => {
  const [state, setState] = useState<IState>({
    currentTab: "list",
    showAddRecipe: false,
    newRecipeName: "",
    searchFilter: "",
    dietaryFlagsSelected: new Set(),
    mealTypesSelected: new Set(),
  });
  const { recipeData, createNewRecipe, requestRecipes } = props;
  const {
    searchFilter,
    dietaryFlagsSelected,
    mealTypesSelected,
    showAddRecipe,
    newRecipeName,
  } = state;

  const recipes = useGData(recipeData, requestRecipes);

  if (isDataLoading(recipeData)) {
    return (
      <>
        <Header pageTitle={"Recipes"} />
        <GLoading />
      </>
    );
  }

  function clearFilters() {
    setState({
      ...state,
      searchFilter: "",
      dietaryFlagsSelected: new Set<DietaryFlag>(),
      mealTypesSelected: new Set<MealType>(),
    });
  }

  function toggleSet<T>(set: Set<T>, value: T) {
    if (set.has(value)) {
      set.delete(value);
    } else {
      set.add(value);
    }
  }

  function toggleShowAddRecipe() {
    setState({ ...state, showAddRecipe: !showAddRecipe });
  }

  function addRecipe() {
    if (newRecipeName.length > 0) {
      createNewRecipe(newRecipeName);
    }
  }

  //
  // Search
  //
  function searchOnChange(e: any) {
    setState({ ...state, searchFilter: e.target.value });
  }

  function clearSearch() {
    setState({ ...state, searchFilter: "" });
  }

  //
  // Meal Types
  //
  function createMealTypeCheckbox(flag: MealType) {
    function handler() {
      toggleSet(mealTypesSelected, flag);
      setState({ ...state, mealTypesSelected });
    }
    return (
      <Grid item xs={6} sm={4}>
        <GCheckbox
          checked={mealTypesSelected.has(flag)}
          onChange={handler}
          label={<MealTypeLabel name={flag} />}
        />
      </Grid>
    );
  }

  //
  // Diet Flags
  //
  function createDietaryFlagCheckbox(flag: DietaryFlag) {
    function handler() {
      toggleSet(dietaryFlagsSelected, flag);
      setState({ ...state, dietaryFlagsSelected });
    }
    return (
      <Grid item xs={6} sm={4}>
        <GCheckbox
          checked={dietaryFlagsSelected.has(flag)}
          onChange={handler}
          label={<DietaryFlagLabel name={flag} />}
        />
      </Grid>
    );
  }

  // selected filters summary
  let selectedFilters = [];
  if (searchFilter) {
    selectedFilters.push(searchFilter);
  }
  if (mealTypesSelected.size > 0) {
    selectedFilters = [...selectedFilters, ...mealTypesSelected];
  }
  if (dietaryFlagsSelected.size > 0) {
    selectedFilters = [...selectedFilters, ...dietaryFlagsSelected];
  }
  const currentFiltersText =
    selectedFilters.length > 0 ? "(" + selectedFilters.join(", ") + ")" : "";

  return (
    <>
      <Header pageTitle={"Recipes"} />
      <FiltersAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <FilterListIcon /> &nbsp; Filters{" "}
          <SelectedFilters>{currentFiltersText}</SelectedFilters>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item sm={12} md={6}>
              <SearchContainer>
                <TextField
                  label="Search field"
                  margin="normal"
                  onChange={searchOnChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position={"end"}>
                        <IconButton onClick={clearSearch}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type="search"
                  value={searchFilter}
                  variant={"outlined"}
                />
                <Button variant={"outlined"} onClick={clearFilters}>
                  Clear All Filters
                </Button>
              </SearchContainer>
            </Grid>
            <Grid item sm={12} md={6}>
              <Grid container spacing={1}>
                {createMealTypeCheckbox(MealType.Breakfast)}
                {createMealTypeCheckbox(MealType.Lunch)}
                {createMealTypeCheckbox(MealType.Dinner)}
                {createMealTypeCheckbox(MealType.Dessert)}
                {createMealTypeCheckbox(MealType.Snack)}
                {createMealTypeCheckbox(MealType.Drink)}
                {createDietaryFlagCheckbox(DietaryFlag.Vegan)}
                {createDietaryFlagCheckbox(DietaryFlag.Vegetarian)}
                {createDietaryFlagCheckbox(DietaryFlag.DairyFree)}
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </FiltersAccordion>
      <RecipesList
        recipes={recipes || []}
        searchFilter={searchFilter}
        dietaryFlagsSelected={dietaryFlagsSelected}
        mealTypesSelected={mealTypesSelected}
      />
      <GFab icon={<AddIcon />} onClick={toggleShowAddRecipe} offset={6} />
      <Dialog open={showAddRecipe}>
        <DialogTitle>Add New Recipe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              label={"Recipe Name"}
              variant={"outlined"}
              value={newRecipeName}
              onChange={(e) =>
                setState({ ...state, newRecipeName: e.target.value })
              }
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleShowAddRecipe}>Cancel</Button>
          <Button
            onClick={addRecipe}
            disabled={newRecipeName.length === 0}
            type={"submit"}
          >
            Create Recipe
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
